<div class="alert-content" *ngIf="showAlert">
  <div class="alert-content__icon-fms">
    <img src="/assets/icon/alert-fms.svg" alt="Alert">
  </div>
  <div class="alert-content__box">
    <div class="alert-header">
      <span class="alert-title">{{alertTitle}}</span>
      <span class="expand" (click)="explodeWidget($event)" (keydown)="explodeWidget($event)">
        <img src="/assets/icon/Group-w.svg" alt="">
      </span>
    </div>
    <p class="alert-message">{{innerAlertText}}</p>
  </div>
</div>