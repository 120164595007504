import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { BehaviorSubject, lastValueFrom, Observable, Subject, takeUntil } from 'rxjs';
import { LayoutService } from 'src/app/components/layout.service';
import { LoggingService } from 'src/app/components/Logging.service';
import { Utils } from 'src/app/shared/helpers/utils';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ReportFMSService } from 'src/app/shared/services/report_fms.service';

@Component({
  selector: 'app-widget-report-fms',
  templateUrl: './widget-report-fms.component.html',
  styleUrls: ['./widget-report-fms.component.scss']
})
export class WidgetReportFmsComponent implements OnInit, OnDestroy {
  @Input() is_dashboard: any;

  isMobileWithTablet$: Observable<boolean>;
  reportsFMS: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  size: NzButtonSize = 'small';
  activities = [
    { type: 'raccolta', value: 1 },
    { type: 'concimazione', value: 2 },
    { type: 'potatura', value: 3 }
  ];
  showWidget: boolean = false;

  private activityUnits: { [key: string]: string } = {
    'Trattamento fungicida': '(L)',
    'Irrigazione a goccia': '(L/h)',
    'Monitoraggio delle malattie delle piante': '(Piante)',
    'Preparazione del terreno': 'Ettari (ha)',
    'Trattamento insetticida': 'Litri (L)',
    'Concimazione del terreno': '(kg)',
  };
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly reportService: ReportFMSService,
    private readonly auth: AuthService,
    private readonly _loggingService: LoggingService,
    private readonly router: Router,
    private readonly _utils: Utils,
    private readonly _layoutService: LayoutService
  ) {
    this.isMobileWithTablet$ = this._layoutService.isMobileWithTablet$;
  }

  ngOnInit(): void {
    this.getAllReportFMS();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getAllReportFMS() {
    this.reportService.getUserReports({ user_id: this.auth.currentUser.value.id })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {

        if (res.data.length > 0) {
          this.reportsFMS.next(res.data);
          this.showWidget = true
          console.log('Reports', res.data);


        } else {
          this.reportsFMS.next(
            [
              {
                id: 1,
                activity: "Nessun dato",
                user_id: 2,
                date: "Nessun dato",
                plot: "Nessun dato",
                cultivar: "Nessun dato",
                causal: "Nessun dato",
                product_id: 0,
                amount: 0,
                surface: 0,
                created_at: null,
                updated_at: null,
                deleted_at: null,
                product: {
                  id: 4,
                  name: "Nessun dato",
                  description: "Nessun dato",
                  category: 1,
                  img_thumbnail: "Nessun dato",
                  path: null,
                  created_at: null,
                  updated_at: null
                },
                user: {
                  id: 2,
                  name: "Nessun dato",
                  email: "Nessun dato",
                  email_verified_at: "Nessun dato",
                  confirmed: 1,
                  disabled: 0,
                  // created_at: 2024-04-17T12:48:45.000000Z,
                  // updated_at: "2024-04-17T12:48:45.000000Z"
                }
              }
            ]
          )
          this.showWidget = true
        }

      });
  }

  getActivityClass(value: string): string {
    switch (value) {
      case 'Trattamento':
        return 'Trattamento';
      case 'Raccolta':
        return 'Raccolta';
      case 'Irrigazione':
        return 'Irrigazione';
      case 'Monitoraggio':
        return 'Monitoraggio';
      case 'Preparazione':
        return 'Preparazione';
      case 'Concimazione':
        return 'Concimazione';
      default:
        return 'Nessun attività';
    }
  }

  getFirstWord(text: string): string {
    if (!text) {
      return '';
    }
    const words = text.split(' ');
    return words.length > 0 ? words[0] : '';
  }

  getCategoryImage(value: any) {
    // console.log('IMG',value);

    switch (value) {
      case '1':
        return '/assets/img/agrumi/orange-icon.svg';
      case '2':
        return '/assets/img/agrumi/lemon-icon.svg';
      default:
        return null;
    }
  }

  explodeWidget() {
    this._utils.handleWidgetClick('/app/producer/reports', 2);

    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-widget-report-fms'
    }
    lastValueFrom(this._loggingService.logClick(data))
      .then((response: any) => {
        this.router.navigate(['/app/producer/reports']);
      });
  }

  getUnitOfMeasure(activity: string): string {
    return this.activityUnits[activity] || 'Kg';
  }
}
