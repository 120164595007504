import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ComponentService } from '../component.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { CommonService } from '../common.service';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-shipment-tracking',
  templateUrl: './shipment-tracking.component.html',
  styleUrls: ['./shipment-tracking.component.scss'],
})
export class ShipmentTrackingComponent implements OnInit, OnDestroy {
  form!: FormGroup;
  selectedItemIndex: number = 0;
  batchToSelect: any;
  item!: any;
  shipment: any;

  isToggleCollapsedForMobileFiltering: boolean = true;
  isMobileWithTablet$: Observable<boolean>;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly _componentAervice: ComponentService,
    private readonly _commonService: CommonService,
    private readonly _layoutService: LayoutService
  ) {
    this.isMobileWithTablet$ = this._layoutService.isMobileWithTablet$;
  }

  ngOnInit(): void {
    this.getMyBatches();
    this.form = this.fb.group({
      field: new FormControl(null),
      lotto: new FormControl(null),
      search: new FormControl(null),
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  submitForm(): void {
    this.getTimelineData();
  }

  closeDetail(item: any, event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }

    setTimeout(() => {
      if (item) {
        item.visible = false;
      }
      this.item = undefined;
    });
  }

  openDetails(item: any, event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }

    setTimeout(() => {
      if (item) {
        item.visible = true;
      }
      this.item = item;
    });
  }

  getMyBatches(): void {
    this._componentAervice
      .getMyTimelineBatch()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.batchToSelect = r.result;
        this.form.patchValue({ lotto: this.batchToSelect[0] });
        this.getTimelineData();
      });
  }

  getTimelineData(): void {
    this._componentAervice
      .timeLine(this.form.value.lotto)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        r.data.steps = r.data.steps.map((item: any) => ({
          ...item,
          visible: false,
        }));
        this.shipment = r.data;
      });
  }

  toggleCollapsedForMobileFiltering(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.isToggleCollapsedForMobileFiltering =
      !this.isToggleCollapsedForMobileFiltering;
  }

  weightReceivedManipulation(item: any) {
    return item?.otherInfo[0].original_quantity ?? this.shipment.quantity;
  }

  weightSentManipulation(item: any) {
    return item?.otherInfo[0].quantity ?? this.shipment.quantity;
  }

  weightWasteManipulation(item: any) {
    const waste = item?.otherInfo[0]?.original_quantity
      ? item?.otherInfo[0]?.original_quantity - item?.otherInfo[0]?.quantity
      : null;
    return waste ? `${waste} kg` : 'Nessuno scarto';
  }
}
