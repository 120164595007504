<div *ngIf="role_id !== '4' ">
  <app-feedback></app-feedback>
</div>

<div *ngIf="role_id === '4' " class="feedback-list-container">
  <nz-tabset>
    <!-- Tab 1: Consumatori -->
    <nz-tab nzTitle="Consumatori">
      <app-feedback-consumer></app-feedback-consumer>
    </nz-tab>

    <!-- Tab 2: Operatori di filiera -->
    <nz-tab nzTitle="Operatori di filiera">
      <div class="operatori-tab-content">
        <app-feedback></app-feedback>
      </div>
    </nz-tab>
  </nz-tabset>
</div>
