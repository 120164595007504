<div class="historical-weather">
  <div class="box-header">
    <div class="box-header__title">
      Storico meteorologico
    </div>
    <div class="box-header__filter-cta">
      <a href="javascript:void(0)" (click)="toggleCollapsedForMobileFiltering($event)"
        (keydown)="toggleCollapsedForMobileFiltering($event)">
        Filtra
        <i class="icon-custom settings-orange mt-2"></i>
      </a>
    </div>
  </div>
  <div class="box-mobile-filter" [class.box-mobile-filter--open]="!isToggleCollapsedForMobileFiltering">
    <div class="filter-title">Filtra</div>
    <form class="box-filter-input" [formGroup]="filtersForm">
      <nz-select class="w-100" formControlName="year" [nzPlaceHolder]="filtersWeather[0].placeholder">
        <nz-option [nzLabel]="item.label" [nzValue]="item" *ngFor="let item of filtersWeather[0].options">
        </nz-option>
      </nz-select>
      <nz-select class="w-100" formControlName="municipality_id" [nzPlaceHolder]="filtersWeather[1].placeholder">
        <nz-option [nzLabel]="item.label" [nzValue]="item.value" *ngFor="let item of filtersWeather[1].options">
        </nz-option>
      </nz-select>
    </form>
  </div>
  <div class="rounded-container-white">
    <div class="d-none d-lg-flex justify-content-between align-items-center mb-4 mb-lg-5">
      <h5 id="title-lighter">Storico meteorologico</h5>
      <div>
        <form [formGroup]="filtersForm">
          <div class="d-flex justify-content-around">
            <nz-select class="select-list custom-select" id="select-1" formControlName="year"
              [nzPlaceHolder]="filtersWeather[0].placeholder">
              <nz-option [nzLabel]="item.label" [nzValue]="item" *ngFor="let item of filtersWeather[0].options">
              </nz-option>
            </nz-select>
            <nz-select class="select-list custom-select" id="select-2" formControlName="municipality_id"
              [nzPlaceHolder]="filtersWeather[1].placeholder">
              <nz-option [nzLabel]="item.label" [nzValue]="item.value" *ngFor="let item of filtersWeather[1].options">
              </nz-option>
            </nz-select>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="batch_history == null" class="disappearing-line">
      <p>
        Seleziona un comune per vederne lo storico
      </p>
    </div>

    <div *ngIf=" (batch_history !== null && batch_history?.total_month > 0 ) || showMonths ">
      <div *ngIf="batch_info">
        <p class="title-info">
          Lotto <span class="span-heavier"> {{ batch_info.batch_unique_identifier }}</span>
        </p>
        <p>
          Seminato il: <span class="span-heavier"> {{ batch_info.sowing_date ?? 'Non definito' }} </span>
          Raccolto il: <span class="span-heavier"> {{ batch_info.reaping_date ?? 'Non definito' }} </span>
        </p>
      </div>
      <!-- month -->
      <div *ngIf="showMonths" class="month-buttons">
        <button *ngFor="let m of months; let i = index" (click)="selectMonth(m, i, $event)"
          (keydown)="selectMonth(m, i, $event)" [ngClass]="{
            'month-button': true,
            'active-month-button': activeMonth?.value === m.value,
            'adjacent-month-button': isAdjacentMonth(i)
          }">
          {{ m.label }}
        </button>
      </div>

      <!-- days -->

      <div
        *ngIf="batch_history?.data && batch_history?.data?.monthly_data && batch_history?.total_month > 0 ; else noDataTemplate"
        class="weather-days-container">
        <button class="nav-button" (click)="scrollDays(-1, $event)" (keydown)="scrollDays(-1, $event)"
          [disabled]="skip === 0">
          <img src="../../../../assets/icon/previsioni/expand_circle_left.svg" alt="Icon" class="icon">
        </button>
        <div #dayCardsContainer class="day-cards-container">
          <div #dayCardsInner class="day-cards-inner">
            <app-weather-day-card *ngFor="let day of batch_history?.data?.monthly_data; let i = index"
              [dayWeather]="day" (click)="selectDay(day, i, $event)" (keydown)="selectDay(day, i, $event)"
              [isSelected]="selectedDay?.id === day?.id"></app-weather-day-card>
          </div>
        </div>
        <button class="nav-button" (click)="scrollDays(1, $event)" (keydown)="scrollDays(1, $event)"
          [disabled]="skip + take >= batch_history?.total_month">
          <img src="../../../../assets/icon/previsioni/expand_circle_right.svg" alt="Icon" class="icon">
        </button>
      </div>

      <!-- Template alternativo per quando non ci sono dati -->
      <ng-template #noDataTemplate>
        <div class="no-data-message">
          <p>Non ci sono dati disponibili per questo periodo.</p>
        </div>
      </ng-template>


      <!-- weather-istances -->
      <div *ngIf=" batch_history !== null && batch_history?.total_month > 0 &&selectedDay ">
        <section class="weather-section">
          <div class="section-header">
            <h6 class="section-title-lighter">Dettagli del giorno</h6>
            <p *ngIf="activeMonth != null">
              <!-- {{ selectedDay.created_at | date: 'fullDate'  }} -->
              {{ selectedDay.created_at | date:'dd' }} {{ selectedDay.created_at | date:'MMMM' | capitalizeFirst }} {{
              selectedDay.created_at | date:'yyyy'}}
            </p>
          </div>

          <ng-container *ngIf="(isMobileWithTablet$ | async) else weatherInstDesktopLayout">
            <!-- begin: Weather layout mobile/tablet -->
            <div class="row mb-4">
              <div class="col-12">
                <div class="weather-instances-main">
                  <div class="box-weather-main" *ngFor="let weather of visibleWeatherInstances">
                    <p class="box-weather-main__hour">{{ weather.created_at | date:'HH:mm' }}</p>
                    <p class="box-weather-main__temp">
                      {{ weather.temp_main }} °C
                    </p>
                    <img class="box-weather-main__icon" [src]="getIconPath(weather.weather_main)"
                      [alt]="weather.weather_main" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-md-4">
                <!-- begin: Vento -->
                <div class="weather-instances-other">
                  <img class="weather-instances-other__icon" src="../../../../assets/icon/previsioni/wind.svg"
                    alt="vento">
                  <p class="weather-instances-other__desc">
                    Vento
                  </p>
                  <p class="weather-instances-other__value">
                    {{ selectedDay.wind_speed }} <span>km/h</span>
                  </p>
                </div>
                <!-- end: Vento -->
              </div>
              <div class="col-6 col-md-4">
                <!-- begin: Umidità -->
                <div class="weather-instances-other">
                  <img class="weather-instances-other__icon" src="../../../../assets/icon/previsioni/humidity.svg"
                    alt="umidità">
                  <p class="weather-instances-other__desc">
                    Umidità
                  </p>
                  <p class="weather-instances-other__value">
                    {{ selectedDay.humidity }} <span>%</span>
                  </p>
                </div>
                <!-- end: Umidità -->
              </div>
              <div class="col-6 col-md-4 pt-3 pt-md-0">
                <!-- begin: Pressione -->
                <div class="weather-instances-other">
                  <img class="weather-instances-other__icon" src="../../../../assets/icon/previsioni/pressure.svg"
                    alt="pressione">
                  <p class="weather-instances-other__desc">
                    Pressione
                  </p>
                  <p class="weather-instances-other__value">
                    {{ selectedDay.pressure }} <span>mbar</span>
                  </p>
                </div>
                <!-- end: Pressione -->
              </div>
            </div>
            <!-- end: Weather layout mobile/tablet -->
          </ng-container>
          <ng-template #weatherInstDesktopLayout>
            <!-- begin: Weather layout desktop -->
            <div class="box-weather-instances-external">
              <div class="weather-instances-main">
                <div class="box-weather-main" *ngFor="let weather of visibleWeatherInstances">
                  <p class="box-weather-main__hour">{{ weather.created_at | date:'HH:mm' }}</p>
                  <p class="box-weather-main__temp">
                    {{ weather.temp_main }} °C
                  </p>
                  <img class="box-weather-main__icon" [src]="getIconPath(weather.weather_main)"
                    [alt]="weather.weather_main" />
                </div>
              </div>

              <!-- begin: Vento -->
              <div class="weather-instances-other">
                <img class="weather-instances-other__icon" src="../../../../assets/icon/previsioni/wind.svg"
                  alt="vento">
                <p class="weather-instances-other__desc">
                  Vento
                </p>
                <p class="weather-instances-other__value">
                  {{ selectedDay.wind_speed }} <span>km/h</span>
                </p>
              </div>
              <!-- end: Vento -->

              <!-- begin: Umidità -->
              <div class="weather-instances-other">
                <img class="weather-instances-other__icon" src="../../../../assets/icon/previsioni/humidity.svg"
                  alt="umidità">
                <p class="weather-instances-other__desc">
                  Umidità
                </p>
                <p class="weather-instances-other__value">
                  {{ selectedDay.humidity }} <span>%</span>
                </p>
              </div>
              <!-- end: Umidità -->

              <!-- begin: Pressione -->
              <div class="weather-instances-other">
                <img class="weather-instances-other__icon" src="../../../../assets/icon/previsioni/pressure.svg"
                  alt="pressione">
                <p class="weather-instances-other__desc">
                  Pressione
                </p>
                <p class="weather-instances-other__value">
                  {{ selectedDay.pressure }} <span>mbar</span>
                </p>
              </div>
              <!-- end: Pressione -->
            </div>
            <!-- end: Weather layout desktop -->
          </ng-template>
        </section>
      </div>
    </div>
  </div>
</div>