import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { LoggingService } from 'src/app/components/Logging.service';
import { ComponentService } from 'src/app/components/component.service';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-ProductsForSale',
  templateUrl: './ProductsForSale.component.html',
  styleUrls: ['./ProductsForSale.component.scss'],
})
export class ProductsForSaleComponent implements OnInit {
  @ViewChild('carouselElement') carouselElement!: ElementRef;

  data: any;
  info: any;
  currentDate: Date = new Date();

  constructor(
    private router: Router,
    private _loggingService: LoggingService,
    private _componentService: ComponentService,
    private renderer: Renderer2,
    private _utils: Utils
  ) {}

  ngOnInit() {
    this.getLastProductVendit();
  }

  ngAfterViewInit() {
    const carousel = this.carouselElement.nativeElement;
    this.renderer.listen(carousel, 'slid.bs.carousel', (event) => {
      const activeIndex = event.to;
      const currentItem = this.data[activeIndex];
      console.log('Elemento corrente:', currentItem);
      this.info = [
        {
          status: currentItem.status,
          dayInSale: currentItem.dayInSale,
          rejected: currentItem.rejected,
        },
      ];
    });
  }

  explodeWidget() {
    this._utils.handleWidgetClick('/your-products', 18);
    this.router.navigate(['your-products']);
    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-doughnut-and-pie-chart',
    };
    lastValueFrom(this._loggingService.logClick(data));
  }

  getLastProductVendit() {
    this._componentService.getLastProductVendit().subscribe((r) => {
      console.log('CAROUSEL', r);
      this.data = r.data;
      this.info = [
        {
          status: this.data[0].status,
          dayInSale: this.data[0].dayInSale,
          rejected: this.data[0].rejected,
        },
      ];
    });
  }
}
