<div class="container">
  <div class="d-flex justify-content-between">
    <h4 class="header-widget">Prodotti in arrivo</h4>
    <span style="cursor: pointer;" (click)="explodeWidget()" onkeypress="">
      <img src="/assets/img/arrow.svg" alt="">
    </span>
  </div>

  <div class="tab">
    <table class="table table-striped">
      <thead>
        <tr>
          <th class="font-title" *ngFor="let col of cols">{{col}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of data">
          <td class="text-style-big padd">{{ item.batch.batch_unique_identifier}}</td>
          <td class="text-style-medium">{{ item.arrival_date | date:'dd/MM/yyyy'}}</td>
          <td class="text-style-big">{{ item.batch.user.name }}</td>
          <td class="text-style-big">{{ item.batch.variety}}</td>
          <td class="text-style-medium">{{ item.quantity}} kg</td>
          <td class="text-style-big">
            <div
              [ngClass]="{'rovinato': item.status == 'Rovinato', 'buono': item.status == 'Buono', 'discreto': item.status == 'Discreto'}">
              {{item.status}}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</div>
