<div class="widget-bar-chart" *ngIf="showWidget">
  <div class="d-flex justify-content-between align-items-center mb-2">
    <div>
      <h4 class="header-widget">Andamento Produzione</h4>
    </div>
    <div class="d-flex align-items-center gap-3">
      <div class="select-year">
        <nz-select class="test" nzPlaceHolder="Seleziona un anno" [(ngModel)]="selectedYear"
          (ngModelChange)="getData()">
          <nz-option nzLabel="In corso" [nzValue]="null"></nz-option>
          <nz-option *ngFor="let year of years" [nzLabel]="year" [nzValue]="year" class="color">{{year}}</nz-option>
        </nz-select>
      </div>
      <span class="arrow-icon cursor-pointer" (click)="explodeWidget($event)" (keydown)="explodeWidget($event)">
        <img src="/assets/img/arrow.svg" alt="">
      </span>
    </div>
  </div>
  <div class="box-bar-chart" *ngIf="barChartData.datasets[0].data">
    <canvas id="barChart" baseChart height="400" [data]="(barChartData.datasets[0].data) ? barChartData : undefined" [options]="barChartOptions" [type]="barChartType"
      [legend]="barChartLegend">
    </canvas>
  </div>
</div>
