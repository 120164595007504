<div class="treatment-prices-handler">
  <div class="treatment-prices-handler__header">
    <div class="title-header">Costi attività</div>
    <div class="cta-header">
      <button type="button" nz-button class="custom-button" [nzType]="'primary'"
        (click)="openModal('add', null, $event)" (keydown)="openModal('add', null, $event)">
        Aggiungi costo
      </button>
    </div>
  </div>

  <nz-table class="treatment-prices-handler__table" #basicTable [nzData]="activityData" [nzPageIndex]="pageIndex"
    [nzPageSize]="take" [nzTotal]="totElementsNum" (nzPageIndexChange)="onPageChange($event)">
    <thead>
      <tr>
        <th id="plante" *ngIf="role_id === '2'">Piantagione</th>
        <th id="batch" *ngIf="role_id !== '2'">Lotto</th>
        <th id="product">Prodotto</th>
        <th *ngIf="role_id !== '6'" id="activity">Trattamento</th>
        <th *ngIf="role_id == '6'" id="transportation">Mezzo di transporto</th>
        <th id="price">Costo</th>
        <th id="action">Azioni</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of activityData">
        <td *ngIf="role_id === '2'">{{ item.plante_label }}</td>
        <td *ngIf="role_id !== '2'">{{ item.batch_label }}</td>
        <td>{{ item.product_label }}</td>
        <td>{{ item.activity }}</td>
        <td>{{ item.price | currency: 'EUR' }}</td>
        <td>
          <img class="img-click" src="/assets/icon/edit_button_table.svg" alt="modifica"
            (click)="openModal('edit', item, $event)" (keydown)="openModal('edit', item, $event)" />
        </td>
      </tr>
    </tbody>
  </nz-table>

  <!-- begin: Mobile/Tablet price list -->
  <div class="list-price-mobile" *ngIf="activityData?.length">
    <div class="list-price-mobile__item-list" *ngFor="let item of activityData">
      <div class="header-list">
        <div class="header-list__box-title">
          <p class="description">Prodotto</p>
          <p class="title">{{item.product_label}}</p>
        </div>
        <button type="button" nz-button class="header-list__cta-edit" [nzType]="'primary'"
          (click)="openModal('edit', item, $event)" (keydown)="openModal('edit', item, $event)">
          <i class="icon-custom pencil-white"></i>
        </button>
      </div>
      <div class="content-item">
        <div class="content-item__row">
          <div>
            <div class="row-title">Piantagione</div>
            <div class="row-value">{{item.plante_label}}</div>
          </div>
        </div>
        <div class="content-item__row">
          <div>
            <div class="row-title">Prodotto</div>
            <div class="row-value">{{item.product_label}}</div>
          </div>
        </div>
        <div class="content-item__row">
          <div>
            <div class="row-title">Trattamento</div>
            <div class="row-value">{{item.activity}}</div>
          </div>
        </div>
        <div class="content-item__row">
          <div>
            <div class="row-title">Costo</div>
            <div class="row-value">{{item.price | currency: 'EUR'}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-end w-100">
      <nz-pagination [nzPageIndex]="pageIndex" [nzPageSize]="take" [nzTotal]="totElementsNum"
        (nzPageIndexChange)="onPageChange($event)" nzSimple>
      </nz-pagination>
    </div>
  </div>
  <!-- end: Mobile/Tablet price list -->
</div>

<!-- Treatment Modal -->
<nz-modal [nzClosable]="false" [nzClassName]="'treatment-modal'" [nzOkLoading]="isModalOkLoading"
  [nzTitle]="((modalType === 'add') ? 'Aggiungi' : 'Modifica')+' costo'" [nzOkText]="'Salva'"
  [(nzVisible)]="isModalVisible" (nzOnCancel)="handleModalCancel()" (nzOnOk)="handleModalOk()">
  <ng-container *nzModalContent>
    <div class="d-flex flex-column">
      <form [formGroup]="formActivity">
        <div class="modal-input" *ngIf="role_id === '2'">
          <!-- Plant Dropdown -->
          <label for="planteSelect">Piantagione</label>
          <nz-select id="planteSelect" formControlName="plante_id" class="ant-select">
            <nz-option *ngFor="let option of dropDownOptions" [nzLabel]="option.label"
              [nzValue]="option.value"></nz-option>
          </nz-select>
        </div>

        <div class="modal-input" *ngIf="role_id !== '2'">
          <!-- Plant Dropdown -->
          <label for="batchSelect">Lotto</label>
          <nz-select id="batchSelect" formControlName="batch_id" class="ant-select">
            <nz-option *ngFor="let option of dropDownOptions" [nzLabel]="option.label"
              [nzValue]="option.value"></nz-option>
          </nz-select>
        </div>

        <div class="modal-input">
          <!-- Product Label -->
          <label for="productInput">Prodotto</label>
          <input id="productInput" nz-input [value]="selectedProductName" readonly class="ant-input" />
        </div>

        <div class="modal-input">
          <!-- Activity Dropdown -->
          <label for="transportationSelect" *ngIf="role_id === '6'">Mezzo di transporto</label>
          <label for="activitySelect" *ngIf="role_id !== '6'">Trattamento</label>
          <nz-select id="activityTransportationSelect" formControlName="activity" class="ant-select">
            <nz-option [nzLabel]="option" [nzValue]="option" *ngFor="let option of activityOptions"></nz-option>
          </nz-select>
        </div>

        <div class="modal-input mb-0">
          <!-- Price Input -->
          <label for="priceInput">Costo</label>
          <input id="priceInput" nz-input formControlName="price" nzType="text" class="ant-input text-start"
            currencyMask [options]="{ prefix: '€ ', thousands: '.', decimal: ',', precision: 2 }" />
        </div>

        <div class="modal-input radio-group-custom">
          <!-- Visibility Radio -->
          <nz-radio-group formControlName="visibility">
            <label for="Pubblico" nz-radio [nzValue]="true">Pubblico</label>
            <label for="Privato" nz-radio [nzValue]="false">Privato</label>
          </nz-radio-group>
        </div>
      </form>
    </div>
  </ng-container>
</nz-modal>