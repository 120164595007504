<div class="report-fms">
  <div class="row align-items-center">
    <div class="col-12 col-lg-5">
      <h2 class="mb-0">Quaderno delle attività</h2>
    </div>
    <div class="col-12 col-lg-7 select-field d-none d-lg-flex align-items-center">
      <div class="col-4 select-municipality">
        <nz-select [nzPlaceHolder]="placeholder" [(ngModel)]="selectedField" (ngModelChange)="getReports()"
          class="custom-select">
          <nz-option *ngFor="let field of (fields | async)" [nzLabel]="field.label" [nzValue]="field.id" class="color">
            {{field}}
          </nz-option>
        </nz-select>
      </div>

      <div class="col-8">
        <nz-input-group [nzPrefix]="prefixIconSearch">
          <input type="text" autocomplete="off" aria-autocomplete="none" nz-input
            [placeholder]="'Cerca per attività (es. “potatura”, “concimazione”, ecc...)'" [(ngModel)]="filter"
            (keyup.enter)="onSearch()" />
        </nz-input-group>
        <ng-template #prefixIconSearch>
          <span nz-icon nzType="search"></span>
        </ng-template>
      </div>
    </div>
  </div>

  <!-- begin: Section only mobile/tablet -->
  <div class="box-mobile-searching">
    <div class="box-mobile-searching__municipality">
      <div class="box-municipality">
        <nz-select [nzPlaceHolder]="placeholder" [(ngModel)]="selectedField" (ngModelChange)="getReports()"
          class="custom-select">
          <nz-option *ngFor="let field of (fields | async)" [nzLabel]="field.label" [nzValue]="field.id" class="color">
            {{field}}
          </nz-option>
        </nz-select>
      </div>
      <div class="box-filter-date">
        <a href="javascript:void(0)" (click)="toggleCollapsedForMobileSearching($event)"
          (keydown)="toggleCollapsedForMobileSearching($event)">
          <i class="icon-custom calendar-orange"></i>
        </a>
      </div>
    </div>
    <div>
      <div class="box-mobile-searching__box-calendar"
        [class.box-mobile-searching__box-calendar--open]="!isToggleCollapsedForMobileSearching">
        <app-calendar (defaultDateChanged)="onDefaultDateChanged($event)"></app-calendar>
      </div>
    </div>
    <div class="box-mobile-searching__box-search">
      <nz-input-group [nzPrefix]="prefixIconSearch">
        <input type="text" autocomplete="off" aria-autocomplete="none" nz-input
          [placeholder]="'Cerca per attività (es. “potatura”, “concimazione”, ecc...)'" [(ngModel)]="filter"
          (keyup.enter)="onSearch()" />
      </nz-input-group>
      <ng-template #prefixIconSearch>
        <span nz-icon nzType="search"></span>
      </ng-template>
    </div>
  </div>
  <!-- end: Section only mobile/tablet -->

  <div class="row mb-4">
    <div class="col-12 col-lg-4 d-none d-lg-block">
      <app-calendar (defaultDateChanged)="onDefaultDateChanged($event)"></app-calendar>
      <div class="mt-4 px-4 py-3 bg-color bd-rad">
        <app-widget-report-fms [is_dashboard]="is_dashboard"></app-widget-report-fms>
      </div>
    </div>
    <div class="col-12 col-lg-8">
      <div class="d-flex flex-row align-items-center">
        <div><img src="assets/icon/calendar.svg" alt=""></div>
        <div>
          <h4 class="mb-0 calendar-text">{{ currentDate }}</h4>
        </div>
      </div>
      <div *ngIf="(reports | async)?.length > 0; else noData">
        <div class="mt-4" *ngFor="let act of (reports | async)">
          <app-activity-type (reloadReports)="handleReloadReports()" [report]="act"></app-activity-type>
        </div>
      </div>
      <ng-template #noData>
        <div class="d-flex justify-content-center no-activity-margin"><img src="/assets/img/noActivity.svg"
            alt="no activity"></div>
      </ng-template>

    </div>
  </div>

  <div class="mt-3 d-flex justify-content-end">
    <nz-pagination [nzPageIndex]="pageIndex" [nzPageSize]="pageSize" [nzTotal]="(total | async)"
      (nzPageIndexChange)="handlePageEventNotification($event)" nzSimple>
    </nz-pagination>
  </div>
</div>