<div class="register">
  <div class="row">
    <div class="register__box-img col-12 col-lg-6" [ngClass]="currentImgClass">
      <!-- Template steps mobile/tablet -->
      <div class="d-flex d-lg-none justify-content-center align-items-center">
        <ng-container *ngTemplateOutlet="boxSteps"></ng-container>
      </div>
    </div>
    <div class="register__box-steps col-12 col-lg-6 pe-0">
      <!-- Template steps desktop -->
      <ng-container *ngTemplateOutlet="boxSteps"></ng-container>
    </div>
  </div>
</div>

<!-- begin: Template steps -->
<ng-template #boxSteps>
  <div class="box-steps">
    <div class="box-steps__img-logo">
      <img src="/assets/icon/logo-citrace-color-1.svg" alt="CiTrace" />
    </div>
    <div class="box-steps__step">
      <h2 class="step-title">Registrati</h2>
      <div class="step-progress-bar">
        <div class="step-progress-bar__section"
          [class.step-progress-bar__section--current]="currentStepNumber >= step.stepNumber"
          *ngFor="let step of steps; trackBy: trackById"></div>
      </div>
      <div class="step-body" [ngSwitch]="stepsForm">
        <ng-container *ngSwitchCase="0" [ngTemplateOutlet]="first"></ng-container>
        <ng-container *ngSwitchCase="1" [ngTemplateOutlet]="second"></ng-container>
        <ng-container *ngSwitchCase="2" [ngTemplateOutlet]="third"></ng-container>
        <ng-container *ngSwitchDefault>
          <p>Layout non riconosciuto</p>
        </ng-container>
      </div>
      <div class="step-action" *ngIf="!isDone">
        <button type="button" class="back" nz-button nzType="default" (click)="pre($event)" (keydown)="pre($event)"
          *ngIf="currentStepNumber > 0">
          Indietro
        </button>
        <button type="button" class="next" nz-button nzType="primary" [disabled]="!typeValue" (click)="next($event)"
          (keydown)="next($event)" [nzLoading]="processing" *ngIf="currentStepNumber < 2">
          Continua
        </button>
        <button type="button" class="submit-register" nz-button nzType="primary" (click)="done($event)"
          (keydown)="done($event)" [nzLoading]="processing" *ngIf="currentStepNumber === 2">
          Registrati
        </button>
      </div>
      <div class="step-info">
        <span class="step-info__login">
          Hai già un account?
          <a href="javascript:void(0)" (click)="goToLoginPage($event)" (keydown)="goToLoginPage($event)">
            Accedi
          </a>
        </span>
      </div>
    </div>
  </div>

  <ng-template #first>
    <p class="step-description">A quale categoria appartieni?</p>
    <nz-radio-group class="step-radio-group" [(ngModel)]="typeValue">
      <label class="step-radio-group__radio-item" aria-label="consumatore" for="consumatore" nz-radio
        nzValue="consumatore">
        Sono un consumatore
      </label>
      <label class="step-radio-group__radio-item" aria-label="to-next" for="to-next" nz-radio nzValue="to-next">
        Sono produttore
      </label>
    </nz-radio-group>
  </ng-template>

  <ng-template #second>
    <p class="step-description">A quale categoria appartieni?</p>
    <nz-radio-group class="step-radio-group" [(ngModel)]="roleValue">
      <label class="step-radio-group__radio-item" aria-label="produttore" for="produttore" nz-radio
        nzValue="produttore">
        Produttore
      </label>
      <label class="step-radio-group__radio-item" aria-label="selezione e stoccaggio" for="selezione-stoccaggio"
        nz-radio nzValue="selezione e stoccaggio">
        Selezione e stoccaggio
      </label>
      <label class="step-radio-group__radio-item" aria-label="distributore" for="distributore" nz-radio
        nzValue="distributore">
        Distributore
      </label>
      <label class="step-radio-group__radio-item" aria-label="venditore" for="venditore" nz-radio nzValue="venditore">
        Punto vendita
      </label>
    </nz-radio-group>
  </ng-template>

  <ng-template #third>
    <p class="step-description">Inserisci i tuoi dati per completare la registrazione</p>
    <form class="step-form" nz-form [nzLayout]="'vertical'" [formGroup]="registerForm">
      <div class="step-form__section-user-info-1 row">
        <div class="col-12 col-lg-6">
          <nz-form-item>
            <nz-form-control nzErrorTip="Inserisci il tuo nome">
              <nz-input-group>
                <input type="text" nz-input formControlName="name" placeholder="Nome" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-12 col-lg-6">
          <nz-form-item>
            <nz-form-control nzErrorTip="Inserisci il tuo cognome">
              <nz-input-group>
                <input type="text" nz-input formControlName="lastName" placeholder="Cognome" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="step-form__section-user-info-2 row">
        <div class="col-12 col-lg-6">
          <nz-form-item>
            <nz-form-control nzErrorTip="Inserisci il nome della tua azienda">
              <nz-input-group>
                <input type="text" nz-input formControlName="company" placeholder="Nome azienda" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-12 col-lg-6">
          <nz-form-item>
            <nz-form-control nzErrorTip="Inserisci la Partita IVA">
              <nz-input-group>
                <input type="text" nz-input formControlName="vatNumber" placeholder="P.IVA" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="step-form__section-user-other-1 row">
        <div class="col-12 col-lg-6">
          <nz-form-item>
            <nz-form-control nzErrorTip="Inserisci la tua mail">
              <nz-input-group>
                <input type="email" nz-input formControlName="email" placeholder="Email" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="col-12 col-lg-6">
          <nz-form-item>
            <nz-form-control nzErrorTip="Inserisci la passoword">
              <nz-input-group [nzSuffix]="suffixTemplateFirstPass">
                <input nz-input formControlName="password" placeholder="Password"
                  [type]="(firstPasswordVisible) ? 'text' : 'password'" />
              </nz-input-group>
              <ng-template #suffixTemplateFirstPass>
                <span nz-icon class="font-icon" [nzType]="(firstPasswordVisible) ? 'eye-invisible' : 'eye'"
                  (click)="toggleFirstPasswordVisibility($event)"
                  (keydown)="toggleFirstPasswordVisibility($event)"></span>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <div class="step-form__section-user-other-2 row">
        <div class="col-12 col-lg-6">
          <nz-form-item>
            <nz-form-control nzErrorTip="Reinserisci la password per confermarla">
              <nz-input-group [nzSuffix]="suffixTemplateSecondPass">
                <input nz-input formControlName="confirmPassword" placeholder="Conferma Password"
                  [type]="(secondPasswordVisible) ? 'text' : 'password'" (ngModelChange)="updateConfirmValidator()" />
              </nz-input-group>
              <ng-template #suffixTemplateSecondPass>
                <span nz-icon class="font-icon" [nzType]="(secondPasswordVisible) ? 'eye-invisible' : 'eye'"
                  (click)="toggleSecondPasswordVisibility($event)"
                  (keydown)="toggleSecondPasswordVisibility($event)"></span>
              </ng-template>
            </nz-form-control>
            <div role="alert" class="ant-form-item-explain-error" nz-form-explain
              *ngIf="registerForm?.get('confirmPassword')?.valid && passwordMismatch">
              Le due password inserite non coincidono
            </div>
          </nz-form-item>
        </div>
      </div>

      <div class="step-form__section-user-terms row">
        <div class="col-12">
          <nz-form-control nzErrorTip="E' necessario accettare i termini e le condizioni d'uso">
            <nz-input-group>
              <label aria-label="Accetto i termini e condizioni d'uso" for="terms" formControlName="hasAcceptedTerms"
                nz-checkbox>
                Accetto i <a href="javascript:void(0)">termini</a> e <a href="javascript:void(0)">condizioni d'uso</a>
                <input type="hidden" id="terms" class="d-none" />
              </label>
            </nz-input-group>
          </nz-form-control>
        </div>
      </div>
    </form>
  </ng-template>
</ng-template>
<!-- end: Template steps -->