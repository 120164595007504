<div class="dashboard">
  <ng-container *ngIf="role_id==2">
    <app-container1 [favoriteActivities]="favoriteActivities"></app-container1>
  </ng-container>
  
  <ng-container *ngIf="role_id==5">
    <app-transformer-home1 [favoriteActivities]="favoriteActivities"></app-transformer-home1>
  </ng-container>
  
  <ng-container *ngIf="role_id==6">
    <app-transporter-home [favoriteActivities]="favoriteActivities"></app-transporter-home>
  </ng-container>
  
  <ng-container *ngIf="role_id==4">
    <app-salePoint-home [favoriteActivities]="favoriteActivities"></app-salePoint-home>
  </ng-container>
  
  <ng-container *ngIf="role_id==3">
    <app-consumer></app-consumer>
  </ng-container>
</div>
