import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { merge, Observable, Subject, timer } from 'rxjs';
import { delay, finalize, map, scan, takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/shared/services/user.service';
import { toast } from 'src/app/app.component';
import { CommonService } from 'src/app/components/common.service';

interface SyncStep {
  id: number;
  stepNumber: number;
  title: string;
  description: string;
  async: false;
  percentage: null;
}

interface AsyncStep {
  id: number;
  stepNumber: number;
  title: string;
  description: string;
  async: true;
  percentage: number;
}

type Step = SyncStep | AsyncStep;

function mockAsyncStep(): Observable<number> {
  const subStep1 = timer(0).pipe(map(() => 25));
  const subStep2 = subStep1.pipe(delay(0));
  const subStep3 = subStep2.pipe(delay(0));
  const subStep4 = subStep3.pipe(delay(0));
  return merge(subStep1, subStep2, subStep3, subStep4).pipe(scan((a, b) => a + b));
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  registerForm!: FormGroup;

  passwordMismatch: boolean = false;
  cities: any;
  optionsCities: any;
  showProdForm: boolean = false;
  showConsForm: boolean = false;
  firstPasswordVisible: boolean = false;
  secondPasswordVisible: boolean = false;
  processing: boolean = false;
  typeValue: any;
  roleValue: any;
  stepsForm: any = 0;
  isDone: boolean = false;
  currentStepNumber: number = 0;
  currentImgClass: string = 'register__box-img--img-register-1';
  data = {
    "name": null,
    "lastName": null,
    "email": null,
    "password": null,
    "password_confirmation": null,
    "role": null,
    "type": null,
    "company": null,
    "vatNumber": null
  };
  steps: Step[] = [
    {
      id: 1,
      stepNumber: 0,
      title: '',
      description: '',
      async: false,
      percentage: null
    },
    {
      id: 2,
      stepNumber: 1,
      title: '',
      description: '',
      async: true,
      percentage: 0
    },
    {
      id: 3,
      stepNumber: 2,
      title: '',
      description: '',
      async: true,
      percentage: 0
    }
  ];

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly _userService: UserService,
    private readonly _commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.buildForm('consumatore');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  buildForm(type: string): void {
    this.registerForm = this.fb.group({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: [null, [Validators.required]],
      confirmPassword: [null, [Validators.required]],
      name: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      company: new FormControl(null, Validators.required),
      vatNumber: new FormControl(null, Validators.required),
      hasAcceptedTerms: new FormControl(null, Validators.requiredTrue)
    });
    this.showProdForm = true;
  }

  // valida la conferma password
  updateConfirmValidator(): void {
    this.passwordMismatch = (this.registerForm.value.password != this.registerForm.value.confirmPassword);
  }

  pre(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    if (this.typeValue == 'consumatore') {
      this.currentStepNumber -= 2;
      this.currentImgClass = 'register__box-img--img-register-1';
      this.stepsForm = this.stepsForm - 2;
    } else if (this.typeValue == 'to-next') {
      this.currentStepNumber -= 1;
      this.currentImgClass = 'register__box-img--img-register-1';
      this.stepsForm = this.stepsForm - 1;
    }
  }

  next(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.loadingAndStep();

    if (this.typeValue == 'consumatore') {
      this.stepsForm = this.stepsForm + 2
      if (this.stepsForm == 2) {
        this.currentImgClass = 'register__box-img--img-register-2';
      }
      this.currentStepNumber = this.currentStepNumber + 1
      this.data.role = this.typeValue;
    } else if (this.typeValue == 'to-next') {
      this.stepsForm = this.stepsForm + 1
      if (this.stepsForm == 2) {
        this.currentImgClass = 'register__box-img--img-register-2';
      }
      this.data.role = this.roleValue;
    }

  }

  toggleFirstPasswordVisibility(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.firstPasswordVisible = !this.firstPasswordVisible;
  }

  toggleSecondPasswordVisibility(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.secondPasswordVisible = !this.secondPasswordVisible;
  }

  done(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.data.name = this.registerForm.value.name;
    this.data.lastName = this.registerForm.value.lastName;
    this.data.email = this.registerForm.value.email;
    this.data.password = this.registerForm.value.password;
    this.data.company = this.registerForm.value.company;
    this.data.vatNumber = this.registerForm.value.vatNumber;
    this.data.password_confirmation = this.registerForm.value.confirmPassword;

    if (this.registerForm.valid) {
      if (this.passwordMismatch) {
        toast.fire({
          title: 'Conferma password',
          text: 'Le due password inserite non coincidono',
          icon: 'warning'
        });
        return;
      }

      this._userService.register(this.data)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: r => {
            this.isDone = true;
            this.router.navigate(['auth/registration-done']);
          },
          error: error => {
            let errorMessage = 'Si è verificato un errore.';
            if (error.error) {
              const errorObj = JSON.parse(error.error);
              errorMessage = '';

              for (const key in errorObj) {
                if (errorObj.hasOwnProperty(key)) {
                  errorMessage += `${key}: ${errorObj[key].join(', ')}\n`;
                }
              }
            }

            toast.fire({
              title: 'Campi mancanti',
              text: errorMessage,
              icon: 'warning'
            });
          }
        });
    } else {
      toast.fire({
        title: 'Campi mancanti',
        text: 'Compila tutti i campi',
        icon: 'warning'
      });
    }
  }

  trackById(_: number, item: Step): number {
    return item.id;
  }

  loadingAndStep(): void {
    if (this.currentStepNumber < this.steps.length) {
      const step = this.steps[this.currentStepNumber];
      if (step.async) {
        this.processing = true;
        mockAsyncStep()
          .pipe(
            takeUntil(this.unsubscribe$),
            finalize(() => {
              step.percentage = 0;
              this.processing = false;
              this.currentStepNumber += 1;
            })
          )
          .subscribe(p => {
            step.percentage = p;
          });
      } else {
        this.currentStepNumber += 1;
      }
    }
  }

  goToLoginPage(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.router.navigate(['auth/login']);
  }





















}
