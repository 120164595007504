import { Component, OnInit } from '@angular/core';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { WidgetReportFmsComponent } from 'src/app/modules/producer/components/widget/widget-report-fms/widget-report-fms.component';
import { BarChartComponent } from '../../modules/producer/components/widget/bar-chart/bar-chart.component';
import { DoughnutAndPieChartComponent } from '../../modules/producer/components/widget/doughnut-and-pie-chart/doughnut-and-pie-chart.component';
import { LineChartComponent } from '../../modules/producer/components/widget/line-chart/line-chart.component';
import { SunChartComponent } from '../../modules/producer/components/widget/sun-chart/sun-chart.component';
import { WaterChartComponent } from '../../modules/producer/components/widget/water-chart/water-chart.component';
import { WidgetWeatherComponent } from 'src/app/modules/producer/components/widget/widget-weather/widget-weather.component';
import { AlertComponent } from 'src/app/modules/producer/components/alert/alert.component';
import { WidgetFeedbackComponent } from 'src/app/modules/producer/components/widget/widget-feedback/widget-feedback.component';
import { ComponentService } from '../component.service';
import { Utils } from 'src/app/shared/helpers/utils';
import { IWidget } from '../sidebar/Interfaces/IWidget';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  layoutType?: string;
  containerLg?: boolean;
  containersm?: boolean;
  favoriteActivities: IWidget[] | null = null;

  products = [
    {
      path: '../../../assets/img/market/10.jpg',
      desc: 'succo arancia',
      title: 'arancia rossa',
      id: 1,
    },
    {
      path: '../../../assets/img/market/6.jpg',
      desc: 'succo arancia',
      title: 'arancia rossa',
      id: 2,
    },
    {
      path: '../../../assets/img/market/11.jpg',
      desc: 'succo arancia',
      title: 'arancia rossa',
      id: 3,
    },
    {
      path: '../../../assets/img/market/5.jpg',
      desc: 'succo arancia',
      title: 'arancia rossa',
      id: 4,
    },
    {
      path: '../../../assets/img/market/9.jpg',
      desc: 'succo arancia',
      title: 'arancia rossa',
      id: 5,
    },
    {
      path: '../../../assets/img/market/8.jpg',
      desc: 'succo arancia',
      title: 'arancia rossa',
      id: 6,
    },
    {
      path: '../../../assets/img/market/7.jpg',
      desc: 'succo arancia',
      title: 'arancia rossa',
      id: 7,
    },
    {
      path: '../../../assets/img/market/6.jpg',
      desc: 'succo arancia',
      title: 'arancia rossa',
      id: 8,
    },
    {
      path: '../../../assets/img/market/8.jpg',
      desc: 'succo arancia',
      title: 'arancia rossa',
      id: 9,
    },
    {
      path: '../../../assets/img/market/7.jpg',
      desc: 'succo arancia',
      title: 'arancia rossa',
      id: 10,
    },
    {
      path: '../../../assets/img/market/6.jpg',
      desc: 'succo arancia',
      title: 'arancia rossa',
      id: 11,
    },
  ];

  widget: any;
  widgetSizes = [
    { type: 'app-doughnut-and-pie-chart', size: { width: 319, height: 394 } },
    { type: 'app_report_fms', size: { width: 431, height: 394 } },
    { type: 'app_alert_fms', size: { width: 321, height: 200 } },
    { type: 'app_meteo', size: { width: 319, height: 134 } },
    { type: 'app-sun-chart', size: { width: 203, height: 134 } },
    { type: 'app-water-chart', size: { width: 203, height: 134 } },
    { type: 'app-line-chart', size: { width: 319, height: 439 } },
    { type: 'app-bar-chart', size: { width: 781, height: 378 } },
  ];
  orderedWidgets: any[] | undefined;
  allComponents: {
    component?: any;
    columns?: number;
    background?: string;
    additionalStyles?: string;
    order?: number;
  }[] = [];
  column1: any[] = [];
  column2: any[] = [];
  column3: any[] = [];
  role_id: any = localStorage.getItem('role_id');

  currentUser: any;
  widgetsFromPredict: [] = [];

  constructor(
    private breakpointObserver: BreakpointObserver,
    private _authService: AuthService,
    private _adminService: AdminService,
    private _componentService: ComponentService,
    private _utils: Utils,
    private _router: Router
  ) {
    this.currentUser = this._authService.currentUser.value;
    console.log('CURRENT USER', this.currentUser);

    this.widget = [];
  }

  ngOnInit() {
    this._authService.getUser().subscribe((r) => {
      if (r.code === 200) {
        this.postInteractionEnginePredictComponent(r.data);
        this.postInteractionEnginePredictAction();
      }
      this.getAllWidget(this.role_id);
    });

    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
      ])
      .subscribe((result) => {
        this.layoutType = this.determineLayoutType(result);
      });
  }

  getComponent(value: string): {
    component?: any;
    columns?: number;
    background?: string;
    additionalStyles?: string;
    customClass?: any;
  } {
    switch (value) {
      case 'app-doughnut-and-pie-chart':
        return {
          component: DoughnutAndPieChartComponent,
          additionalStyles:
            'background-color: white; width: 319px; height: 394px',
        };
      case 'app_report_fms':
        return {
          component: WidgetReportFmsComponent,
          additionalStyles:
            'background-color: white; width: 431px; height: 394px',
        };
      case 'app_alert_fms':
        return {
          component: AlertComponent,
          additionalStyles:
            'width: 321px; height: 75px; background-color: orange;',
        };
      case 'app_meteo':
        return {
          component: WidgetWeatherComponent,
          background: "url('/assets/img/weather.svg')",
          additionalStyles:
            'background-repeat: no-repeat; background-size: 80%; background-position: bottom right; background-color: white; width: 319px; height: 8.375rem; margin-top:-6rem',
        };
      case 'app-sun-chart':
        return {
          component: SunChartComponent,
          background: "url('/assets/img/chart/Group 2.svg')",
          additionalStyles:
            'background-repeat: no-repeat; background-position: left bottom; background-size: 80%; background-color: var(--sage-200, #D9EDBF); width: 203px; height: 134p; margin-top:-6rem',
        };

      case 'app-water-chart':
        return {
          component: WaterChartComponent,
          background: "url('/assets/img/chart/water.svg')",
          additionalStyles:
            'background-repeat: no-repeat; background-position: left bottom; background-size: 80%; background-color: var(--sky-200, #D2FAFF); width: 203px; height: 134px; margin-top:-6rem',
        };
      case 'app-line-chart':
        return {
          component: LineChartComponent,
          additionalStyles: 'width: 319px; height: 530px; padding:0!important',
          customClass: 'no-padding',
        };

      case 'app-bar-chart':
        return {
          component: BarChartComponent,
          additionalStyles: 'width: 781px; height: 378px',
        };

      case 'feedback':
        return {
          component: WidgetFeedbackComponent,
          additionalStyles:
            'width: 319px; height: 378px; background-color: #056564;',
        };
      default:
        return {};
    }
  }

  getAllWidget(role_id: any) {
    console.log(role_id);
    this._adminService.getAllwidgetRole(role_id).subscribe((r) => {
      this.widget = r.data;
    });
  }

  populateAllComponents() {
    this.allComponents.length = 0;

    if (this.widget) {
      this.widget.forEach((item: { value: string }) => {
        const componentInfo = this.getComponent(item.value);
        if (componentInfo) {
          this.allComponents.push(componentInfo);
        }
      });
    } else {
      console.error('this.widget è undefined.');
    }
  }

  determineLayoutType(result: BreakpointState): string {
    switch (true) {
      case result.breakpoints[Breakpoints.XSmall]:
        this.containerLg = false;
        this.containersm = true;
        return 'mobile';
      case result.breakpoints[Breakpoints.Small] ||
        result.breakpoints[Breakpoints.Medium]:
        return 'tablet';
      default:
        this.containersm = false;
        this.containerLg = true;
        return 'desktop';
    }
  }

  postInteractionEnginePredictComponent(user: any) {
    const data = {
      user_id: user.id,
      role_id: this.role_id,
      device_type: this._utils.getEnhancedDeviceType(),
    };
    this._componentService
      .postInteractionEnginePredictComponent(data)
      .subscribe((response) => {
        if (response.code === 200) {
          this.widgetsFromPredict = response.data;
        } else {
          this.widgetsFromPredict = [];
        }
      });
  }

  postInteractionEnginePredictAction() {
    const data = { device_type: this._utils.getEnhancedDeviceType() };
    this._componentService
      .postInteractionEnginePredictAction(data)
      .subscribe((response) => {
        const predictedActions = response['Predicted Action'];

        if (predictedActions.length > 0) {
          const matchingWidgets = this.getMatchingWidgets(predictedActions);
          this.favoriteActivities = matchingWidgets.slice(0, 3);

          if (this.shouldRunPrediction()) {
            this.navigateToFirstPrediction(matchingWidgets);
            localStorage.setItem('hasRunPrediction', 'true');
          }
        }
      });
  }

  private getMatchingWidgets(predictedActions: any[]): IWidget[] {
    return predictedActions
      .map((prediction) =>
        this._utils.widgets.find(
          (widget) =>
            widget.name === prediction.Class &&
            widget.roles?.includes(this.role_id)
        )
      )
      .filter(Boolean) as IWidget[];
  }

  private shouldRunPrediction(): boolean {
    return localStorage.getItem('hasRunPrediction') !== 'true';
  }

  private navigateToFirstPrediction(matchingWidgets: IWidget[]): void {
    const url = this.prepareUrl(matchingWidgets[0]?.url ?? '');
    if (url) {
      window.location.href = url;
    }
  }

  private prepareUrl(url: string | null | undefined): string {
    if (!url) return '';

    const protocolRegex = /^[a-zA-Z]+:\/\//;
    if (!protocolRegex.test(url)) {
      return `${environment.useHttps ? 'https' : 'http'}://${url}`;
    }
    return url;
  }
}
