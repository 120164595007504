<div class="feedback-list-container">
  <div class="row">
    <!-- Header and Form for Filtering and Searching -->
    <div class="col-12 header d-flex justify-content-between align-items-center order-1">
      <div class="title-header">Feedback</div>
      <form class="select-search-container d-none d-lg-flex justify-content-end align-items-center" nz-form
        [nzLayout]="'inline'" [formGroup]="form">
        <div class="select-container form-container d-flex justify-content-between align-items-center">
          <span class="filters">Filtra per:</span>
          <div class="d-flex justify-content-between align-items-center">
            <!-- Type Selector -->
            <nz-form-item>
              <nz-form-control>
                <nz-select nzPlaceHolder="Tipo" nzBorderless formControlName="type" (ngModelChange)="submitForm()">
                  <nz-option [nzValue]="true" nzLabel="Ricevuti"></nz-option>
                  <nz-option [nzValue]="false" nzLabel="Emessi"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>

            <!-- Lotto Selector -->
            <nz-form-item>
              <nz-select nzPlaceHolder="Lotto" nzBorderless formControlName="lotto" (ngModelChange)="submitForm()">
                <nz-option [nzValue]="null" nzLabel="Nessuno"></nz-option>
                <nz-option *ngFor="let lotto of userLotti" [nzValue]="lotto.id"
                  [nzLabel]="lotto.batch_unique_identifier"></nz-option>
              </nz-select>
            </nz-form-item>

            <!-- Actor Selector -->
            <nz-form-item>
              <nz-select class="actor" nzPlaceHolder="Operatori di filiera" nzBorderless formControlName="actor"
                (ngModelChange)="submitForm()">
                <nz-option [nzValue]="null" nzLabel="Nessuno"></nz-option>
                <nz-option *ngFor="let role of operatorsToSelectModal" [nzValue]="role" [nzLabel]="role"></nz-option>
              </nz-select>
            </nz-form-item>
          </div>
        </div>

        <!-- Search Input -->
        <div class="search-container">
          <nz-input-group [nzPrefix]="prefixIconSearchDesktop">
            <input type="text" autocomplete="off" aria-autocomplete="none" nz-input formControlName="search"
              [placeholder]="'Cerca'" (keyup.enter)="submitForm()" />
          </nz-input-group>
          <ng-template #prefixIconSearchDesktop>
            <span nz-icon nzType="search"></span>
          </ng-template>
        </div>
      </form>
    </div>

    <!-- Feedback List Display -->
    <div *ngIf="showFeedbackList" class="col-12 col-lg-8 list order-3 order-lg-2">
      <div class="row">
        <div class="col-12 title-list"
          *ngIf="form?.get('type')?.value !== undefined && form?.get('type')?.value !== null">
          <span *ngIf="form.get('type')?.value === true">Ricevuti</span>
          <span *ngIf="form.get('type')?.value === false">Emessi</span>
        </div>
        <div class="col-12 subtitle-list">Tutti i feedback</div>
      </div>
      <div class="feedback ms-0 row" *ngFor="let item of feedbackList" (click)="updateFeedback(item)" onkeypress="">
        <!-- Feedback Item Details -->
        <div class="col-9 col-lg-10 ps-0">
          <div class="top d-flex align-items-start align-items-lg-center flex-column flex-lg-row">
            <div class="d-flex flex-row align-items-center">
              <div class="img">
                <span *ngIf="item.img">
                  <img [src]="item.img" alt="ext" />
                </span>
                <span *ngIf="!item.img">
                  <span>{{ item.name.charAt(0) }}</span>
                </span>
              </div>
              <div class="name">{{ item.name }}</div>
            </div>
            <div class="circle"></div>
            <span class="icon" [ngSwitch]="item.role">
              <span class="icon" *ngSwitchCase="'selezione e stoccaggio'">
                <img src="../../../assets/icon/trasformatore.svg" alt="trasformatore" />
              </span>
              <span class="icon" *ngSwitchCase="'distributore'">
                <img src="../../../assets/icon/distributore.svg" alt="distributore" />
              </span>
              <span class="icon" *ngSwitchCase="'venditore'">
                <img src="../../../assets/icon/venditore.svg" alt="venditore" />
              </span>
              <span class="icon" *ngSwitchCase="'produttore'">
                <img src="/assets/icon/prod.svg" alt="produttore" />
              </span>
              <span class="icon" *ngSwitchCase="'consumatore'">
                <img src="../../../assets/icon/consumatore.svg" alt="consumatore" />
              </span>
              <p *ngSwitchDefault></p>
            </span>
            <span class="role">{{ item.role }}</span>
          </div>
          <div
            class="m-bottom d-flex align-items-center flex-row-reverse flex-lg-row justify-content-end justify-content-lg-start">
            <div class="vote">
              <nz-rate [ngModel]="item.rating" nzAllowHalf nzDisabled="true"></nz-rate>
            </div>
            <span class="title">{{ item.title }}</span>
          </div>
          <div class="m-bottom">
            <span class="date">
              Feedback lasciato il
              {{ item.created_at | date:'dd' }}
              {{ item.created_at | date:'MMMM' | capitalizeFirst}}
              {{ item.created_at | date:'yyyy' }}
            </span>
          </div>
          <div>
            <p class="text">{{ item.feedback }}</p>
          </div>
        </div>

        <!-- Batch Info -->
        <div class="col-3 col-lg-2 pe-0">
          <span class="lotti">Lotto:</span>
          <div class="lotto-num">
            <span>{{ item.batch.batch_unique_identifier }}</span>
          </div>
        </div>
      </div>

      <!-- Pagination -->
      <div class="mt-3 d-flex justify-content-end">
        <nz-pagination [nzPageIndex]="dataFeedback.skip" [nzPageSize]="dataFeedback.take" [nzTotal]="count"
          (nzPageIndexChange)="handlePageEventNotification($event)" nzSimple></nz-pagination>
      </div>
    </div>

    <!-- No Data Message -->
    <div *ngIf="!showFeedbackList" class="col-12 col-lg-8 list order-3 order-lg-2">
      <span class="list-no-data">Nessun dato</span>
    </div>

    <!-- Right Sidebar (top sidebar header only mobile/tablet) -->
    <div class="col-12 col-lg-4 feedback-sidebar order-2 order-lg-3">
      <div class="row">
        <div class="col-6 col-lg-12 feedback-sidebar__box-add-feedback">
          <div class="new-feedback d-flex justify-content-around align-items-center" (click)="addFeedback()"
            onkeypress="">
            <div class="new-feedback__content d-flex align-items-center">
              <span class="add-new d-flex justify-content-around align-items-center">
                +
              </span>
              <span class="add-new__text">Nuovo feedback</span>
            </div>
          </div>
        </div>
        <div class="col-6 col-lg-12 feedback-sidebar__box-overall-rating">
          <div class="overall-rating d-flex flex-column justify-content-around align-items-center">
            <span class="overall-rating__text">Valutazione complessiva</span>
            <div class="vote">
              <nz-rate [ngModel]="overallRating" nzAllowHalf nzDisabled="true"></nz-rate>
            </div>
          </div>
        </div>
        <div class="col-12 feedback-sidebar__box-operators">
          <div class="d-flex flex-column justify-content-around">
            <span class="operators-title">Operatori di filiera</span>
            <div *ngFor="let item of operators">
              <div class="operator d-flex align-items-center">
                <span class="icon">
                  <img [src]="item.img" alt="ext" />
                </span>
                <span class="operator-name">{{ item.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Form for Filtering and Searching (only mobile/tablet) -->
      <form class="feedback-box-filter-mobile" nz-form [nzLayout]="'inline'" [formGroup]="form">
        <div class="feedback-box-filter-mobile__search-input">
          <div class="box-search">
            <nz-input-group [nzPrefix]="prefixIconSearchMobile">
              <input class="w-100" type="text" autocomplete="off" aria-autocomplete="none" nz-input
                formControlName="search" [placeholder]="'Cerca'" (keyup.enter)="submitForm()" />
            </nz-input-group>
            <ng-template #prefixIconSearchMobile>
              <span nz-icon nzType="search"></span>
            </ng-template>
          </div>
          <div class="box-filter-cta">
            <a href="javascript:void(0)" (click)="toggleCollapsedForMobileFiltering($event)"
              (keydown)="toggleCollapsedForMobileFiltering($event)">
              Filtra
              <i class="icon-custom settings-orange mt-2"></i>
            </a>
          </div>
        </div>
        <div class="feedback-box-filter-mobile__filter-box"
          [class.feedback-box-filter-mobile__filter-box--open]="!isToggleCollapsedForMobileFiltering">
          <div class="filter-title">Filtra</div>
          <div class="box-filter-input">
            <!-- Type Selector -->
            <nz-form-item>
              <nz-form-control>
                <nz-select nzPlaceHolder="Tipo" nzBorderless formControlName="type" (ngModelChange)="submitForm()">
                  <nz-option [nzValue]="true" nzLabel="Ricevuti"></nz-option>
                  <nz-option [nzValue]="false" nzLabel="Emessi"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>

            <!-- Lotto Selector -->
            <nz-form-item>
              <nz-select nzPlaceHolder="Lotto" nzBorderless formControlName="lotto" (ngModelChange)="submitForm()">
                <nz-option [nzValue]="null" nzLabel="Nessuno"></nz-option>
                <nz-option *ngFor="let lotto of userLotti" [nzValue]="lotto.id"
                  [nzLabel]="lotto.batch_unique_identifier"></nz-option>
              </nz-select>
            </nz-form-item>

            <!-- Actor Selector -->
            <nz-form-item>
              <nz-select class="actor" nzPlaceHolder="Operatori di filiera" nzBorderless formControlName="actor"
                (ngModelChange)="submitForm()">
                <nz-option [nzValue]="null" nzLabel="Nessuno"></nz-option>
                <nz-option *ngFor="let role of operatorsToSelectModal" [nzValue]="role" [nzLabel]="role"></nz-option>
              </nz-select>
            </nz-form-item>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Modal for Adding Feedback -->
<nz-modal [nzClosable]="false" [nzClassName]="'modal-add-feedback'" [nzTitle]="'Aggiungi Feedback'"
  [(nzVisible)]="isVisible" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
  <ng-container *nzModalContent>
    <p>Seleziona l'operatore della filiera e il numero di lotto alla quale vuoi lasciare un feedback</p>
    <form nz-form [nzLayout]="'inline'" [formGroup]="feedbackForm">
      <div class="col-12 p-b-2">
        <div class="d-flex align-items-center justify-content-between delect-add-feedback">
          <nz-select class="select" nzPlaceHolder="Seleziona operatore" formControlName="operator" [nzDisabled]="mod"
            (ngModelChange)="activeSelect()">
            <nz-option *ngFor="let item of operatorsToSelectModal" [nzValue]="item" [nzLabel]="item"></nz-option>
          </nz-select>
          <div *ngIf="loadLottiSelect">
            <nz-select nzPlaceHolder="Seleziona lotto" formControlName="lotto" [nzDisabled]="mod">
              <nz-option *ngFor="let item of lottiOnModal" [nzValue]="item" [nzLabel]="item"></nz-option>
            </nz-select>
          </div>
        </div>
      </div>
      <div class="col-12 p-b-2 d-flex flex-column">
        <span class="rate-title">Dai una tua valutazione</span>
        <nz-rate formControlName="vote"></nz-rate>
      </div>
      <div class="col-12 p-b-2 d-flex flex-column">
        <span class="rate-title">Dai un titolo al tuo feedback</span>
        <nz-input-group>
          <input type="text" formControlName="title" nz-input />
        </nz-input-group>
      </div>
      <div class="col-12 p-b-2 d-flex flex-column">
        <span class="rate-title">Aggiungi il tuo feedback</span>
        <nz-input-group>
          <textarea formControlName="description" nz-input rows="2"></textarea>
        </nz-input-group>
      </div>
    </form>
  </ng-container>
</nz-modal>