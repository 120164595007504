import { Component, OnDestroy, OnInit } from '@angular/core';
import { ComponentService } from '../component.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-lotHistoryTimeline',
  templateUrl: './lotHistoryTimeline.component.html',
  styleUrls: ['./lotHistoryTimeline.component.scss'],
})
export class LotHistoryTimelineComponent implements OnInit, OnDestroy {
  search!: string;

  selectedRoleId: any;
  currentRole: any;
  batch: any;
  data: any;
  batches: any;
  roles = [
    { role: 'Produttore', id: 2, selected: false },
    { role: 'Selezione e stoccaggio', id: 5, selected: false },
    { role: 'Distributore', id: 6, selected: false },
    { role: 'Punto vendita', id: 4, selected: false },
  ];

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _componentService: ComponentService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly _commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getBatches();
    this.batch = this.route.snapshot.paramMap.get('lotto');
    this.getRole();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getRole(): void {
    this._componentService.getUserRole()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.currentRole = r;
        this.firstRole();
        this.getMyHistoryBatchTimeline();
      });
  }

  getBatches(): void {
    this._componentService.getMyBatchesOnTimeLine()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.batches = r.data;
      });
  }

  searchData(data: string) {
    this.batch = data;
    this.getMyHistoryBatchTimeline();
  }

  firstRole(): void {
    this.roles.forEach((p) => (p.selected = false));
    const roleId = this.roles.find((p) => p.id === this.currentRole);
    if (roleId) {
      roleId.selected = true;
      this.selectedRoleId = roleId.id;
    }
  }

  selectProductType(id: number): void {
    this.roles.forEach((p) => (p.selected = false));
    const roleId = this.roles.find((p) => p.id === id);
    if (roleId) {
      roleId.selected = true;
      this.currentRole = roleId.id;
      this.getMyHistoryBatchTimeline();
    }
  }

  goTo(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.router.navigate(['/lot-history']);
  }

  getMyHistoryBatchTimeline(batch?: any): void {
    if (batch) {
      this.batch = batch;
    }
    this._componentService
      .getMyHistoryBatchTimeline(this.currentRole, this.batch)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (r) => {
          this.data = [r.data];
        },
        error: () => {
          Swal.fire({
            title: 'Lotto inesistente',
            text: 'Il lotto inserito è inesistente',
            showConfirmButton: false,
            icon: 'warning',
          });
        },
      });
  }

  getTranslation(label: string): string {
    return label === 'quantity' ? 'Quantità' : label;
  }
}
