import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/components/common.service';

@Component({
  selector: 'app-registration-done',
  templateUrl: './registration-done.component.html',
  styleUrls: ['./registration-done.component.scss']
})
export class RegistrationDoneComponent {
  constructor(
    private readonly _router: Router,
    private readonly _commonService: CommonService
  ) { }

  goToLoginPage(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this._router.navigate(['auth/login']);
  }
}
