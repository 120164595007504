<div class="widget-feedback">
  <div class="d-flex justify-content-between">
    <div class="d-flex flex-column">
      <h4 class="header-widget t-white">Feedback</h4>
    </div>
    <span class="cursor-pointer" (click)="explodeWidget($event)" (keydown)="explodeWidget($event)">
      <img src="/assets/img/arrow-white.svg" alt="arrow" style="line-height: 1.2">
    </span>
  </div>
  <span class="fs-12 mb-4 t-white">Valutazione complessiva</span>
  <div class="feedback-row d-flex flex-row justify-content-start align-items-center gap-4" *ngFor="let item of (feedback | async)">
    <div class="feedback-row__box-img">
      <ng-container *ngIf="item.label != 'Nessun dato'; else noDataSvg">
        <img [src]="getImagePath(item.label)" [alt]="item.label">
      </ng-container>
      <ng-template #noDataSvg>
        <svg fill="white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
          <path
            d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7L64 512c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zm48 96a144 144 0 1 1 0 288 144 144 0 1 1 0-288zm0 240a24 24 0 1 0 0-48 24 24 0 1 0 0 48zM368 321.6l0 6.4c0 8.8 7.2 16 16 16s16-7.2 16-16l0-6.4c0-5.3 4.3-9.6 9.6-9.6l40.5 0c7.7 0 13.9 6.2 13.9 13.9c0 5.2-2.9 9.9-7.4 12.3l-32 16.8c-5.3 2.8-8.6 8.2-8.6 14.2l0 14.8c0 8.8 7.2 16 16 16s16-7.2 16-16l0-5.1 23.5-12.3c15.1-7.9 24.5-23.6 24.5-40.6c0-25.4-20.6-45.9-45.9-45.9l-40.5 0c-23 0-41.6 18.6-41.6 41.6z">
          </path>
        </svg>
      </ng-template>
    </div>
    <div class="feedback-row__content-rating">
      {{ toCamelCase(item.label) }}
      <div class="vote">
        <nz-rate [ngModel]="item.rating" nzAllowHalf nzDisabled="true"></nz-rate>
      </div>
    </div>
  </div>
</div>
