import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IQrCode } from '../modules/venditore/interfaces/IQrCode';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { IBatchOption } from './historical-weather/interfaces/IBatchOption';
import { FormattedResponse } from '../shared/models/formatted-response';
import { IPredictComponentRequest } from './sidebar/Interfaces/IPredictComponentRequest';
import { IClickExplodeWidgetRequest } from './sidebar/Interfaces/IClickExplodeWidgetRequest';
import { CONFIG} from '../shared/helpers/interceptor.service';

@Injectable({
  providedIn: 'root',
})
export class ComponentService {
  private clickSubject = new Subject<void>();
  clickEvent$ = this.clickSubject.asObservable();

  private batchesSubject = new BehaviorSubject<IBatchOption[]>([]);
  batches$ = this.batchesSubject.asObservable();

  private objectSource = new BehaviorSubject<any>(null);
  currentObject = this.objectSource.asObservable();

  constructor(private http: HttpClient) { }

  triggerClickEvent() {
    this.clickSubject.next();
  }

  changeObject(obj: any) {
    this.objectSource.next(obj);
  }

  getAllProducts(skip: any, take: any) {
    return this.http.get<any>(`${environment.api}/products/${skip}/${take}`);
  }

  getAllFeedback(data: any) {
    return this.http.post<any>(`${environment.api}/get-all-feedback`, data);
  }

  getUserBatch(data: any) {
    return this.http.post<any>(`${environment.api}/get-user-batch`, data);
  }

  createNewFeedback(data: any) {
    return this.http.post<any>(`${environment.api}/add-feedback`, data);
  }

  handleRoles() {
    return this.http.get<any>(`${environment.api}/handle-roles`);
  }

  getBatchByRoleInTimeLine(role: string) {
    return this.http.get<any>(
      `${environment.api}/get-all-batch-from-timeline?role=${role}`
    );
  }

  updateFeedback(data: any) {
    return this.http.post<any>(`${environment.api}/update-feedback`, data);
  }

  updateLastShippingDistributor(lotto: any, data: any) {
    return this.http.post<any>(
      `${environment.api}/updateLastShippingDistributor/${lotto}`,
      data
    );
  }

  getMyBatchesTracking() {
    return this.http.get<any>(`${environment.api}/get-my-batch-tracking`);
  }

  getMyTimelineBatch() {
    return this.http.get<any>(`${environment.api}/my-timeline-batch`);
  }

  timeLine(lotto: string) {
    return this.http.get<any>(`${environment.api}/send-batch/${lotto}`);
  }

  distributorMyBatch(id: any) {
    return this.http.get<any>(`${environment.api}/distributorMyBatch/${id}`);
  }

  lastBatchShipping(actor: string) {
    return this.http.get<any>(`${environment.api}/lastBatchShipping/${actor}`);
  }

  lastDistributoreShipping(isArriving: string) {
    return this.http.get<any>(
      `${environment.api}/lastDistributoreShipping?is_arriving=${isArriving}`
    );
  }

  lastDistributor() {
    return this.http.get<any>(`${environment.api}/get-last-distributor-batch`);
  }

  getYourTransformation(id: any) {
    return this.http.get<any>(`${environment.api}/get-your-treatments/${id}`);
  }

  getLastProductVendit() {
    return this.http.get<any>(`${environment.api}/getLastProductVendit`);
  }

  getVenditaTime(type: any) {
    return this.http.get<any>(`${environment.api}/tempi-di-vendita/${type}`);
  }

  getVenditeState() {
    return this.http.get<any>(`${environment.api}/stato-delle-vendite`);
  }

  scartoDelGiorno() {
    return this.http.get<any>(`${environment.api}/scarto-del-giorno`);
  }

  getMostSold() {
    return this.http.get<any>(`${environment.api}/il-piu-venduto`);
  }

  getArrivingProduct() {
    return this.http.get<any>(`${environment.api}/arriving-product`);
  }

  getMyBatchOnGiacencyPoint(type: any) {
    return this.http.get<any>(
      `${environment.api}/get-my-batch-on-giacency-point?type=${type}`
    );
  }

  storeDailyReport(data: any) {
    return this.http.post<any>(`${environment.api}/store-daily-report`, data);
  }

  getMyBatchesOnTimeLine(type?: any, search?: any, status?: string) {

    if (type && search && status) {
      console.log('1');

      return this.http.get<any>(`${environment.api}/get-my-history-batch?type=${type}&search=${search}&status=${status}`);
    }
    if (search && status) {
      console.log('2');
      return this.http.get<any>(`${environment.api}/get-my-history-batch?type=${type}&search=${search}&status=${status}`);
    }
    if (type && status) {
      console.log('2');
      return this.http.get<any>(`${environment.api}/get-my-history-batch?type=${type}&status=${status}`);
    }
    if (status) {
      console.log('3');
      return this.http.get<any>(`${environment.api}/get-my-history-batch?status=${status}`);
    }
    if (search) {
      console.log('4');
      return this.http.get<any>(`${environment.api}/get-my-history-batch?type=${type}&search=${search}`);
    }
    else {
      console.log('5');
      return this.http.get<any>(`${environment.api}/get-my-history-batch?type=${type}`);
    }
  }

  getProductsInStore(category?: number | null, search?: string | null) {
    return this.http.get<any>(
      `${environment.api}/vendors/products/in-store?category=${category}&search=${search}`
    );
  }

  getProductsToArrive(category?: number | null, search?: string | null) {
    return this.http.get<any>(
      `${environment.api}/vendors/products/arriving?category=${category}&search=${search}`
    );
  }

  qrCodeGenerate(data: IQrCode) {
    return this.http.post<any>(`${environment.api}/qr-code`, data);
  }

  getUserRole() {
    return this.http.get<any>(`${environment.api}/get-user-role`);
  }

  getMyHistoryBatchTimeline(roleId: any, batch: any) {
    return this.http.get<any>(
      `${environment.api}/get-my-history-batch-timeline/${batch}/${roleId}`
    );
  }

  getMyBatches(municipality_id?: number | null): Observable<IBatchOption[]> {
    return this.http
      .get<IBatchOption[]>(
        `${environment.api}/producer/batches?municipality_id=${municipality_id}`
      )
      .pipe(tap((batches) => this.batchesSubject.next(batches)));
  }

  getMyPlante() {
    return this.http.get<any>(`${environment.api}/get-my-plante`);
  }

  getMunicipality() {
    return this.http.get<any>(`${environment.api}/my-municipality`);
  }

  trendProduction(year: any, type: any, plante_id: any) {
    return this.http.get<any>(
      `${environment.api}/get-production-trend-last?year=${year}&type=${type}&plante_id=${plante_id}`
    );
  }

  getWeather(params: any) {
    return this.http.post<any>(`${environment.api}/getWeather`, params);
  }

  venditorCordinate(params: any) {

    return this.http.post<any>(`${environment.api}/VenditorCordinate`, params);
  }

  getQrProductsByCategory(category: any, skip : any , take : any) {
    return this.http.get<any>(`${environment.api}/consumers/products?category=${category}&skip=${skip}&take=${take}`);
  }

  getBatchFieldSowingAndReaping(batch_id: number) {
    return this.http.get<any>(
      `${environment.api}/producers/batches/field?batch_id=${batch_id}}`
    );
  }

  getMarketPricesForStatistics(municipality_id: number | null, year: string, category: number | null) {
    return this.http.get<any>(
      `${environment.api}/producer-market-price/${municipality_id}/${year}/${category}`
    );
  }


  getMarketPricesForStatisticsByMonth(municipality_id?: number | null, month?: number, year?: string, category?: number | null) {
    return this.http.get<any>(
      `${environment.api}/producer-market-price-by-month/${municipality_id}/${month}/${year}/${category}`
    );
  }

  getYourTreatments(date: any, search: string | null = null) {
    return this.http.get<any>(
      `${environment.api}/trasformers/your-treatments?date=${date}&search=${search}`
    );
  }

  addNotesToTreatment(treatmentId: number, params: any): Observable<FormattedResponse<any>> {
    return this.http.put<FormattedResponse<any>>(`${environment.api}/treatments/trasformations/${treatmentId}`, params);
  }


  getFile(path: string, filename: string): Observable<Blob> {
    return this.http.get(`${environment.api}/files/${filename}?path=${path}`, { responseType: 'blob' });
  }

  getReadyProducts(type: any) {
    return this.http.get<any>(`${environment.api}/get-ready-products/${type}`);
  }

  transferData(data: any) {
    return this.http.post<any>(`${environment.api}/store-in-batch-timeline`, data);
  }

  getMonths(year?: any) {
    return this.http.get<any>(`${environment.api}/get-months/${year}`);
  }

  getInfoInStock(year?: any, month?: any) {
    if (year && month) {
      return this.http.get<any>(`${environment.api}/get-info-in-stock?year=${year}&month=${month}`);
    }
    if (year) {
      return this.http.get<any>(`${environment.api}/get-info-in-stock?year=${year}`);
    }
    if (month) {
      return this.http.get<any>(`${environment.api}/get-info-in-stock?month=${month}`);
    }
    else {
      return this.http.get<any>(`${environment.api}/get-info-in-stock`);
    }
  }

  confermBatch(batchId: any) {
    return this.http.patch<any>(`${environment.api}/update-in-batch-timeline`, batchId);
  }

  getUserInfo() {
    return this.http.get<any>(`${environment.api}/profile`);
  }

  updateUserInfo(data: any) {
    return this.http.put<any>(`${environment.api}/profile`, data);
  }

  getUserInfoTransformer() {
    return this.http.get<any>(`${environment.api}/profile/transformer-profile`);
  }

  postUserInfoTransformer(data: any) {
    return this.http.post<any>(`${environment.api}/profile/transformer-profile`, data);
  }

  updateUserInfoTransformer(data: any, id:any) {
    return this.http.put<any>(`${environment.api}/profile/transformer-profile/${id}`, data);
  }

  getUserInfoDistributor() {
    return this.http.get<any>(`${environment.api}/profile/distributor-profile`);
  }

  postUserInfoDistributor(data: any) {
    return this.http.post<any>(`${environment.api}/profile/distributor-profile`, data);
  }

  updateUserInfoDistributor(data: any, id:any) {
    return this.http.put<any>(`${environment.api}/profile/distributor-profile/${id}`, data);
  }

  getUserInfoVendor() {
    return this.http.get<any>(`${environment.api}/profile/vendor-profile`);
  }

  postUserInfoVendor(data: any) {
    return this.http.post<any>(`${environment.api}/profile/vendor-profile`, data);
  }

  updateUserInfoVendor(data: any, id:any) {
    return this.http.put<any>(`${environment.api}/profile/vendor-profile/${id}`, data);
  }

  getAllProducerPlants() {
    return this.http.get<any>(`${environment.api}/profile/producer-all-plants`);
  }

  getSinglePlantInfo(plante_id: any) {
    return this.http.get<any>(`${environment.api}/profile/producer-single-plant/${plante_id}`);
  }

  postProducerPlant(data: any) {
    return this.http.post<any>(`${environment.api}/profile/producer-single-plante`, data);
  }

  updateProducerPlant(data: any, plantId: any) {
    return this.http.put<any>(`${environment.api}/profile/producer-single-plante/${plantId}`, data);
  }

  deletePlant(plant: any) {
    return this.http.delete<any>(`${environment.api}/profile/producer-delete-plant/${plant}`);
  }

  postInteractionEnginePredictComponent(data: IPredictComponentRequest){
    return this.http.post<any>(`${environment.api}/predict-component`, data);
  }

  postInteractionEnginePredictAction(device_type: any){
    return this.http.post<any>(`${environment.api}/predict-action`, device_type);
  }

  postClickExplodeWidget(data: IClickExplodeWidgetRequest){
    return this.http.post<any>(`${environment.api}/click-explode-widget`, data);
  }

  getBatchesByPlante(plante_id: number) {
    return this.http.get<any>(`${environment.api}/get-batch-by-plante/${plante_id}`);
  }

  getTreatmentsByBatch(batch_id: number) {
    return this.http.get<any>(`${environment.api}/get-activity-by-batch/${batch_id}`);
  }

  getProductByPlanteId(plante_id: number) {
    return this.http.get<any>(`${environment.api}/get-all-products-by-plant/${plante_id}`);
  }

  getActivitiesByProductId() {
    return this.http.get<any>(`${environment.api}/get-activity-by-product`);
  }

  postActivityPrice(data: any){
    return this.http.post<any>(`${environment.api}/store-activity-price`, data);
  }

  updateActivityPrice(data: any, market_price_id: any) {
    return this.http.put<any>(`${environment.api}/update-activity-price/${market_price_id}`, data);
  }

  getActivitiesForProducer(skip: number , take : number) {
    return this.http.get<any>(`${environment.api}/get-all-producer-activities?skip=${skip}&take=${take}`);
  }

  getActivitiesSumPrices(batchUId: string) {
    return this.http.get<any>(`${environment.api}/batches/${batchUId}/activities-sum`);
  }

  getAllBatchesByUser() {
    return this.http.get<any>(`${environment.api}/get-all-batch-by-user`);
  }

  getAllProductByBatchId(batchId : number) {
    return this.http.get<any>(`${environment.api}/get-all-products-by-batch/${batchId}`);
  }

  getAllActivitiesNonProducer() {
    return this.http.get<any>(`${environment.api}/get-all-actions`);
  }

  getActivitiesForNonProducer(skip: number , take : number) {
    return this.http.get<any>(`${environment.api}/get-all-batch-activities?skip=${skip}&take=${take}`);
  }

  storeConsumerFeedback(data: any) {
    return this.http.post<any>(
      `${environment.api}/consumer/feedback/add`,
      data,
      {
        context: new HttpContext().set(CONFIG, { isPublic: true }),
      }
    );
  }

  getFinalisationByBatchUnique(batch_unique_identifier: string) {
    return this.http.get<any>(`${environment.api}/finalisation?batch_unique_identifier=${batch_unique_identifier}`);
  }

  getVendorFinalisationsInStore() {
    return this.http.get<any>(`${environment.api}/vendor/products`);
  }

  getFinalisationConsumerFeedbacks(data : any) {
    console.log(data);

    const params = new HttpParams()
      .set('finalisation_id', data.finalisation_id)
      .set('age',data.age)
      .set('gender',data.gender)
    return this.http.get<any>(`${environment.api}/vendor/consumer/feedback`, { params });
  }

  getBatchWeight(batchUId: string) {
    return this.http.get<any>(`${environment.api}/batches/${batchUId}/weight`);
  }

  getBatchesWeightOnTimeline(batchUId: string) {
    return this.http.get<any>(`${environment.api}/get-batch-info-in-timeline/${batchUId}`);
  }

  getUserBatchesTimeline() {
    return this.http.get<any>(`${environment.api}/get-auth-batch-user-on-timeline`);
  }

}

