<div class="batch-waste-used">
  <div class="row consumed-container mb-1 align-items-center ">
    <div class="col-6">
      <h4 class="header-widget">Scarto lotto</h4>
    </div>
    <div [formGroup]="batchForm" class="col-6 alt-black-border ">
      <nz-select nzPlaceHolder="lotto" formControlName="batch_id" (ngModelChange)="getBatchesWeightOnTimeline()">
        <nz-option *ngFor="let batch of batchOptions" [nzLabel]="batch" [nzValue]="batch">
        </nz-option>
      </nz-select>
    </div>
  </div>

  <div class="content">
    <div class="row align-items-center">
      <div class="col-4 d-flex align-items-center">
        <img src="../../../assets/img/trash_can.svg" class="trash-can" alt="trash-can" />
      </div>
      <div class="col-4 d-flex flex-column justify-content-center fw-500">
        <div class="text-mini-widget">Quantità ricevuta</div>
        <span class="fs text-line" [ngClass]="{ 'fs-no-data': isStaticValue(item?.total_quantity) }">
          {{ totalWeightManipulation(item) }}
        </span>
      </div>
      <div class="col-4 d-flex flex-column justify-content-center data-cont px-0 fw-500">
        <div class="text-mini-widget">Quantità scartata</div>
        <span class="fs text-line" [ngClass]="{ 'fs-no-data': isStaticValue(item?.waste) }">
          {{ wasteWeightManipulation(item) }}
        </span>
      </div>
    </div>
  </div>
</div>
