import { NgModule } from '@angular/core';
import { ProducerRoutingModule } from './producer-routing.module';
import { WeatherComponent } from './components/weather/weather.component';
import { MarketPricesComponent } from './components/market-prices/market-prices.component';
import { ProductionTrendComponent } from './components/production-trend/production-trend.component';
import { ReportFmsComponent } from './pages/report-fms/report-fms.component';
import { PlantationsComponent } from './pages/plantations/plantations.component';
import { WidgetReportFmsComponent } from './components/widget/widget-report-fms/widget-report-fms.component';
import { WidgetWeatherComponent } from './components/widget/widget-weather/widget-weather.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/app.module';
import { HttpClient } from '@angular/common/http';
import { AlertComponent } from './components/alert/alert.component';
import { WidgetFeedbackComponent } from './components/widget/widget-feedback/widget-feedback.component';
import { Container1Component } from './components/container1/container1.component';
import { Container2Component } from './components/container2/container2.component'; // Importa il modulo NzSelectModule
import { DoughnutAndPieChartComponent } from 'src/app/modules/producer/components/widget/doughnut-and-pie-chart/doughnut-and-pie-chart.component';
import { SunChartComponent } from 'src/app/modules/producer/components/widget/sun-chart/sun-chart.component';
import { WaterChartComponent } from 'src/app/modules/producer/components/widget/water-chart/water-chart.component';
import { LineChartComponent } from './components/widget/line-chart/line-chart.component';
import { BarChartComponent } from './components/widget/bar-chart/bar-chart.component';
import { ConsumedEnergyComponent } from './components/widget/consumed-energy/consumed-energy.component';
import { WidgetLatestTransformationsComponent } from './components/widget/widget-latest-transformations/widget-latest-transformations.component';
import { NoDataComponent } from './components/widget/no-data/no-data.component';
import { TransformerHome1Component } from './components/transformer-home1/transformer-home1.component';
import { WidgetProductsComingSoonComponent } from './components/widget/widget-products-coming-soon/widget-products-coming-soon.component';
import { QuantityRejectedComponent } from './components/widget/quantity-rejected/quantity-rejected.component';
import { WidgetWaterTransformerComponent } from './components/widget/widget-water-transformer/widget-water-transformer.component';
import { WidgetInStockComponent } from './components/widget/widget-in-stock/widget-in-stock.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ActivityTypeComponent } from './components/activity-type/activity-type.component';
import { DistributorLotsComponent } from './components/widget/distributor-lots/distributor-lots.component';
import { CardDistributorLotsComponent } from './components/widget/card-distributor-lots/card-distributor-lots.component';
import { TransporterHomeComponent } from './components/transporter/transporter-home.component';
import { LatestShipmentsComponent } from './components/widget/latestShipments/latestShipments.component';
import { TransporterHome2Component } from './components/transporter-home2/transporter-home2.component';
import { DeliveriesWidgetComponent } from './components/widget/deliveries-widget/deliveries-widget.component';
import { WidgetArrivingDepartingProductsComponent } from './components/widget/widget-arriving-departing-products/widget-arriving-departing-products.component';
import { ShippingStatusComponent } from './components/widget/shipping-status/shipping-status.component';
import { SalePointHomeComponent } from './components/salePoint-home/salePoint-home.component';
import { SalesStatusComponent } from './components/widget/SalesStatus/SalesStatus.component';
import { BestSellingProductComponent } from './components/widget/bestSellingProduct/bestSellingProduct.component';
import { ProductsForSaleComponent } from './components/widget/ProductsForSale/ProductsForSale.component';
import { SalesTimesComponent } from './components/widget/SalesTimes/SalesTimes.component';
import { SellerInStockComponent } from './components/widget/SellerInStock/SellerInStock.component';
import { SellerUpcomingProductsComponent } from './components/widget/sellerUpcomingProducts/sellerUpcomingProducts.component';
import { DiscardOfTheDayComponent } from './components/widget/DiscardOfTheDay/DiscardOfTheDay.component';
import { AddReportComponent } from './components/widget/addReport/addReport.component';
import { HistoricalLotsComponent } from './pages/historical-lots/historical-lots.component';
import { HistoricalLotsDetailComponent } from './components/historical-lots-detail/historical-lots-detail.component';
import { ConsumerComponent } from './components/consumer/consumer.component';
import { SharedModule } from 'src/app/shared/shared/shared.module';
import { TreatmentPriceHandlerComponent } from './treatment-prices-handler/treatment-prices-handler.component';

@NgModule({
  declarations: [
    ReportFmsComponent,
    WeatherComponent,
    PlantationsComponent,
    MarketPricesComponent,
    ProductionTrendComponent,
    WidgetReportFmsComponent,
    WidgetWeatherComponent,
    AlertComponent,
    WidgetFeedbackComponent,
    Container1Component,
    Container2Component,
    DoughnutAndPieChartComponent,
    SunChartComponent,
    WaterChartComponent,
    LineChartComponent,
    BarChartComponent,
    NoDataComponent,
    TransformerHome1Component,
    TransporterHomeComponent,
    SalePointHomeComponent,
    TransporterHome2Component,
    WidgetProductsComingSoonComponent,
    QuantityRejectedComponent,
    ConsumedEnergyComponent,
    WidgetLatestTransformationsComponent,
    WidgetWaterTransformerComponent,
    WidgetInStockComponent,
    CalendarComponent,
    ActivityTypeComponent,
    DistributorLotsComponent,
    CardDistributorLotsComponent,
    LatestShipmentsComponent,
    DeliveriesWidgetComponent,
    WidgetArrivingDepartingProductsComponent,
    ShippingStatusComponent,
    SalesStatusComponent,
    BestSellingProductComponent,
    ProductsForSaleComponent,
    SalesTimesComponent,
    SellerInStockComponent,
    SellerUpcomingProductsComponent,
    DiscardOfTheDayComponent,
    AddReportComponent,
    HistoricalLotsComponent,
    HistoricalLotsDetailComponent,
    ConsumerComponent,
    TreatmentPriceHandlerComponent
  ],
  imports: [
    SharedModule,
    ProducerRoutingModule,
    // SunChartComponent,
    // WaterChartComponent,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    Container1Component,
    Container2Component,
    TransformerHome1Component,
    TransporterHomeComponent,
    TransporterHome2Component,
    SalePointHomeComponent,
    ConsumerComponent
  ]
})
export class ProducerModule { }
