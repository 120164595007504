<div class="container d-flex align-items-center">
  <div class="container d-flex align-items-center">
    <div class=" d-flex align-items-center">
      <span class="plus" (click)="showModal()">
        <img src="../../../../../../assets/img/add-button.svg" alt="">
      </span>
      <span class="header-widget">
        Aggiungi report del giorno
      </span>
    </div>
  </div>

  <!-- Modal -->
  <div class="report-modal">
    <nz-modal [nzClosable]="false" [nzTitle]="'Report del giorno'" [nzOkLoading]="isOkLoading" [(nzVisible)]="isVisible" (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
      <ng-container *nzModalContent>
        <form [formGroup]="formReport">
          <div class="select-prod padding-b">
            <div class="modal-tex marg-b">Seleziona il tuo prodotto</div>
            <div class="d-flex align-items-center">
              <span *ngFor="let prod of productsType"
                [ngClass]="{'selected': prod.selected, 'border-selected': prod.selected, 'border-to-select': !prod.selected,}"
                class="icon-to-select d-flex align-items-center justify-content-around"
                (click)="selectProductType(prod.id)">
                <img [src]="prod.path" alt="">
              </span>
            </div>
          </div>

          <div class="select-lotto padding-b">
            <div class="modal-tex marg-b">Seleziona il lotto per la quale vuoi fare il report</div>
            <div class="modal-select">
              <nz-select nzAllowClear nzPlaceHolder="Seleziona un lotto" formControlName="lotto"
                [(ngModel)]="selectedValue">
                <!-- <nz-option nzLabel="Jack" nzValue="jack"></nz-option> -->
                <nz-option *ngFor="let item of batchToSelect" [nzLabel]="item" [nzValue]="item"></nz-option>
              </nz-select>
            </div>
          </div>

          <div class="kg-insert padding-b">
            <div class="modal-tex marg-b">Inserisci i kg venduti e quelli scartati</div>
            <div class="d-flex justify-content-between">
              <div>
                <input class="form-control" type="number" formControlName="sold" placeholder="kg venduti" min="1">
              </div>
              <div>
                <input class="form-control" type="number" formControlName="rejected" placeholder="kg scartati" min="0">
              </div>
            </div>
          </div>
        </form>
      </ng-container>
      <div class="modal-footer" *nzModalFooter>
        <button nz-button nzType="default" class="modal-button cancel" (click)="handleCancel()">Cancella</button>
        <button nz-button nzType="primary" class="modal-button save" (click)="handleOk()"
          [nzLoading]="isOkLoading">Invia</button>
      </div>
    </nz-modal>
  </div>