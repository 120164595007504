<div class="shipment-tracking-container">
  <div class="row">
    <div class="col-12 header d-flex justify-content-between align-items-center">
      <div class="title">Monitoraggio spedizioni</div>
      <div class="select-search-container d-none d-lg-flex justify-content-between align-items-center">
        <form nz-form [nzLayout]="'inline'" [formGroup]="form" class="wide-form">
          <div class="select-container form-container d-flex justify-content-between align-items-center">
            <span class="filters">Filtra per lotto:</span>
            <div class="d-flex justify-content-between align-items-center">
              <nz-form-item>
                <nz-select nzPlaceHolder="Lotto" nzBorderless formControlName="lotto" (ngModelChange)="submitForm()"
                  class="wide-select">
                  <nz-option *ngFor="let lotto of batchToSelect" [nzValue]="lotto" [nzLabel]="lotto"></nz-option>
                </nz-select>
              </nz-form-item>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="col-12 timeline">
      <div class="timeline-header">
        <div class="timeline-header__title">Ultima spedizione</div>
        <div class="timeline-header__filter-cta">
          <a href="javascript:void(0)" (click)="toggleCollapsedForMobileFiltering($event)"
            (keydown)="toggleCollapsedForMobileFiltering($event)">
            Filtra
            <i class="icon-custom settings-orange mt-2"></i>
          </a>
        </div>
      </div>
      <form class="box-filter-form wide-form" nz-form
        [class.box-filter-form--open]="!isToggleCollapsedForMobileFiltering" [nzLayout]="'inline'" [formGroup]="form">
        <div class="filter-title">Filtra per lotto</div>
        <nz-form-item>
          <nz-select nzPlaceHolder="Lotto" nzBorderless formControlName="lotto" (ngModelChange)="submitForm()"
            class="wide-select">
            <nz-option *ngFor="let lotto of batchToSelect" [nzValue]="lotto" [nzLabel]="lotto"></nz-option>
          </nz-select>
        </nz-form-item>
      </form>
      <div class="row timeline-content">
        <div class="col-12 d-flex align-items-center padding-b">
          <div class="current-lotto-container d-flex flex-column">
            <span class="current-lotto-title">Lotto</span>
            <span class="current-lotto">{{shipment?.lotto}}</span>
          </div>
          <div class="current-state-container d-flex flex-column">
            <span class="current-lotto-title">Stato</span>
            <span class="shipment-status">{{shipment?.status}}</span>
          </div>
        </div>

        <div class="col-12 d-flex" [ngClass]="{
          'flex-column align-items-start': (isMobileWithTablet$ | async),
          'align-items-center': !(isMobileWithTablet$ | async)
          }">
          <ng-container *ngIf="(isMobileWithTablet$ | async) else timelineDesktop">
            <!-- begin: Mobile/Tablet timeline -->
            <div class="steps" *ngFor="let item of shipment?.steps; let i = index; let last = last;">
              <div class="line-container d-flex flex-row">
                <div class="d-flex flex-column align-items-center">
                  <div class="actor-img"
                    [ngClass]="{'relative': item.status != 'progress', 'border-finish-color': item.status == 'progress', 'border-wait-color': item.status != 'progress'}">
                    <img [src]="item.path" alt="">
                    <div *ngIf="item.status == 'finish'" class="overlay">
                      <i class="fas fa-check"></i>
                    </div>
                    <div *ngIf="item.status == 'wait'" class="overlay-wait">
                    </div>
                  </div>
                  <div [class.transit]="item.transit">
                    <ng-container *ngIf="item.transit else layoutNoTransit">
                      <div class="progress-line-finish-color" [class.progress-line]="!last"></div>
                      <div class="progress-line-wait-color" [class.progress-line]="!last"></div>
                    </ng-container>
                    <ng-template #layoutNoTransit>
                      <div [class.progress-line]="!last"
                        [ngClass]="{'progress-line-finish-color': item.status == 'finish', 'progress-line-wait-color': item.status != 'finish'}">
                      </div>
                    </ng-template>
                  </div>
                </div>
                <div class="info d-flex flex-column">
                  <div
                    [ngClass]="{'actor-name-no-wait': item.status != 'wait', 'actor-name-wait': item.status == 'wait'}">
                    {{item.name}}
                  </div>
                  <span class="step-shipment-status" [class.d-none]="item.status == 'wait'">{{ item.shipmentStatus
                    }}</span>
                  <span class="step-shipment-date" [class.d-none]="item.status == 'wait'">
                    {{ item.shipmentStatus == 'Spedito'
                    ? (item.shippingDate | date:'dd MMMM yyyy')
                    : (item.deliveryDate | date:'dd MMMM yyyy') }}
                  </span>

                  <a href="javascript:void(0)" class="details" nz-popover [class.d-none]="item.status == 'wait'"
                    [nzPopoverPlacement]="'bottom'" [nzPopoverTrigger]="'click'" [nzPopoverContent]="contentTemplate"
                    [nzPopoverVisible]="item.visible" (click)="item?.status != 'wait' && openDetails(item, $event)"
                    (keydown)="item?.status != 'wait' && openDetails(item, $event)">
                    Dettagli
                  </a>
                </div>
              </div>
            </div>
            <!-- end: Mobile/Tablet timeline -->
          </ng-container>
        </div>

        <!-- begin: Desktop timeline -->
        <ng-template #timelineDesktop>
          <div class="steps" *ngFor="let item of shipment?.steps; let i = index; let last = last;">
            <div class="line-container d-flex flex-column">
              <div [ngClass]="{'actor-name-no-wait': item.status != 'wait', 'actor-name-wait': item.status == 'wait'}">
                {{item.name}}
              </div>
              <div class="d-flex align-items-center">
                <div class="actor-img"
                  [ngClass]="{'relative': item.status != 'progress', 'border-finish-color': item.status == 'progress', 'border-wait-color': item.status != 'progress'}">
                  <img [src]="item.path" alt="">
                  <div *ngIf="item.status == 'finish'" class="overlay">
                    <i class="fas fa-check"></i>
                  </div>
                  <div *ngIf="item.status == 'wait'" class="overlay-wait">
                  </div>
                </div>
                <div [class.transit]="item.transit">
                  <div [class.progress-line]="!last"
                    [ngClass]="{'progress-line-finish-color': item.status == 'finish', 'progress-line-wait-color': item.status != 'finish'}">
                  </div>
                </div>
              </div>

              <div class="info d-flex flex-column" [class.invisible]="item.status == 'wait'">
                <span class="step-shipment-status">{{ item.shipmentStatus }}</span>
                <span class="step-shipment-date">
                  {{ item.shipmentStatus == 'Spedito'
                  ? (item.shippingDate | date:'dd MMMM yyyy')
                  : (item.deliveryDate | date:'dd MMMM yyyy') }}
                </span>

                <a href="javascript:void(0)" class="details" nz-popover [nzPopoverPlacement]="'bottom'"
                  [nzPopoverTrigger]="'click'" [nzPopoverContent]="contentTemplate" [nzPopoverVisible]="item.visible"
                  (click)="item?.status != 'wait' && openDetails(item, $event)"
                  (keydown)="item?.status != 'wait' && openDetails(item, $event)">
                  Dettagli
                </a>
              </div>
            </div>
          </div>
        </ng-template>
        <!-- end: Desktop timeline -->

        <ng-template #contentTemplate>
          <div class="popover-other-info">
            <div class="other-details-actor-container d-flex justify-content-between align-items-center">
              <div class="other-details-actor">{{item?.name}}</div>
              <button type="button" aria-label="Chiudi" class="other-details-buttons"
                (click)="closeDetail(item, $event)" (keydown)="closeDetail(item, $event)">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <mask id="mask0_2062_16807" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24"
                    height="24">
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_2062_16807)">
                    <path
                      d="M6.4 19L5 17.6L10.6 12L5 6.4L6.4 5L12 10.6L17.6 5L19 6.4L13.4 12L19 17.6L17.6 19L12 13.4L6.4 19Z"
                      fill="#5B6671" />
                  </g>
                </svg>
              </button>
            </div>
            <hr class="header-divider" />
            <div *ngFor="let site of item?.otherInfo" class="actual-role d-flex flex-column">
              <div *ngIf="item?.shippingDate">Spedito: {{ item?.shippingDate | date:'dd MMMM yyyy' }}</div>
              <div *ngIf="item?.shippingDate==null">Spedito: Non ancora spedito</div>

              <ng-container [ngSwitch]="item?.name">
                <ng-container *ngSwitchCase="'Produttore'">
                  <div>Campo: {{site.field}}</div>
                </ng-container>
                <ng-container *ngSwitchCase="'Selezione e stoccaggio'">
                  <div>Sede: {{site.site}}</div>
                </ng-container>
                <ng-container *ngSwitchCase="'Distributore'">
                  <div>Mezzo: {{site.shippingType}}</div>
                </ng-container>
                <ng-container *ngSwitchCase="'Punto Vendita'">
                  <div>Punto: {{site.marketPoint}}</div>
                </ng-container>
                <ng-container *ngSwitchCase="'Consumatore'">
                  <div>Sede: {{site.consSite}}</div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                </ng-container>
              </ng-container>
            </div>

            <div class="pre-role d-flex flex-column">
              <div class="other-details-actor">Prodotto</div>
              <div>
                <span class="prod-details-title">Lotto: </span>
                <span class="prod-details-data">{{shipment?.lotto}}</span>
              </div>
              <div>
                <span class="prod-details-title">Prodotto: </span>
                <span class="prod-details-data">{{shipment?.cultivar}}</span>
              </div>
              <div *ngIf="item?.role?.toLowerCase()?.trim() !== 'produttore' ">
                <span class="prod-details-title">Quantità ricevuta: </span>
                <span class="prod-details-data">{{ weightReceivedManipulation(item) }} kg</span>
              </div>
              <!-- Punto vendita da rimuovere completamente e sostituire  con venditore  -->
              <div *ngIf="item?.role?.toLowerCase()?.trim() !== 'punto vendita' ">
                <span class="prod-details-title">Quantità inviata: </span>
                <span class="prod-details-data">{{ weightSentManipulation(item) }} kg</span>
              </div>
              <div *ngIf="item?.role?.toLowerCase()?.trim() !== 'produttore' ">
                <span class="prod-details-title">Quantità scartata: </span>
                <span class="prod-details-data">{{ weightWasteManipulation(item) }} </span>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
