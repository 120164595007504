import { Component, Input } from '@angular/core';
import { IWidget } from '../../sidebar/Interfaces/IWidget';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common.service';
import { LayoutService } from '../../layout.service';
import { Observable } from 'rxjs';
import { NzCarouselComponent } from 'ng-zorro-antd/carousel';
@Component({
  selector: 'app-most-used-activities',
  templateUrl: './most-used-activities.component.html',
  styleUrls: ['./most-used-activities.component.scss'],
})
export class MostUsedActivitiesComponent {
  @Input() favoriteActivities: IWidget[] | null = null;

  local: boolean = window.location.hostname.includes('localhost') ?? false;
  isMobileWithTablet$: Observable<boolean>;

  favoriteActivitiesAlt: any = [
    {
      name: 'Ultima spedizione',
      icon: '/assets/icon/monitoraggio_spedizioni_orange.svg',
      url: 'https://dev-citrace.darwintech.it/shipment-tracking'
    },
    {
      name: 'feedback',
      icon: '/assets/icon/feedback_orange.svg',
      url: 'https://dev-citrace.darwintech.it/app/feedback'
    },
  ];

  constructor(
    private readonly _router: Router,
    private readonly _commonService: CommonService,
    private readonly _layoutService: LayoutService
  ) {
    this.isMobileWithTablet$ = this._layoutService.isMobileWithTablet$;
  }

  goTo(activity: any, event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }

    let url = activity.url
      ? activity.url.replace(/^https?:\/\/localhost:4200/, '')
      : '';

    if (url.startsWith('/')) {
      this._router.navigate([url]);
    } else {
      if (!url.startsWith('http')) {
        url = `${environment.useHttps ? 'https' : 'http'}://${url}`;
      }
      window.location.href = url;
    }
  }

  carouselPre(carousel: NzCarouselComponent, event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    carousel.pre();
  }

  carouselNext(carousel: NzCarouselComponent, event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    carousel.next();
  }
}
