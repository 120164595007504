<div *ngIf="loading" class="loading-container flex-content-center flex-center">
  <ng-lottie [options]="options" class="d-flex m-auto" style="height: 15rem">
  </ng-lottie>
</div>

<div class="d-flex flex-column justify-content-between">
  <div *ngIf="components.sidebar; else noSidebar" class="d-flex" [class.flex-row]="!(isMobileWithTablet$ | async)"
    [class.flex-column]="(isMobileWithTablet$ | async)">

    <div [class.col-2]="(isSidebarVisible$ | async) && !(isMobileWithTablet$ | async)"
      *ngIf="(isSidebarVisible$ | async)">
      <app-sidebar *ngIf="components.sidebar"></app-sidebar>
    </div>
    <div [ngClass]="{
      'col-10 right-page' : ((isSidebarVisible$ | async) && !(isMobileWithTablet$ | async)),
      'full-page' : !(isSidebarVisible$ | async) || ((isSidebarVisible$ | async) && (isMobileWithTablet$ | async)),
      'full-page--padding-mobile' : (isSidebarVisible$ | async) && (isMobileWithTablet$ | async)
    }">
      <router-outlet>
        <div *ngIf="_loading.loadingSub | async" class="loading-overlay">
          <nz-spin class="loading-spinner" nzTip=""></nz-spin>
          <div class="loading-text">Loading...</div>
        </div>
      </router-outlet>
    </div>

  </div>
  <ng-template #noSidebar>
    <div class="d-flex flex-column flex-grow-1">

      <router-outlet>
        <div *ngIf="_loading.loadingSub | async" class="loading-overlay">
          <nz-spin class="loading-spinner" nzTip=""></nz-spin>
          <div class="loading-text">Loading...</div>
        </div>
      </router-outlet>
    </div>
  </ng-template>
  <!-- <app-footer *ngIf="components.footer"></app-footer> -->
</div>