import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { toast } from 'src/app/app.component';
import Swal from 'sweetalert2';
import { LoggingService } from 'src/app/components/Logging.service';
import { Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/components/common.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  form!: FormGroup;
  containerLg?: boolean;
  containersm?: boolean;
  access_token?: any;
  password?: string;
  passwordVisible: boolean = false;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly _authService: AuthService,
    private readonly activated: ActivatedRoute,
    private readonly _loggingService: LoggingService,
    private readonly _commonService: CommonService
  ) { }

  ngOnInit(): void {
    if (this.activated.snapshot.url[1]) {
      Swal.fire({
        title: "Mail arrivata?",
        text: "Controlla la tua casella di posta e inserisci la nuova password",
        icon: "question"
      });
    }

    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  goToCreateAccountPage(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.router.navigate(['auth/register']);
  }

  togglePasswordVisibility(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.passwordVisible = !this.passwordVisible;
  }

  submitForm(): void {
    this._authService.login(this.form.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(r => {
        localStorage.setItem("hasRunPrediction", "false");
        this.access_token = r.access_token
        this._authService.currentUser.next(r.user);
        const sessionId = String(r.session_id);
        localStorage.setItem('session_id', sessionId);
        localStorage.setItem('role_id', r.role_id);
        const token = this.access_token;
        this.setCookie('access_token', token, 100)
        if (r.role_id == 1) {
          this.router.navigate(['admin/user-management']);
        } else {
          const browserInfo = {
            name: navigator.appName,
            version: navigator.appVersion
          };
          sessionStorage.setItem('browserInfo', JSON.stringify(browserInfo));

          // Memorizza la grandezza dello schermo nel sessionStorage
          const screenSize = {
            width: window.innerWidth,
            height: window.innerHeight
          };
          sessionStorage.setItem('screenSize', JSON.stringify(screenSize));

          const storedBrowserInfo = sessionStorage.getItem('browserInfo');
          if (storedBrowserInfo) {
            const browserInfo = JSON.parse(storedBrowserInfo);
          } else {
            console.log('Nessuna informazione sul browser memorizzata.');
          }

          // Recupera la grandezza dello schermo dal sessionStorage
          const storedScreenSize = sessionStorage.getItem('screenSize');
          if (storedScreenSize) {
            const screenSize = JSON.parse(storedScreenSize);
          } else {
            console.log('Nessuna informazione sulla grandezza dello schermo memorizzata.');
          }

          const data = {
            session_id: sessionId,
            browser_data: {
              brawserName: browserInfo.name,
              brawserVersion: browserInfo.version,
              screenWidth: screenSize.width,
              screenHeight: screenSize.height
            }
          };

          this._loggingService.interacionEngineSessionData(data)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(r => {
              this.router.navigate(['app/dashboard']);
            });
        }
      },
        error => {
          toast.fire({
            title: 'Respinto',
            text: error.error,
            icon: 'warning'
          })
        }
      );
  }

  private setCookie(name: string, value: string, expireDays: number): void {
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + expireDays);
    const cookieValue = encodeURIComponent(name) + '=' + encodeURIComponent(value) + ';expires=' + expireDate.toUTCString() + ';path=/';
    document.cookie = cookieValue;
  }
}
