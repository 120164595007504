<div class="statistics-container">
  <div class="d-flex justify-content-between align-items-center mb-4">
    <div class="title">Statistiche</div>
  </div>

  <nz-tabset [nzSelectedIndex]="selectedTab">
    <nz-tab nzTitle="Andamento produzione">
      <app-production-rend></app-production-rend>
    </nz-tab>
    <nz-tab nzTitle="Storico meteorologico">
      <app-historical-weather [municipalityId]="municipality_id_from_route"
        [municipalityLabel]="municipality_desc_from_route">
      </app-historical-weather>
    </nz-tab>
    <nz-tab nzTitle="Prezzi di mercato">
      <app-statistics-market-prices [municipalityId]="municipality_id_from_route"
        [municipalityLabel]="municipality_desc_from_route"></app-statistics-market-prices>
    </nz-tab>
  </nz-tabset>
</div>
