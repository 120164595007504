import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  /*
  BehaviorSubject per verifica dimensioni display per mobile/tablet (smartphone e tablet)
  */
  private readonly isMobileWithTabletSubject = new BehaviorSubject<boolean>(false);
  isMobileWithTablet$ = this.isMobileWithTabletSubject.asObservable();

  /*
  BehaviorSubject per verifica dimensioni display per solo mobile (SOLO smartphone
  esclusi tablet)
  */
  private readonly isMobileWithoutTabletSubject = new BehaviorSubject<boolean>(false);
  isMobileWithoutTablet$ = this.isMobileWithoutTabletSubject.asObservable();

  constructor() {
    /*
    Inizializzo gli stati di controllo se si tratta di un display per mobile/tablet
    */
    this.checkIsMobileWithTabletDisplay();
    this.checkIsMobileWithoutTabletDisplay();

    /*
    Inizializzo l'evento per poter aggiornare lo stato di controllo (ad ogni resize
    della window) se si tratta di un display per mobile/tablet
    */
    window.addEventListener('resize', () => {
        this.checkIsMobileWithTabletDisplay();
        this.checkIsMobileWithoutTabletDisplay();
    });
  }

  /**
   * Aggiorna il booleano che verifica se è entro la soglia di dimensione display
   * mobile/tablet (smartphone e tablet)
   * @returns 
   */
  private checkIsMobileWithTabletDisplay(): void {
    this.isMobileWithTabletSubject.next(window.innerWidth <= 992);
  }

  /**
   * Aggiorna il booleano che verifica se è entro la soglia di dimensione display
   * mobile (SOLO smartphone esclusi tablet)
   * @returns 
   */
  private checkIsMobileWithoutTabletDisplay(): void {
      this.isMobileWithoutTabletSubject.next(window.innerWidth <= 767);
  }
}
