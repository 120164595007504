<div>
  <div class="d-flex justify-content-between header">
    <div>
      <h4 class="header-widget">Prodotti in arrivo / partenza</h4>
    </div>
    <div class="d-flex justify-content-between">
      <div class="select-year">
        <nz-select class="test" nzPlaceHolder="Seleziona un anno" [(ngModel)]="selectedValue"
          (ngModelChange)="getData()">
          <nz-option nzLabel="In arrivo" [nzValue]="'true'"></nz-option>
          <nz-option nzLabel="In partenza" [nzValue]="'false'"></nz-option>
        </nz-select>
      </div>
      <span class="arrow" style="cursor: pointer;" (click)="explodeWidget()" onkeypress="">
        <img src="/assets/img/arrow.svg" alt="">
      </span>
    </div>
  </div>

  <table class="product-table table custom-table" *ngIf="loaded">
    <thead>
      <tr>
        <th class="col padding-th" *ngFor="let c of cols">{{c}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let prod of data">
        <td class="item-1">{{prod.batchId}}</td>
        <td class="item-2">{{prod.arriving == 'Arrivo' ? (prod.deliveryDate | date:'dd/MM/yyyy') : (prod.shippingDate |
          date:'dd/MM/yyyy')}}</td>
        <td class="item-2">{{prod.agency}}</td>
        <td class="item-1">{{prod.product}}</td>
        <td class="item-2">{{prod.timeInSelectionMagazine}} gg</td>
        <td class="item-2">{{prod.quantity}} kg </td>
      </tr>
    </tbody>
  </table>

</div>
