import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserRole } from 'src/app/shared/models/user-role.enum';
import { User } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ComponentService } from '../component.service';
import Swal from 'sweetalert2';
import { Utils } from 'src/app/shared/helpers/utils';
import { ISection } from './Interfaces/ISection';
import { CommonService } from '../common.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  role_id: string = localStorage.getItem('role_id') ?? '';
  isToggleCollapsedForMobileMenu: boolean = true;
  isVisible: boolean = false;
  isOkLoading = false;
  type: any;
  selectedbatch: any;
  selectedRole = null;
  dataForm!: FormGroup;
  userId: any;
  widgetsFromPredict: [] = [];
  selectedBatchSumPrices: number | null = null;
  batchWeight = null;
  productsType = [
    {
      id: 1,
      path: '/assets/icon/modal-orange-icon.svg',
      selected: false,
    },
    {
      id: 2,
      path: '/assets/icon/modal-lemon-icon.svg',
      selected: false,
    },
  ];
  batchToSelect: [] | null = null;
  roleNaleLabel!: any;
  roleToSelect: any;
  currentRole: any;
  recevingUser: any;
  minSum: number = 0;

  public sections: ISection[] = [
    {
      icon: '/assets/icon/dashboard_alt.svg',
      label: 'Dashboard',
      link: '/app/dashboard',
      role_id: ['1', '2', '3', '4', '5', '6'],
    },
    {
      icon: '/assets/icon/quaderno-attivita.svg',
      label: 'Quaderno delle attività',
      link: '/app/producer/reports',
      role_id: ['2'],
    },
    {
      icon: '/assets/icon/prezzi_attivita.svg',
      label: 'Costi attività',
      link: '/app/producer/treatment-prices-handler',
      role_id: ['2', '5', '6'],
    },
    {
      icon: '/assets/icon/statistiche.svg',
      label: 'Statistiche',
      link: '/app/statistics',
      role_id: ['1', '2'],
    },
    {
      icon: '/assets/icon/prodotti.svg',
      label: 'Prodotti',
      link: '/app/vendor/your-products',
      role_id: ['4'],
    },
    {
      icon: '/assets/icon/storico_lotti.svg',
      label: 'Storico lotti',
      link: '/app/lot-history',
      role_id: ['1', '2', '3', '4', '5', '6'],
    },
    {
      icon: '/assets/icon/monitoraggio_spedizioni.svg',
      label: 'Monitoraggio spedizioni',
      link: '/shipment-tracking',
      role_id: ['2', '3', '4', '5', '6'],
    },
    {
      icon: '/assets/icon/trattamenti.svg',
      label: 'Trattamenti',
      link: '/app/your-treatments',
      role_id: ['5'],
    },
    {
      icon: '/assets/icon/feedback.svg',
      label: 'Feedback',
      link: '/app/feedback',
      role_id: ['1', '2', '3', '4', '5', '6'],
    },
    {
      icon: '/assets/icon/magazzino.svg',
      label: 'Magazzino',
      link: '/app/warehouse/' + this.role_id,
      role_id: ['5', '6'],
    },
    {
      icon: '/assets/icon/trasferisci_dati.svg',
      label: 'Trasferimento dati',
      link: 'trasferimento',
      role_id: ['2', '5', '6'],
    },
  ];
  public sections2: {
    icon: string | null;
    label: string;
    callback?: () => void;
  }[] = [
      {
        icon: '/assets/icon/logout.svg',
        label: 'Esci',
        callback: () => this.logout(),
      },
    ];
  public admin_sections: ISection[] = [
    {
      icon: './../../assets/icon/gestione_utenti.svg',
      label: 'Gestione utenti',
      link: '/admin/user-management',
    },
    {
      icon: './../../assets/icon/gestioni_widgets.svg',
      label: 'Gestione widgets',
      link: '/admin/widget-management',
    },
  ];
  currentUser: User | undefined = undefined;
  roles = UserRole;
  step: number = 0.01;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _componentService: ComponentService,
    private readonly router: Router,
    private readonly _auth: AuthService,
    private readonly fb: FormBuilder,
    private readonly _utils: Utils,
    private readonly _commonService: CommonService
  ) {
    this.currentUser = this._auth.currentUser.value;

    this.dataForm = this.fb.group({
      lotto: [null, Validators.required],
      company: [null, Validators.required],
      price: [null, Validators.required],
      note: [null],
    });
  }

  ngOnInit(): void {
    this.getUser();
    this.getUserRole();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  goToPath(link: string, event?: Event) {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    const pageBodyContent: HTMLElement | null = document.getElementById('page-body');

    this.isToggleCollapsedForMobileMenu = true;
    pageBodyContent?.classList.remove('open-mobile-sidebar');
    if (link == 'trasferimento') {
      this.showModal();
    } else {
      this.router.navigate([link]);
    }
  }

  isPathActive(url: string): boolean {
    if (this.router.url.startsWith(url)) {
      return true;
    }
    return false;
  }

  logout() {
    this._auth
      .logout()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.deleteCookie('access_token');
        this._auth.currentUser.next(null);

        this.router.navigate(['/auth/login']);
      });
  }

  deleteCookie(name: string) {
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() - 1);
    document.cookie = '';
  }

  onSectionClick(section: {
    icon: string | null;
    label: string;
    link?: string;
    role_id?: string;
    callback?: () => void;
  }) {
    if (section.callback) {
      section.callback();
    } else if (section.link) {
      this.router.navigate([section.link]);
    }
  }

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    const priceControl = this.dataForm.get('price');
    if (this.dataForm.valid) {
      let data = {
        batch_id: this.dataForm.value.lotto,
        receiver_id: this.dataForm.value.company,
        sum: this.dataForm.value.price,
        note: this.dataForm.value.note,
      };

      this._componentService
        .transferData(data)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((r) => {
          this.isOkLoading = true;
          this.handleCancel();

          Swal.fire({
            title: 'Inviato',
            text: 'Lotto inviato con successo',
            showConfirmButton: false,
            icon: 'success',
            timer: 2000,
          }).then(() => {
            window.location.reload();
          });
        });
    } else if (priceControl!.hasError('min')) {
      Swal.fire({
        title: 'Costo troppo basso',
        text: `Il costo deve essere almeno € ${this.minSum}.`,
        showConfirmButton: false,
        icon: 'warning',
        timer: 2000,
      });
    } else {
      Swal.fire({
        title: 'Campi mancanti',
        text: 'Compila tutti i campi correttamente.',
        showConfirmButton: false,
        icon: 'warning',
        timer: 2000,
      });
    }
  }

  handleCancel(): void {
    this.isVisible = false;
    this.dataForm.reset();
    this.isOkLoading = false;

    this.productsType.forEach((prod) => {
      prod.selected = false;
    });

    this.batchToSelect = null;
    this.minSum = 0;
  }

  selectProductType(id: number): void {
    this.productsType.forEach((p) => (p.selected = false));
    const product = this.productsType.find((p) => p.id === id);
    if (product) {
      product.selected = true;
      this.type = product.id;
      this._componentService
        .getReadyProducts(this.type)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((r) => {
          this.batchToSelect = r.data.products;
          this.roleNaleLabel = r.data.successiveRoleName;
          this.roleToSelect = r.data.receiverRoleName;
        });
    }
  }

  getUserRole() {
    this._componentService
      .getUserRole()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.currentRole = r;
        switch (this.currentRole) {
          case 2:
            this.recevingUser = 'Selezione e stoccaggio';
            break;
          case 5:
            this.recevingUser = 'Distributore';
            break;
          case 6:
            this.recevingUser = 'Venditore';
            break;

          default:
            break;
        }
      });
  }

  getUser() {
    this._auth
      .getUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.userId = r.data.id;
      });
  }

  goToProfile(event?: Event) {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    const pageBodyContent: HTMLElement | null = document.getElementById('page-body');

    this.isToggleCollapsedForMobileMenu = true;
    pageBodyContent?.classList.remove('open-mobile-sidebar');
    this.router.navigate(['app/user-profile/' + this.userId]);
  }

  postInteractionEnginePredictComponent(user: any) {
    const data = {
      user_id: user.id,
      role_id: localStorage.getItem('role_id'),
      device_type: this._utils.getEnhancedDeviceType(),
    };
    this._componentService
      .postInteractionEnginePredictComponent(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (response.code === 200) {
          this.widgetsFromPredict = response.data;
        }
      });
  }

  onBatchChange(batchId: string) {
    if (batchId) {
      this.getSumActivitiesPrice(batchId);
      this.getBatchWeight(batchId);
    }
  }

  getSumActivitiesPrice(batchId: string) {
    this._componentService
      .getActivitiesSumPrices(batchId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (response.code === 200) {
          this.selectedBatchSumPrices = response.data.sumToReturn;
          this.minSum = parseFloat(response.data.minSum);

          const priceControl = this.dataForm.get('price');
          priceControl!.setValidators([
            Validators.required,
            Validators.min(this.minSum),
          ]);

          priceControl!.updateValueAndValidity();
          this.dataForm.patchValue({
            price: this.selectedBatchSumPrices,
          });
        }
      });
  }

  getBatchWeight(batchId: string) {
    this._componentService
      .getBatchWeight(batchId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: any) => {
        this.batchWeight = result;
      });
  }

  toggleCollapsedForMobileMenu(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }

    const pageBodyContent: HTMLElement | null =
      document.getElementById('page-body');
    this.isToggleCollapsedForMobileMenu = !this.isToggleCollapsedForMobileMenu;
    if (
      !this.isToggleCollapsedForMobileMenu &&
      !pageBodyContent?.classList?.contains('open-mobile-sidebar')
    ) {
      pageBodyContent?.classList.add('open-mobile-sidebar');
    } else if (this.isToggleCollapsedForMobileMenu) {
      pageBodyContent?.classList.remove('open-mobile-sidebar');
    }
  }
}
