<div *ngIf="showWidget" class="piantagioni-container align-items-center">
  <div class="d-flex justify-content-between widget-container">
    <h4 class="header-widget">Piantagioni</h4>
    <span class="cursor-pointer" (click)="explodeWidget($event)" (keydown)="explodeWidget($event)">
      <img src="/assets/img/arrow.svg" alt="">
    </span>
  </div>
  <div *ngIf="doughnutChartData.datasets[0].data">
    <div class="box-chart">
      <canvas id="doughnutChart" baseChart [data]="doughnutChartData" [type]="'doughnut'"
        [options]="doughnutChartOptions" [plugins]="doughnutChartPlugins">
      </canvas>

    </div>

    <div class="legenda row">
      <div class="legenda__item col-12 d-flex justify-content-between" *ngFor="let item of legendData">
        <div class="box-title d-flex flex-row align-items-center">
          <div class="color-spot" [style.background-color]="item.color"></div>
          <div>{{item.name}}</div>
        </div>
        <div class="box-value d-flex flex-row align-items-center justify-content-end flex-wrap">
          <div>({{item.percentage}}%)</div>
          <div class="text-g">{{item.total}} ha</div>
        </div>
      </div>
    </div>
  </div>

</div>

<div *ngIf="!showWidget" class="piantagioni-container align-items-center">
  <h1>{{noData}}</h1>
</div>
