import { Component, Input } from '@angular/core';
import { IWidget } from 'src/app/components/sidebar/Interfaces/IWidget';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-transformer-home1',
  templateUrl: './transformer-home1.component.html',
  styleUrls: ['./transformer-home1.component.scss'],
})
export class TransformerHome1Component {
  @Input() widgetsFromPredict: [] = [];
  @Input() favoriteActivities: IWidget[] | null = null;
  widget = new Array();

  constructor(
    private authService: AuthService,
    private adminService: AdminService
  ) {}

  ngOnInit() {
    this.authService.getUser().subscribe((r) => {
      let role_id = localStorage.getItem('role_id');
      this.getAllWidget(role_id);
    });
  }

  getAllWidget(role_id: any) {
    this.adminService.getAllwidgetRole(role_id).subscribe((r) => {
      r.data.forEach((element: { value: any }) => {
        this.widget.push(element.value);
      });
      console.log('tutti i wigdet trasformatore', this.widget);
    });
  }
}
