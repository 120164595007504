<div class="lot-history">
  <div class="lot-history__header row">
    <div class="col-12 col-lg-8">
      <h2 class="header-title">Storico lotti</h2>
    </div>
    <div class="col-12 col-lg-4">
      <nz-input-group [nzPrefix]="prefixIconSearch">
        <input type="text" autocomplete="off" aria-autocomplete="none" nz-input [placeholder]="'Cerca'"
          [(ngModel)]="search" (keyup.enter)="searchData()" />
      </nz-input-group>
      <ng-template #prefixIconSearch>
        <span nz-icon nzType="search"></span>
      </ng-template>
    </div>
  </div>

  <div class="lot-history__content row">
    <div class="col-12">
      <div class="filters-header">
        <div class="d-flex align-items-center">
          <div class="filters-header__cta-filter"
            [ngClass]="{'selected': type.selected, 'not-selected': !type.selected}"
            (click)="selectProductType(type.id, $event)" (keydown)="selectProductType(type.id, $event)"
            *ngFor="let type of productsType">
            <div *ngIf="type.path" class="img">
              <img [src]="type.path" [alt]="type.label">
            </div>
            <div class="label">{{type.label}}</div>
          </div>
        </div>
        <div
          class="d-flex flex-column flex-lg-row align-items-start justify-content-start align-items-lg-center justify-content-lg-end gap-3 w-100"
          *ngIf="userRole != 2">
          <div class="notify" *ngIf="alert > 0">
            <img src="../../../assets/icon/notifications.svg" alt="">
            Hai {{alert}} lotti in attesa di conferma
          </div>
          <div class="select-status">
            <nz-select nzPlaceHolder="Seleziona stato" [(ngModel)]="status" (ngModelChange)="getFilterDataStatus()"
              class="select-list custom-select">
              <ng-container>
                <nz-option nzLabel="Confermato" nzValue="confermato"></nz-option>
                <nz-option nzLabel="Da confermare" nzValue="attesa"></nz-option>
              </ng-container>
            </nz-select>
          </div>
        </div>
      </div>

      <div class="table-content">
        <table class="table table-striped">
          <thead>
            <tr>
              <th class="font-title" *ngFor="let col of cols">{{col}}</th>
            </tr>
          </thead>
          <tbody>
            <tr [class.cursor-pointer]="active" (click)="goTo(batch.batch_id, $event)"
              (keydown)="goTo(batch.batch_id, $event)" *ngFor="let batch of data,let i = index;">
              <td class="text-style-big padd">{{ batch?.batch_id}}</td>
              <td class="text-style-medium">{{ batch?.product_name}}</td>
              <td class="text-style-medium">{{ batch?.productor }}</td>
              <td *ngIf="batch?.quantity" class="text-style-medium">{{
                weightManipulationQuantity(batch)}}
              </td>
              <td>
                <span *ngIf="batch.batch_id !== 'Nessun dato'">{{ weightManipulationWaste(batch) }}</span>
              </td>
              <td>
                <div class="badge" [ngClass]="{
                  'spedito': batch?.status == 'spedito',
                  'consegnato': batch?.status == 'consegnato',
                  'transito': batch?.status == 'in transito',
                  'in-arrivo': batch?.status == 'In arrivo'
                }">
                  <div class="circle"></div>
                  <div class="text-style-big">{{ batch?.status}}</div>
                </div>
              </td>
              <td class="note-column">
                <div class="table-note">
                  <a href="javascript:void(0)" class="note" (click)="openNoteModal(batch, i, $event)"
                    (keydown)="openNoteModal(batch, i, $event)">
                    {{ noteManipulation(batch) | slice: 0: 12 }}{{ noteManipulation(batch).length > 12 ? '...' : '' }}
                  </a>
                </div>
              </td>

              <td class="text-style-medium content-confirm" *ngIf="userRole != 2 && batch.batch_id !== 'Nessun dato'">
                <button type="button" nz-button [nzType]="'primary'" class="confirm-button"
                  [ngClass]="{'confirm-button--confirmed': !batch.is_waiting, 'confirm-button--to-confirmed': batch.is_waiting}"
                  (click)="openModal(batch, $event)" (keydown)="openModal(batch, $event)"
                  *ngIf="batch.requesting_user_id != batch.receiving_user_id">
                  {{batch.is_waiting == 0 ? 'Confermato' : 'Conferma'}}
                </button>

                <!-- Secondo blocco: se requesting_user_id e receiving_user_id sono entrambi uguali a 5 -->
                <button type="button" nz-button [nzType]="'primary'" class="confirm-button"
                  [ngClass]="{'confirm-button--confirmed': batch.confirmed, 'confirm-button--to-confirmed': !batch.confirmed}"
                  (click)="openModal(batch, $event)" (keydown)="openModal(batch, $event)"
                  *ngIf="batch.requesting_user_id == batch.receiving_user_id">
                  {{batch.confirmed !== null && batch.confirmed == 1 ? 'Confermato' : 'Conferma'}}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- begin: Mobile/Tablet lot list -->
      <div class="list-lot-mobile" *ngIf="data?.length">
        <div class="list-lot-mobile__item-list" [class.cursor-pointer]="active" (click)="goTo(batch.batch_id, $event)"
          (keydown)="goTo(batch.batch_id, $event)" *ngFor="let batch of data, let i = index;">
          <div class="header-list">
            <div class="header-list__box-title">
              <p class="description">Lotto</p>
              <p class="title">{{batch.batch_id}}</p>
            </div>
            <div class="header-list__badge badge" [ngClass]="{
              'spedito': batch?.status == 'spedito',
              'consegnato': batch?.status == 'consegnato',
              'transito': batch?.status == 'in transito',
              'in-arrivo': batch?.status == 'In arrivo'
            }">
              <div class="circle"></div>
              <div class="text-style-big">{{ batch?.status}}</div>
            </div>
          </div>
          <div class="content-item">
            <div class="content-item__row">
              <div>
                <div class="row-title">Prodotto</div>
                <div class="row-value">{{batch.product_name }}</div>
              </div>
            </div>
            <div class="content-item__row">
              <div>
                <div class="row-title">Produttore</div>
                <div class="row-value">{{batch.productor}}</div>
              </div>
            </div>
            <div class="content-item__row">
              <div>
                <div class="row-title">Quantità ricevuta</div>
                <div class="row-value">{{weightManipulationQuantity(batch)}}</div>
              </div>
            </div>
            <div class="content-item__row">
              <div>
                <div class="row-title">Quantità scartata</div>
                <div class="row-value">{{weightManipulationWaste(batch)}}</div>
              </div>
            </div>
            <div class="content-item__row" *ngIf="noteManipulation(batch).length > 0">
              <div>
                <div class="row-title">Note</div>
                <div class="row-value">
                  <img class="img-click cursor-pointer" src="/assets/icon/quaderno_attivita_orange.svg" alt="note"
                    (click)="openNoteModal(batch, i, $event)" (keydown)="openNoteModal(batch, i, $event)" />
                </div>
              </div>
            </div>
            <div class="content-item__row" *ngIf="userRole != 2 && batch.batch_id !== 'Nessun dato'">
              <div>
                <div class="row-value content-confirm w-100">
                  <button type="button" nz-button [nzType]="'primary'" class="confirm-button"
                    [ngClass]="{'confirm-button--confirmed': !batch.is_waiting, 'confirm-button--to-confirmed': batch.is_waiting}"
                    (click)="openModal(batch, $event)" (keydown)="openModal(batch, $event)"
                    *ngIf="batch.requesting_user_id != batch.receiving_user_id">
                    {{batch.is_waiting == 0 ? 'Confermato' : 'Conferma'}}
                  </button>

                  <!-- Secondo blocco: se requesting_user_id e receiving_user_id sono entrambi uguali a 5 -->
                  <button type="button" nz-button [nzType]="'primary'" class="confirm-button"
                    [ngClass]="{'confirm-button--confirmed': batch.confirmed, 'confirm-button--to-confirmed': !batch.confirmed}"
                    (click)="openModal(batch, $event)" (keydown)="openModal(batch, $event)"
                    *ngIf="batch.requesting_user_id == batch.receiving_user_id">
                    {{batch.confirmed !== null && batch.confirmed == 1 ? 'Confermato' : 'Conferma'}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end: Mobile/Tablet lot list -->
    </div>
  </div>
</div>

<nz-modal [nzClosable]="false" [nzClassName]="'modal-note'" [nzTitle]="'Nota'" [nzCancelText]="'Chiudi'"
  [(nzVisible)]="modalNoteVisible" (nzOnCancel)="closeNoteModal()">
  <ng-container *nzModalContent>
    <div *ngIf="selectedItem">
      <div class="modal-note-content">
        <div class="other-info d-flex flex-column">
          <div class="other-details-actor-container mb-4">
            <div class="d-flex flex-column note-item"
              [ngClass]="{ 'border-bottom': !(i === selectedItem.notes.length - 1) }"
              *ngFor="let note of selectedItem.notes; let i = index"> <span> Nota da : <strong>
                  {{ note?.name }} {{ note?.first_name }} {{ note?.last_name }}
                </strong></span>
              <span> Descrizione: {{ note?.note }}</span>
              <span> Nota lasciata il: {{ note?.date }} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</nz-modal>

<nz-modal [nzClosable]="false" [nzClassName]="'modal-lot-reception'" [nzTitle]="'Ricezione lotto'"
  [(nzVisible)]="modalLotVisible" (nzOnCancel)="closeModal()" (nzOnOk)="confirm()">
  <ng-container *nzModalContent>
    <form class="lotform mb-4" nz-form [formGroup]="lotForm">
      <div class="row pb-3">
        <div class="col-5">
          <span> <strong> Lotto: </strong> {{selectedBatch?.batch_id}} </span>
        </div>
        <div class="col-7">
          <span> <strong> Prodotto: </strong> {{selectedBatch?.product_name}} </span>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-5">
          <span>
            <strong> Totale : </strong> {{ selectedBatch?.quantity }} kg
          </span>
        </div>
        <div class="col-7 d-flex">
          <span><strong> Scarto : </strong></span>
          <input nz-input type="number" [max]="selectedBatch?.quantity" formControlName="waste"
            class="modal-batch-waste ms-2 " (input)="onWasteInput($event)" />
        </div>
      </div>
    </form>
  </ng-container>
</nz-modal>