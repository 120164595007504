<div class="warehouse-container">
  <div class="d-flex align-items-center justify-content-between header">
    <div class="title">Magazzino</div>
    <div class="select-warehouse select-warehouse-cont d-flex align-items-center">
      <span class="strong-span">Seleziona:</span>
      <nz-select nzPlaceHolder="Anno" [(ngModel)]="selectedYear" (ngModelChange)="getMonths()" nzBorderless
        class="wide-select">
        <nz-option *ngFor="let year of years" [nzValue]="year" [nzLabel]="year"></nz-option>
      </nz-select>
      <nz-select nzPlaceHolder="Mese" [(ngModel)]="selectedMonth" (ngModelChange)="getInfoInStock()" nzBorderless
        class="wide-select">
        <nz-option *ngFor="let month of months" [nzValue]="month.key" [nzLabel]="month.value"></nz-option>
      </nz-select>
    </div>
  </div>

  <div class="selected-date">{{ stringMonth(selectedMonth) }} {{selectedYear}}</div>
  <div class="widgets-sontainer d-flex ">
    <div class="widget-left d-flex align-items-center">
      <div *ngIf="loadWidget" class="chart">
        <canvas id="doughnutChart" baseChart [data]="doughnutChartData" [type]="'doughnut'"
          [options]="doughnutChartOptions" [plugins]="doughnutChartPlugins">
        </canvas>
      </div>
      <div class="legend-chart">
        <div class="legend" *ngFor="let legend of chartLegend">
          <div class="d-flex align-items-center">
            <div class="circle" [ngStyle]="{ 'background-color': legend.color }"></div>
            <div class="label">{{legend.label}}</div>
          </div>
          <div class="quantity">{{ legend.quantity | number:'1.2-2' }}% dello spazio occupato</div>
        </div>
      </div>
    </div>
    <div class="widget-right d-flex flex-column justify-content-between">
      <div class="quantity-container quantity-container-color-in d-flex align-items-center">
        <div class="arrow-icon">
          <img src="../../../assets/img/download-icon.svg" alt="">
        </div>
        <div class="d-flex flex-column">
          <div class="label">Totale entrate del mese</div>
          <div class="quantity">{{entrance}} kg</div>
        </div>
      </div>
      <div class="quantity-container quantity-container-color-out d-flex align-items-center">
        <div class="arrow-icon">
          <img src="../../../assets/img/upload-icon.svg" alt="">
        </div>
        <div class="d-flex flex-column">
          <div class="label">Totale uscite del mese</div>
          <div class="quantity">{{exit}} kg</div>
        </div>
      </div>
    </div>
  </div>

  <div class="table-container">
    <div class="d-flex align-items-center justify-content-between">
      <div class="selected-date">Lotti in magazzino</div>
      <div class="d-flex">
        <div class="d-flex arrows-container">
          <div class="arrow">
            <img src="../../../assets/img/arrow-down-green.svg" alt="">
          </div>
          <div>Ingresso</div>
        </div>
        <div class="d-flex">
          <div class="arrow">
            <img src="../../../assets/img/arrow-up-orange.svg" alt="">
          </div>
          <div>Uscita</div>
        </div>
      </div>
    </div>

    <div class="table-cont">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="font-title" *ngFor="let col of cols">{{col}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data">
            <td class="text-style-big padd">
              <div class="d-flex">
                <div class="tab-arrow" *ngIf="item.is_requesting">
                  <img src="../../../assets/img/arrow-up-orange.svg" alt="">
                </div>
                <div class="tab-arrow" *ngIf="!item.is_requesting">
                  <img src="../../../assets/img/arrow-down-green.svg" alt="">
                </div>
                <div class="d-flex flex-column">
                  <div class="font-title">{{item.batch ? item.batch : 'Nessun dato'}}</div>
                  <div class="text-style-medium">{{item.type ? item.type : 'Nessun dato'}}</div>
                </div>
              </div>
            </td>
            <td class="text-style-medium">{{item.producer ? item.producer : 'Nessun dato'}}</td>
            <td class="text-style-medium">{{item.giacency ? item.giacency : '0'}} giorni</td>
            <td *ngIf="item?.quantity" class="text-style-medium">{{item.quantity ? item.quantity : '0'}} kg</td>
            <td>
              <div class="d-flex flex-column">
                <div class="text-style-medium">Arrivo: {{item.arriving ? item.arriving : 'Nessun dato'}}</div>
                <div class="text-style-medium">Uscita: {{item.shipping ? item.shipping : 'non definita'}}</div>
              </div>
            </td>
            <td class="text-style-medium">{{item.destination ? item.destination : 'Nessun dato'}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
