import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ComponentService } from 'src/app/components/component.service';
import { Router } from '@angular/router';
import { LoggingService } from 'src/app/components/Logging.service';
import { Utils } from 'src/app/shared/helpers/utils';
import { lastValueFrom, Observable, Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/components/common.service';
import { LayoutService } from 'src/app/components/layout.service';

@Component({
  selector: 'app-latestShipments',
  templateUrl: './latestShipments.component.html',
  styleUrls: ['./latestShipments.component.scss'],
})
export class LatestShipmentsComponent implements OnInit, OnDestroy {
  @Input() actor!: string;

  visible: boolean = false;
  selectedItemIndex: number = -1;
  batchToSelect: any;
  item!: any;
  lastBatch!: string;
  loaded: boolean = false;
  shipment: any;
  lastShipmentWithProgressOrFinished: any;

  isMobileWithTablet$: Observable<boolean>;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _ComponentService: ComponentService,
    private readonly router: Router,
    private readonly _loggingService: LoggingService,
    private readonly _utils: Utils,
    private readonly _layoutService: LayoutService,
    private readonly _commonService: CommonService
  ) {
    this.isMobileWithTablet$ = this._layoutService.isMobileWithTablet$;
  }

  ngOnInit(): void {
    this.getLatestLotto();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getData(): void {
    this._ComponentService.timeLine(this.lastBatch)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response: any) => {
        this.shipment = response?.data;
        this.lastShipmentWithProgressOrFinished = this.shipment?.steps
          ?.filter((step: any) => step.shippingDate && (step.status === "progress" || step.status === "finish"))
          ?.pop();
        this.loaded = true;
      });
  }

  getLatestLotto(): void {
    this._ComponentService.lastBatchShipping(this.actor)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response: any) => {
        this.lastBatch = response?.data;
        this.getData();
      });
  }

  explodeWidget(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this._utils.handleWidgetClick('/shipment-tracking', 33);
    this.router.navigate(['shipment-tracking']);
    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-latestShipments',
    };
    lastValueFrom(this._loggingService.logClick(data));
  }
}
