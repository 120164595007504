<div class="row energy-container">
  <div class="energy-content">
    <div class="col-6 d-flex justify-content-between title-cont">
      <h4 class="header-widget">Energia</h4>
    </div>
    <div class="col-12 text-mini-widget">
      <p>Consumo medio per Kg di agrumi</p>
    </div>
    <div class="col-12 data-cont">
      <span class="data">{{kvh ? kvh : 0}}</span>
      <span class="kWh">kWh</span>
    </div>
  </div>
</div>
