<div class="login">
  <div class="row">
    <div class="login__box-img col-12 col-lg-6">
      <!-- Template form mobile/tablet -->
      <div class="d-flex d-lg-none justify-content-center align-items-center">
        <ng-container *ngTemplateOutlet="boxForm"></ng-container>
      </div>
    </div>
    <div class="login__box-login col-12 col-lg-6 pe-0">
      <!-- Template form desktop -->
      <ng-container *ngTemplateOutlet="boxForm"></ng-container>
    </div>
  </div>
</div>

<!-- begin: Template box form -->
<ng-template #boxForm>
  <div class="box-form">
    <div class="box-form__img-logo">
      <img src="/assets/icon/logo-citrace-color-1.svg" alt="CiTrace" />
    </div>
    <form class="box-form__form" nz-form [nzLayout]="'vertical'" [formGroup]="form"
      (ngSubmit)="form.valid && submitForm()">
      <h2 class="form-title">Login</h2>
      <p class="form-description">Inserisci la tua email e password per accedere alla dashboard</p>
      <nz-form-item class="mb-2">
        <nz-form-control nzErrorTip="Inserisci la mail">
          <nz-input-group>
            <input type="text" class="h-input" nz-input formControlName="email" placeholder="Email" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="mb-0">
        <nz-form-control nzErrorTip="Inserisci la password">
          <nz-input-group [nzSuffix]="suffixTemplate">
            <input [type]="passwordVisible ? 'text' : 'password'" nz-input placeholder="Password"
              formControlName="password" />
          </nz-input-group>
          <ng-template #suffixTemplate>
            <span nz-icon class="font-icon" [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
              (click)="togglePasswordVisibility($event)" (keydown)="togglePasswordVisibility($event)"></span>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <button nz-button class="submit-form" [nzType]="'primary'">Accedi</button>
      <div class="form-info">
        <span class="form-info__create-account">
          Non hai un account?
          <a href="javascript:void(0)" (click)="goToCreateAccountPage($event)"
            (keydown)="goToCreateAccountPage($event)">
            Registrati
          </a>
        </span>
        <a class="form-info__forgot-password" href="javascript:void(0)" [routerLink]="'/auth/password-forgotten'">
          Password dimenticata?
        </a>
      </div>
    </form>
  </div>
</ng-template>
<!-- end: Template box form -->