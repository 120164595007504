import { Injectable } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { IClickExplodeWidgetRequest } from 'src/app/components/sidebar/Interfaces/IClickExplodeWidgetRequest';
import { ComponentService } from 'src/app/components/component.service';
import { AuthService } from '../services/auth.service';
import { IWidget } from 'src/app/components/sidebar/Interfaces/IWidget';

@Injectable({
  providedIn: 'root',
})
export class Utils {
  env: string = window.location.hostname.includes('localhost') // dinamizzazione fe , da sostituire con una be per migliorare il flusso e rimuovere questo uso di hostname
    ? 'localhost:4200'
    : 'https://dev-citrace.darwintech.it';
  role = localStorage.getItem('role') ?? 2;

  widgets: IWidget[] = [
    {
      id: 1,
      name: 'piantagioni',
      value: 'app-doughnut-and-pie-chart',
      url: this.env + '/producer/reports',
      icon: '/assets/icon/quaderno_attivita_orange.svg',
      roles: ['2'],
    },
    {
      id: 2,
      name: 'reports',
      value: 'app_report_fms',
      url: this.env + '/producer/reports',
      icon: '/assets/icon/quaderno_attivita_orange.svg',
      roles: ['2'],
    },
    {
      id: 3,
      name: 'alert fms',
      value: 'app_alert_fms',
      url: this.env + '/producer/reports',
      icon: '/assets/icon/quaderno_attivita_orange.svg',
      roles: ['2'],
    },
    {
      id: 4,
      name: 'prezzi di mercato',
      value: 'app-line-chart',
      url: this.env + '/statistics',
      params: '/tab=3',
      icon: '/assets/icon/statistiche_orange.svg',
      roles: ['2'],
    },
    {
      id: 5,
      name: 'meteo',
      value: 'app_meteo',
      url: this.env + '/statistics',
      params: 'tab=2',
      icon: '/assets/icon/statistiche_orange.svg',
      roles: ['2'],
    },
    {
      id: 6,
      name: 'andamento produzione',
      value: 'app-bar-chart',
      url: this.env + '/statistics',
      params: 'tab=1',
      icon: '/assets/icon/statistiche_orange.svg',
      roles: ['2'],
    },
    {
      id: 7,
      name: 'feedback',
      value: 'feedback',
      url: this.env + '/feedback',
      icon: '/assets/icon/feedback_orange.svg',
      roles: ['2', '4', '5', '6'],
    },
    {
      id: 8,
      name: 'ultime trasformazioni',
      value: 'app-widget-latest-transformations',
      icon: '/assets/icon/magazzino_orange.svg',
      roles: ['5'],
    },
    {
      id: 9,
      name: 'magazzino',
      value: 'app-widget-in-stock',
      url: this.env + '/warehouse/' + this.role,
      icon: '/assets/icon/magazzino_orange.svg',
      roles: ['4', '5', '6'],
    },
    {
      id: 10,
      name: 'prodotti in arrivo',
      value: 'app-widget-products-coming-soon',
      url: this.env + '/warehouse/' + this.role,
      icon: '/assets/icon/prodotti_orange.svg',
      roles: ['4', '5', '6'],
    },
    {
      id: 11,
      name: 'prodotti in arrivo / partenza',
      value: 'app-widget-arriving-departing-products',
      url: this.env + '/warehouse/' + this.role,
      icon: '/assets/icon/prodotti_orange.svg',
      roles: ['4', '5', '6'],
    },
    {
      id: 12,
      name: 'ultima spedizione',
      value: 'app-latestShipments',
      url: this.env + '/shipment-tracking',
      icon: '/assets/icon/monitoraggio_spedizioni_orange.svg',
      roles: ['2', '4', '5', '6'],
    },
    {
      id: 13,
      name: 'Tracciamento spedizioni',
      value: 'shipment-tracking',
      url: this.env + '/shipment-tracking',
      icon: '/assets/icon/monitoraggio_spedizioni_orange.svg',
      roles: ['2', '4', '5', '6'],
    },
    {
      id: 14,
      name: 'I tuoi lotti',
      value: 'app-distributor-lots',
      icon: '/assets/icon/storico_lotti_orange.svg',
      roles: ['2'],
    },
    {
      id: 15,
      name: 'Stato vendite',
      value: 'app-SalesStatus',
      icon: '/assets/icon/magazzino_orange.svg',
      roles: ['4'],
    },
    {
      id: 16,
      name: 'Scarto del giorno',
      value: 'app-DiscardOfTheDay',
      icon: '/assets/icon/magazzino_orange.svg',
      roles: ['4'],
    },
    {
      id: 17,
      name: 'Più venduto',
      value: 'app-bestSellingProduct',
      url: this.env + '/your-products',
      icon: '/assets/icon/magazzino_orange.svg',
      roles: ['4'],
    },
    {
      id: 18,
      name: 'Prodotti in vendita',
      value: 'app-ProductsForSale',
      url: this.env + '/your-products',
      icon: '/assets/icon/prodotti_orange.svg',
      roles: ['4', '5'],
    },
    {
      id: 19,
      name: 'Tempi di vendita',
      value: 'app-SalesTimes',
      icon: '/assets/icon/magazzino_orange.svg',
      roles: ['4'],
    },
    {
      id: 20,
      name: 'In magazzino (vendita)',
      value: 'app-SellerInStock',
      url: this.env + '/your-products',
      icon: '/assets/icon/magazzino_orange.svg',
      roles: ['4'],
    },
    {
      id: 21,
      name: 'In arrivo (vendita)',
      value: 'app-sellerUpcomingProducts',
      icon: '/assets/icon/magazzino_orange.svg',
      roles: ['4'],
    },
    {
      id: 22,
      name: 'I tuoi Prodotti',
      value: 'app-distributor-lots',
      icon: '/assets/icon/magazzino_orange.svg',
      roles: ['4'],
    },
    {
      id: 23,
      name: 'Aggiungi report',
      value: 'app-addReport',
      icon: '/assets/icon/magazzino_orange.svg',
      roles: ['2'],
    },
    {
      id: 24,
      name: 'Statistiche',
      value: 'statistics',
      url: this.env + '/statistics',
      icon: '/assets/icon/statistiche_orange.svg',
      roles: ['2'],
    },
    {
      id: 25,
      name: 'Andamento produzione',
      value: 'production-trend',
      url: this.env + '/statistics',
      params: 'tab=1',
      icon: '/assets/icon/statistiche_orange.svg',
      roles: ['2'],
    },
    {
      id: 26,
      name: 'Storico meteorologico',
      value: 'historical-weather',
      url: this.env + '/statistics',
      params: 'tab=2',
      icon: '/assets/icon/statistiche_orange.svg',
      roles: ['2'],
    },
    {
      id: 27,
      name: 'Prezzi di mercato',
      value: 'market-prices',
      url: this.env + '/statistics',
      params: 'tab=3',
      icon: '/assets/icon/statistiche_orange.svg',
      roles: ['2'],
    },
    {
      id: 28,
      name: 'In magazzino (generale)',
      value: 'warehouse',
      url: this.env + '/warehouse/' + this.role,
      icon: '/assets/icon/magazzino_orange.svg',
      roles: ['4', '5', '6'],
    },
    {
      id: 29,
      name: 'I tuoi trattamenti',
      value: 'your-treatments',
      url: this.env + '/your-treatments',
      icon: '/assets/icon/quaderno_attivita_orange.svg',
      roles: ['2', '5', '6'],
    },
    {
      id: 30,
      name: 'I tuoi prodotti',
      value: 'your-products',
      url: this.env + '/vendor/your-products',
      icon: '/assets/icon/magazzino_orange.svg',
      roles: ['4'],
    },
  ];

  constructor(
    private router: Router,
    private _componentsService: ComponentService,
    private _authService: AuthService
  ) {}

  /**
   * Funzione per gestire il click del widget e inviare la chiamata
   * @param route - La route su cui navigare dopo la chiamata
   * @param widget_id - L'id del widget selezionato
   * @param params - I parametri , se presenti da inviare
   */
  handleWidgetClick(route?: string | null, widget_id?: number, params?: any) {
    const device_type = this.getEnhancedDeviceType();
    const browserInfo = this.getBrowserInfo();
    const resolution = this.getScreenResolution();
    const zoomLevel = this.getZoomLevel();
    const darkMode = this.isDarkMode();
    const language = this.getLanguage();
    const routeToSend = route ?? this.router.url;
    const widget = this.getComponentWidgetMatch(routeToSend, params);
    const time_now = new Date().toISOString();

    this._authService.me().subscribe((response) => {
      const dataToSend: IClickExplodeWidgetRequest = {
        user: {
          user_id: response?.data?.id
            ? String(response.data.id)
            : '0c91c753-661e-4c4a-95c4-7b33003e5f2a',
          role: response?.data?.role?.display_name ?? 'Trasformatore',
        },
        device: {
          device_type: device_type,
          browser: browserInfo.browser,
          browser_version: browserInfo.browser_version,
          resolution: resolution,
          zoom: zoomLevel,
          dark_mode: darkMode,
        },
        session_id:
          localStorage.getItem('session_id') ??
          '0c91c753-661e-4c4a-95c4-7b33003e5f2d',
        page_url: this.env + routeToSend,
        component: widget?.value,
        element: widget?.name,
        selector: widget?.value,
        widget_id: widget_id ?? 1,
        timestamp: time_now,
        language: language,
      };
      this._componentsService
        .postClickExplodeWidget(dataToSend)
        .subscribe((response) => {
          console.log(response);
        });
    });
  }

  /**
   * Naviga alla nuova route dopo la chiamata API
   * @param route - La route su cui navigare
   */
  navigateTo(route: string): void {
    this.router.navigate([route]);
  }

  /**
   * Determina il tipo di dispositivo dell'utente basato solo sulla larghezza dello schermo
   * @returns {string} - Il tipo di dispositivo ('mobile', 'tablet', 'laptop', 'desktop')
   */
  getDeviceType(): string {
    const width = window.innerWidth;

    if (width <= 768) {
      return 'mobile';
    } else if (width > 768 && width <= 1024) {
      return 'tablet';
    } else if (width > 1024 && width <= 1600) {
      return 'laptop';
    } else {
      return 'desktop';
    }
  }

  /**
   * Determina il tipo di dispositivo dell'utente basato su larghezza dello schermo, supporto touch e user agent
   * @returns {string} - Il tipo di dispositivo ('mobile', 'tablet', 'laptop', 'desktop')
   */
  getEnhancedDeviceType(): string {
    const width = window.innerWidth;
    const userAgent = navigator.userAgent.toLowerCase();
    const isTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    if (width <= 768 || userAgent.includes('mobile')) {
      return 'mobile';
    } else if (
      (width > 768 && width <= 1024) ||
      userAgent.includes('tablet') ||
      isTouch
    ) {
      return 'tablet';
    } else if (width > 1024 && width <= 1600 && !isTouch) {
      return 'laptop';
    } else {
      return 'desktop';
    }
  }

  /**
   * Recupera le informazioni sul browser dell'utente
   * @returns {Object} - Oggetto con le proprietà 'browser' e 'browser_version'
   */
  getBrowserInfo() {
    const userAgent = navigator.userAgent;
    let browser = 'Unknown';
    let version = 'Unknown';

    // Updated regex patterns to avoid excessive backtracking
    const chromeRegex = /\b(?:Chrome|CriOS)\/(\d+)\b/;
    const firefoxRegex = /\b(?:Firefox|FxiOS)\/(\d+)\b/;
    const safariRegex = /\bVersion\/(\d+)\b.*\bSafari\b/;
    const ieRegex = /\b(?:MSIE |rv:)(\d+)\b/;

    let match;

    match = chromeRegex.exec(userAgent);
    if (match) {
      browser = 'Chrome';
      version = match[1];
    } else {
      match = firefoxRegex.exec(userAgent);
      if (match) {
        browser = 'Firefox';
        version = match[1];
      } else {
        match = safariRegex.exec(userAgent);
        if (match) {
          browser = 'Safari';
          version = match[1];
        } else {
          match = ieRegex.exec(userAgent);
          if (match) {
            browser = 'Internet Explorer';
            version = match[1];
          }
        }
      }
    }
    return { browser, browser_version: version };
  }

  /**
   * Recupera la risoluzione dello schermo dell'utente
   * @returns {string} - La risoluzione dello schermo nel formato 'width x height'
   */
  getScreenResolution(): string {
    return `${window.screen.width}x${window.screen.height}`;
  }

  /**
   * Recupera il livello di zoom del browser dell'utente
   * @returns {number} - Il livello di zoom del browser
   */
  getZoomLevel(): number {
    return window.devicePixelRatio || 1;
  }

  /**
   * Verifica se l'utente preferisce la modalità scura
   * @returns {boolean} - `true` se la modalità scura è preferita, altrimenti `false`
   */
  isDarkMode(): boolean {
    return (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    );
  }

  /**
   * Recupera la lingua preferita del browser dell'utente
   * @returns {string} - La lingua preferita del browser
   */
  getLanguage(): string {
    return navigator.language || 'en';
  }

  /**
   * Recupera il componente associato a un percorso specifico
   * @param {string} routePath - Il percorso della route da cercare
   * @returns {any} - Il componente associato al percorso o `null` se non trovato
   */
  getComponentForRoute(routePath: string): any {
    const routes: Routes = this.router.config;
    const matchingRoute = routes.find((route) => route.path === routePath);

    return matchingRoute?.component || null;
  }

  /**
   * Recupera il nome del componente associato a un percorso specifico
   * @param {string} [routePath] - Il percorso della route da cercare. Se non fornito, usa il percorso corrente.
   * @returns {string | null} - Il nome del componente associato al percorso o `null` se non trovato
   */
  getComponentForRouteStringReturn(routePath?: string): string | null {
    const parts: string[] = routePath?.split('/') || [];
    const routeKey = parts.length > 1 ? parts[1] : parts[0];
    const routes: Routes = this.router.config;
    const matchingRoute = routes.find((route) => route.path === routeKey);

    return matchingRoute?.component?.name || null;
  }

  /**
   * Recupera il valore del widget associato a un percorso specifico
   * @param {string} [routePath] - Il percorso della route da cercare. Se non fornito, usa il percorso corrente.
   * @returns {string | null} - Il valore del widget associato al percorso o `null` se non trovato
   */
  getComponentWidgetMatch(routePath?: string, params?: any): IWidget | null {
    let parts: string[] = routePath ? routePath.split('/') : [];
    parts = parts.filter((p) => p !== '' && p !== 'app' && p !== '5');
    const routeKey = parts.length > 1 ? parts[1] : parts[0];
    let matchingWidget = this.widgets.find(
      (widget) => widget.value === routeKey || widget.name === routeKey
    );

    if (routeKey === 'statistics') {
      matchingWidget = this.getStatisticsTab(params.tab);
    }

    return matchingWidget || null;
  }

  getStatisticsTab(paramsTab?: number) {
    let widgetToReturn = null;
    switch (paramsTab) {
      case 1:
        widgetToReturn = this.widgets.find(
          (w) => w.value === 'production-trend' || w.name === 'production-trend'
        );
        break;
      case 2:
        widgetToReturn = this.widgets.find(
          (w) =>
            w.value === 'historical-weather' || w.name === 'historical-weather'
        );
        break;
      case 3:
        widgetToReturn = this.widgets.find(
          (w) => w.value === 'market-prices' || w.name === 'market-prices'
        );
        break;
      default:
        widgetToReturn = this.widgets.find(
          (w) => w.value === 'statistics' || w.name === 'statistics'
        );
        break;
    }
    return widgetToReturn;
  }
}
