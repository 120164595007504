<div class="widget-line-chart" *ngIf="showWidget">
  <ng-container *ngIf="!isEmpty else noDataLayout">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <div>
        <h4 class="header-widget">Prezzi di mercato</h4>
      </div>
      <div class="select-prov d-flex align-items-center gap-3">
        <nz-select nzPlaceHolder="Seleziona una provincia" [(ngModel)]="selectedField" (ngModelChange)="getData()">
          <nz-option *ngFor="let p of province" [nzLabel]="p.label" [nzValue]="p" class="color">
            {{ p.label }}
          </nz-option>
        </nz-select>
        <span class="cursor-pointer" (click)="explodeWidget($event)" (keydown)="explodeWidget($event)">
          <img src="/assets/img/arrow.svg" alt="">
        </span>
      </div>
    </div>

    <div class="box-line-chart">
      <canvas #baseChars baseChart height="400" [type]="'line'" [data]="lineChartData" [options]="lineChartOptions"
        [legend]="lineChartLegend">
      </canvas>
    </div>
  </ng-container>
  <ng-template #noDataLayout>
    <h1>No data</h1>
  </ng-template>
</div>