import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChartService } from '../../../../../components/charts/chart.service';
import { LoggingService } from 'src/app/components/Logging.service';
import { Router } from '@angular/router';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/components/common.service';

@Component({
  selector: 'app-water-chart',
  templateUrl: './water-chart.component.html',
  styleUrls: ['./water-chart.component.scss']
})
export class WaterChartComponent implements OnInit, OnDestroy {
  selectedField!: string;
  fields: any;
  title: string = 'Acqua';
  data: any
  showWidget: boolean = false;
  placeholder: string = 'Seleziona una provincia';

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _chart: ChartService,
    private readonly _loggingService: LoggingService, 
    private readonly router: Router,
    private readonly _commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getMunicipality();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getMunicipality(): void {
    this._chart.getMunicipality()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(r => {
        if (r.data.length > 0) {
          this.fields = r.data
          this.selectedField = this.fields[0].id
          this.getData()
        }else{
          this.placeholder = 'Nessun dato'
          this.data = [
            { label: 'Oggi', value: 'Nessun dato' },
          ];
          this.showWidget = true;
        }
      });
  }

  getData(): void {
    this._chart.getWaterData(this.selectedField)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(r => {
        this.data = [
          { label: 'Oggi', value: r.data ? r.data + 'Lt' : 'Nessun dato' },
        ];
        this.showWidget = true;
      });
  }

  explodeWidget(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-water-chart'
    }
    lastValueFrom(this._loggingService.logClick(data));
  }

}
