<div class="row">
  <!-- Left Container: Main Widgets -->
  <div class="col-9 d-flex flex-column gap-4">
    <div class="most-used-activities-container">
      <app-most-used-activities [favoriteActivities]="favoriteActivities"></app-most-used-activities>
    </div>

    <div class="card bd-rad bg-color" *ngIf="widget.includes('app-distributor-lots'); else noData">
      <div class="card-body">
        <app-distributor-lots [actor]="6"></app-distributor-lots>
      </div>
    </div>

    <div class="card bd-rad bg-color" *ngIf="widget.includes('app-latestShipments'); else noData">
      <div class="card-body">
        <app-latestShipments [actor]="'distributor_id'"></app-latestShipments>
      </div>
    </div>

    <div class="card bd-rad bg-color" *ngIf="widget.includes('app-widget-arriving-departing-products'); else noData">
      <div class="card-body">
        <app-widget-arriving-departing-products></app-widget-arriving-departing-products>
      </div>
    </div>
  </div>

  <!-- Right Container: Shipping Status and Feedback -->
  <div class="col-3 d-flex flex-column gap-4">
    <div class="card bd-rad bg-color h-100" *ngIf="widget.includes('app-shipping-status'); else noData">
      <div class="card-body">
        <app-shipping-status></app-shipping-status>
      </div>
    </div>

    <div class="card bd-rad bg-feedback h-100" *ngIf="widget.includes('feedback'); else noData">
      <div class="card-body">
        <app-widget-feedback></app-widget-feedback>
      </div>
    </div>

    <div>
      <div class="card h-100 bd-rad bg-quantity pb-0">
        <div class="card-body">
          <app-batch-waste-used></app-batch-waste-used>
        </div>
      </div>
    </div>

  </div>
</div>

<ng-template #noData>
  <div class="card bd-rad bg-no-data h-100">
    <div class="card-body">
      <app-no-data></app-no-data>
    </div>
  </div>
</ng-template>
