<div class="d-flex gap-4">
  <div class="d-flex flex-column gap-4">
    <div class="most-used-activities-container">
      <app-most-used-activities [favoriteActivities]="favoriteActivities"></app-most-used-activities>
    </div>
    <div class="col-md-12 d-flex justify-content-between gap-4">
      <div class="quantity">
        <div class="card bg-quantity bd-rad" *ngIf="widget.includes('app-quantity-rejected'); else noData">
          <div class="card-body mini-widget-h">
            <app-quantity-rejected></app-quantity-rejected>
          </div>
        </div>
      </div>
      <div class="energy">
        <div class="card color-energy bd-rad">
          <div class="card-body bg-energy mini-widget-h" *ngIf="widget.includes('app-consumed-energy'); else noData">
            <app-consumed-energy></app-consumed-energy>
          </div>
        </div>
      </div>
      <div class="water">
        <div class="card color-water bd-rad">
          <div class="card-body bg-water bd-rad mini-widget-h"
            *ngIf="widget.includes('app-widget-water-transformer'); else noData">
            <app-widget-water-transformer></app-widget-water-transformer>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="bd-rad h-100" *ngIf="widget.includes('app-latestShipments'); else noData">
        <app-latestShipments [actor]="'selection_user_id'"></app-latestShipments>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card bd-rad bg-color" *ngIf="widget.includes('app-distributor-lots'); else noData">
        <div class="card-body">
          <app-distributor-lots [actor]="5"></app-distributor-lots>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card bd-rad bg-color h-100" *ngIf="widget.includes('app-widget-products-coming-soon'); else noData">
        <div class="card-body">
          <app-widget-products-coming-soon></app-widget-products-coming-soon>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-column gap-4 cont-2">
    <div class="col-md-12">
      <div class="card bd-rad bg-color h-100" *ngIf="widget.includes('app-widget-in-stock'); else noData">
        <div class="card-body">
          <app-widget-in-stock></app-widget-in-stock>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="card bd-rad bg-feedback h-100" *ngIf="widget.includes('feedback'); else noData">
        <div class="card-body">
          <app-widget-feedback></app-widget-feedback>
        </div>
      </div>
    </div>
    <div>
      <div class="card h-100 bd-rad bg-quantity">
        <div class="card-body pb-0">
          <app-batch-waste-used></app-batch-waste-used>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noData>
  <div class="card bd-rad bg-no-data h-100">
    <div class="card-body">
      <app-no-data></app-no-data>
    </div>
  </div>
</ng-template>
