import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { IWidget } from 'src/app/components/sidebar/Interfaces/IWidget';
import { AdminService } from 'src/app/modules/admin/admin.service';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-container1',
  templateUrl: './container1.component.html',
  styleUrls: ['./container1.component.scss'],
})
export class Container1Component {
  @Input() widgetsFromPredict: [] = [];
  @Input() favoriteActivities: IWidget[] | null = null;

  widget = new Array();
  is_dashboard = true;

  constructor(
    private readonly authService: AuthService,
    private readonly adminService: AdminService,
    private readonly cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.authService.getUser().subscribe((r) => {
      let role_id = localStorage.getItem('role_id');
      this.getAllWidget(role_id);
    });
  }

  getAllWidget(role_id: any) {
    if (this.widgetsFromPredict.length === 0) {
      this.adminService.getAllwidgetRole(role_id).subscribe((r) => {
        r.data.forEach((element: { value: any }) => {
          this.widget.push(element.value);
        });
        this.cdr.detectChanges();
      });
    } else {
      this.widget = Object.values(this.widgetsFromPredict);
      this.cdr.detectChanges();
    }
  }
}
