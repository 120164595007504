import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, finalize, Subject, takeUntil } from 'rxjs';
import { ChartService } from 'src/app/components/charts/chart.service';
import { CommonService } from 'src/app/components/common.service';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {
  @Input() alertTitle: string = 'Alert FMS!';

  innerAlertText: string = '';
  showAlert: boolean = false;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _chartService: ChartService,
    private readonly router: Router,
    private readonly _utils: Utils,
    private readonly _commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getAlert();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getAlert(): void {
    this._chartService.getAlertFms()
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => {
          this.showAlert = true;
        }),
        catchError((error: any) => {
          this.innerAlertText = 'Nessun dato';
          return error;
        })
      )
      .subscribe((result: any) => {
        this.innerAlertText = result?.data ?? 'Il campo 3 ha bisogno di acqua irrigare presto';
      });
  }

  explodeWidget(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this._utils.handleWidgetClick('producer/reports', 3);
    this.router.navigate(['producer/reports']);
  }

}
