<div class="registration-done">
  <div class="row flex-row-reverse">
    <div class="registration-done__box-img col-12 col-lg-6">
      <!-- Template form mobile/tablet -->
      <div class="d-flex d-lg-none justify-content-center align-items-center">
        <ng-container *ngTemplateOutlet="boxInfo"></ng-container>
      </div>
    </div>
    <div class="registration-done__box-info col-12 col-lg-6 pe-0">
      <!-- Template form desktop -->
      <ng-container *ngTemplateOutlet="boxInfo"></ng-container>
    </div>
  </div>
</div>

<!-- begin: Template box info -->
<ng-template #boxInfo>
  <div class="box-info">
    <div class="box-info__img-logo">
      <img src="/assets/icon/logo-citrace-color-1.svg" alt="CiTrace" />
    </div>
    <div class="box-info__other">
      <h2 class="info-title">Registrazione effettuata!</h2>
      <p class="info-description">
        Grazie per esserti registrato su CiTrace. Abbiamo mandato una richiesta di approvazione
        all'amministratore.<br />
        Controlla la tua casella di posta elettronica per aggiornamenti sullo stato del tuo account.
      </p>
      <button type="button" nz-button [nzType]="'primary'" (click)="goToLoginPage($event)"
        (keydown)="goToLoginPage($event)">
        Accedi
      </button>
    </div>
  </div>
</ng-template>
<!-- end: Template box info -->