import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from 'src/app/components/Logging.service';
import { ChartService } from 'src/app/components/charts/chart.service';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-consumed-energy',
  templateUrl: './consumed-energy.component.html',
  styleUrls: ['./consumed-energy.component.scss'],
})
export class ConsumedEnergyComponent implements OnInit {
  selectedField!: string;
  prov: any;
  kvh: any;
  placeHolder: string = 'Seleziona una provincia';

  constructor(
    private _chartService: ChartService,
    private _loggingService: LoggingService,
    private router: Router,
    private _utils: Utils
  ) {}

  ngOnInit() {
    this.getTransformerMunicipality();
  }

  getData() {
    this.getTransformerenergyData();
  }

  getTransformerMunicipality() {
    this._chartService.getTransformerMunicipality().subscribe((r) => {
      if (r.data.length > 0) {
        this.prov = r.data;
        this.selectedField = r.data[0]?.id;
        this.getTransformerenergyData();
      } else {
        this.placeHolder = 'Nessun dato';
      }
    });
  }

  getTransformerenergyData() {
    this._chartService
      .getTransformerenergyData(this.selectedField)
      .subscribe((r) => {
        this.kvh = r.data?.energetic_consumption;
      });
  }

  explodeWidget() {
    this._utils.handleWidgetClick('/app-consumed-energy', 12);
    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-consumed-energy',
    };
    this._loggingService.logClick(data).subscribe((r) => {
      console.log(r);
    });
  }
}
