import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ChartService } from '../charts/chart.service';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit, OnDestroy {
  @Input() municipality_id: number | null = null;
  @Input() municipality_desc: string | null = null;
  @Input() tab: number | null = 0;

  selectedTab: number = 0;
  municipality_id_from_route: any;
  municipality_desc_from_route: any;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _chartService: ChartService,
    private readonly _route: ActivatedRoute
  ) {
    this._route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        this.selectedTab = (Number(+params['tab']) - 1) || 1;
        this.municipality_id_from_route = params['municipality_id'] != null ? +params['municipality_id'] : null;
        this.municipality_desc_from_route = params['municipality_desc'] ?? null;
      });
  }

  ngOnInit(): void {
    this.selectedTab = 0;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
