import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from 'src/app/components/Logging.service';
import { ComponentService } from 'src/app/components/component.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-widget-arriving-departing-products',
  templateUrl: './widget-arriving-departing-products.component.html',
  styleUrls: ['./widget-arriving-departing-products.component.scss'],
})
export class WidgetArrivingDepartingProductsComponent implements OnInit {
  selectedValue = 'true';
  arriving = new BehaviorSubject<string>('Arrivo');
  loaded: boolean = false;
  cols = [
    'Lotto',
    this.arriving.value,
    'Azienda',
    'Prodotto',
    'In magazzino',
    'Quantitá',
  ];
  colsPartenza = [
    'Lotto',
    'Partenza',
    'Prodotto',
    'Quantitá',
    'Destinazione',
    'Mezzo',
  ];
  data!: any;
  role = localStorage.getItem('role') ?? 6;

  constructor(
    private _loggingService: LoggingService,
    private router: Router,
    private _componentService: ComponentService,
    private _utils: Utils
  ) {}

  ngOnInit() {
    this.getData();
  }

  getData() {
    this._componentService
      .lastDistributoreShipping(this.selectedValue)
      .subscribe((r) => {
        this.loaded = false;
        this.data = r.data;
        console.log('TAB LOG', this.data);

        if (this.data[0].arriving) {
          this.cols[1] = 'Arrivo';
        } else {
          this.cols[1] = 'Partenza';
        }
        this.loaded = true;
      });
  }

  explodeWidget() {
    this._utils.handleWidgetClick('widget-arriving-departing-products', 17);
    this.router.navigate(['/warehouse/' + this.role]);
    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-widget-arriving-departing-products',
    };
    lastValueFrom(this._loggingService.logClick(data));
  }
}
