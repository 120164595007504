import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IFilterWeather } from '../interfaces/IFilterWeather';
import { ComponentService } from '../../component.service';
import * as moment from 'moment';
import { Observable, Subject, isObservable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IBatchWeatherData } from '../interfaces/IBatchWeatherData';
import { IDayData } from '../interfaces/IDayData';
import { IWeatherData } from '../interfaces/IWeatherData';
import { IMonthData } from '../interfaces/IMonthData';
import { IBatchOption } from '../interfaces/IBatchOption';
import { IFilterOption } from '../interfaces/IFilterOption';
import { IBatchesMunicipalities } from '../interfaces/IBatchesMunicipalities';
import { IBatchInfo } from '../interfaces/IBatchInfo';
import { CommonService } from '../../common.service';
import { LayoutService } from '../../layout.service';

@Component({
  selector: 'app-historical-weather',
  templateUrl: './historical-weather.component.html',
  styleUrls: ['./historical-weather.component.scss'],
})
export class HistoricalWeatherComponent implements OnInit, OnDestroy {
  @ViewChild('dayCardsContainer') dayCardsContainer!: ElementRef;
  @ViewChild('dayCardsInner') dayCardsInner!: ElementRef;

  @Input() municipalityId: number | null = null;
  @Input() municipalityLabel: string | null = null;

  monthNames = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ];

  batches$: Observable<any>;
  filtersForm: FormGroup;
  weatherForm: FormGroup;
  months: IMonthData[] = [];
  adjacentMonthIndices: number[] = [];
  batch_history?: IBatchWeatherData | null = null;
  activeMonth?: IMonthData | null = null;
  selectedDay?: IDayData | null = null;
  visibleWeatherInstances: IWeatherData[] | undefined = [];
  selectedDayIndex: number = 0;
  selectedMonthIndex: number | null = null;
  conditionTemp: string = 'soleggiato';
  skip: number = 0;
  take: number = 6;
  filtersWeather: IFilterWeather[] | [] = [];
  isToggleCollapsedForMobileFiltering: boolean = true;

  showMonths: boolean = false; // questo booleano una volta che la tabella collegata sia più ricca di dati andrà rimosso
  batch_info: IBatchInfo | null = null;

  isMobileWithTablet$: Observable<boolean>;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly _componentService: ComponentService,
    private readonly _commonService: CommonService,
    private readonly _layoutService: LayoutService
  ) {
    this.isMobileWithTablet$ = this._layoutService.isMobileWithTablet$;
    this.batches$ = this._componentService.batches$;

    let obj = { label: 2024, value: 2024 };
    this.filtersForm = this.fb.group({
      municipality_id: [null, Validators.required],
      year: [obj, Validators.required],
      batch_id: [null, Validators.required],
    });

    this.weatherForm = this.fb.group({
      skip: [null, Validators.required],
      take: [null, Validators.required],
      municipality_id: [null, Validators.required],
      year: [null, Validators.required],
      month: [null, Validators.required],
      day: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.initForm();
    this.initializeBatchOptions();
    this.generateMonths();

    this._componentService.getMyBatches(this.municipalityId ?? 7243);

    this.getBatchHistory(true);

    this.filtersForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getBatchHistory(false);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  generateYearOptions(range: number): IFilterOption[] {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: range }, (_, i) => ({
      label: (currentYear + i).toString(),
      value: currentYear + i,
    }));
  }

  areAllSelectsValid(values: any): boolean {
    return (
      values.year !== null &&
      values.year !== 'year' &&
      values.municipality_id !== null
    );
  }

  getBatchHistory(firstaccess: boolean): void {
    const currentDate = moment();
    const currentDay: string = currentDate.format('DD');
    const currentMonth: string = currentDate.format('MM');
    const currentYear: string = currentDate.format('YYYY');
    const selectedDayDate: string = moment(this.selectedDay?.created_at).format(
      'DD'
    );
    if (this.activeMonth === null) {
      this.activeMonth = this.months.find((m) => m.value === currentMonth);

      const currentMonthIndex = this.months.findIndex(
        (m) => m.value === currentMonth
      );

      this.updateAdjacentMonths(currentMonthIndex);
    }

    let municipalityId = this.filtersForm.get('municipality_id')?.value ?? 7243;
    if (firstaccess) {
      municipalityId = this.municipalityId ?? municipalityId;
    }
    // cerchiamo di mettere un un elemento singolo con validazione e poi si vede
    this.weatherForm.patchValue({
      skip: this.skip,
      take: this.take,
      municipality_id: municipalityId,
      year: this.filtersForm.get('year')?.value ?? currentYear,
      month: this.activeMonth?.value ?? currentMonth,
      day: selectedDayDate ?? currentDay,
    });

    this._componentService
      .getWeather(this.weatherForm.value)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response: IBatchWeatherData) => {
        this.batch_history = response;
        if (this.batch_history) {
          this.setData();
        }
      });
  }

  setData(): void {
    const defaultMonth = this.batch_history?.data.monthly_data;
    if (defaultMonth) {
      this.selectedDay = this.selectedDay ?? defaultMonth[0];
      this.visibleWeatherInstances = this.batch_history?.data?.daily_data;
      this.showMonths = true;

      const currentMonthIndex = this.months.findIndex(
        (m) => m.value === this.activeMonth?.value
      );

      this.updateAdjacentMonths(currentMonthIndex);
    } else {
      this.selectedDay = null;
    }
  }

  selectMonth(month: IMonthData, index: number, event?: any): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event) || this.activeMonth?.value === month.value) {
      return;
    }
    this.selectedMonthIndex = index;
    this.activeMonth = month;
    this.selectedDay = null;
    this.getBatchHistory(false);
  }

  selectDay(day: IDayData, i: number, event?: any): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event) || this.selectedDay?.id === day.id) {
      return;
    }
    this.selectedDay = day;
    this.selectedDayIndex = i;
    this.getBatchHistory(false);
  }

  scrollDays(direction: number, event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    if (direction === -1 && this.skip > 0) {
      this.skip -= this.take;
    } else if (
      direction === 1 &&
      this.skip + this.take < this.batch_history?.total_month
    ) {
      this.skip += this.take;
    }
    this.getBatchHistory(false);
  }

  getIconPath(conditionSummary: string): string {
    const iconBasePath = '../../../../assets/icon/previsioni/';
    const iconMap: { [key: string]: string } = {
      Clear: 'soleggiato.svg',
      Clouds: 'nuvoloso.svg',
      Rain: 'piovoso.svg',
      nebbioso: 'nebbioso.svg',
      Thunderstorm: 'temporalesco.svg',
      possibile_pioggia: 'possibile_pioggia.svg',
      neve: 'neve.svg',
    };
    return iconBasePath + (iconMap[conditionSummary] || 'soleggiato.svg');
  }

  generateMonths(): void {
    for (let i = 0; i < 12; i++) {
      this.months.push({
        label: this.monthNames[i],
        value: moment().month(i).format('MM'),
      });
    }
  }

  updateAdjacentMonths(currentMonthIndex: number): void {
    this.adjacentMonthIndices = [];
    if (currentMonthIndex > 0) {
      this.adjacentMonthIndices.push(currentMonthIndex - 1);
    }
    if (currentMonthIndex < this.months.length - 1) {
      this.adjacentMonthIndices.push(currentMonthIndex + 1);
    }
  }

  isAdjacentMonth(index: number): boolean {
    return this.adjacentMonthIndices.includes(index);
  }

  isObservable(obj: any): obj is Observable<any> {
    return isObservable(obj);
  }

  initializeBatchOptions(): void {
    this.batches$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((batches: IBatchesMunicipalities) => {
        const batchFilter = this.filtersWeather.find(
          (filter) => filter.formControlName === 'batch_id'
        );

        if (batchFilter && batches.data && batches.data.length > 0) {
          batchFilter.options = batches.data.map((batch: IBatchOption) => ({
            label: batch.batch_unique_identifier,
            value: batch.id,
          }));
        }
      });
  }

  getMunicipalities(): any {
    const municipalities: IFilterOption[] = [];

    this._componentService
      .getMunicipality()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response: any) => {
        municipalities.push(
          ...response.data.map((municipality: any) => ({
            label: municipality.label,
            value: municipality.id,
          }))
        );
      });

    return municipalities;
  }

  initForm(): void {
    this.filtersWeather = [
      {
        formControlName: 'year',
        placeholder: '2024',
        options: this.generateYearOptions(3),
      },
      {
        formControlName: 'municipality_id',
        placeholder: this.municipalityLabel ?? 'Comune',
        options: this.getMunicipalities(),
      },
    ];

    this.filtersForm.patchValue({
      municipality_id: this.municipalityId,
      year: 2024,
    });

    this.weatherForm.patchValue({
      municipality_id: {
        label: this.municipalityLabel,
        value: this.municipalityId,
      },
    });
  }

  toggleCollapsedForMobileFiltering(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.isToggleCollapsedForMobileFiltering = !this.isToggleCollapsedForMobileFiltering;
  }
}
