import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { ChartService } from 'src/app/components/charts/chart.service';
import { CommonService } from 'src/app/components/common.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ReportFMSService } from 'src/app/shared/services/report_fms.service';

@Component({
  selector: 'app-report-fms',
  templateUrl: './report-fms.component.html',
  styleUrls: ['./report-fms.component.scss']
})
export class ReportFmsComponent implements OnInit, OnDestroy {
  placeholder: string = 'Seleziona un campo';
  selectedField!: string;
  fields: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  is_dashboard: boolean = false;
  formReport!: FormGroup;
  filter: any;
  filterChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  pageIndex: number = 1;
  pageSize: number = 2;
  total: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  reports: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  currentDate: any;
  defaultDate: any;
  isToggleCollapsedForMobileSearching: boolean = true;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly reportService: ReportFMSService,
    private readonly auth: AuthService,
    private readonly chartService: ChartService,
    private readonly fb: FormBuilder,
    private readonly commonService: CommonService
  ) {
    moment.locale('it');
    const formattedDate = moment().format('DD MMMM YYYY');
    const capitalizedMonth = formattedDate.split(' ')[1].charAt(0).toUpperCase() + formattedDate.split(' ')[1].slice(1);
    const today = new Date();

    this.currentDate = formattedDate.replace(formattedDate.split(' ')[1], capitalizedMonth);
    this.defaultDate = today.toISOString().split('T')[0];
    this.formReport = this.fb.group({
      search: [null],
      note: [null]
    });
  }

  ngOnInit(): void {
    this.getReports();
    this.getMunicipality();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getReports(): void {
    let params = this.filter ? { user_id: this.auth.currentUser.value.id, pageIndex: this.pageIndex, pageSize: this.pageSize, date: this.defaultDate, search: this.filter, municipality_id: this.selectedField } : { user_id: this.auth.currentUser.value.id, pageIndex: this.pageIndex, pageSize: this.pageSize, date: this.defaultDate, municipality_id: this.selectedField };
    this.reportService.getTableReportUser(params)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        this.reports.next(res.data.data);
        this.total.next(res.total);
      });
  }

  handleReloadReports(): void {
    this.getReports();
  }

  onSearch(): void {
    this.getReports();
  }

  getMunicipality(): void {
    this.chartService.getMunicipality()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        if (res.data.length > 0) {
          this.fields.next(res.data);
          this.selectedField = this.fields.value[0].id
          this.getReports()
        }
      });
  };

  handlePageEventNotification(page: number): void {
    this.pageIndex = page;
    this.getReports();
  }

  onDefaultDateChanged(defaultDate: string): void {
    // Aggiungi un giorno alla defaultDate
    const newDate = moment(defaultDate);

    // Assegna la nuova data a defaultDate
    this.defaultDate = newDate.format('YYYY-MM-DD'); // Formatta la data come stringa se necessario

    // Formatta la nuova data
    const formattedDate = newDate.format('DD MMMM YYYY');
    const capitalizedMonth = formattedDate.split(' ')[1].charAt(0).toUpperCase() + formattedDate.split(' ')[1].slice(1);

    // Aggiorna currentDate con la data formattata
    this.currentDate = formattedDate.replace(formattedDate.split(' ')[1], capitalizedMonth);

    // Richiama la funzione per ottenere i report
    this.getReports();
  }

  toggleCollapsedForMobileSearching(event?: Event): void {
    if (!this.commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.isToggleCollapsedForMobileSearching = !this.isToggleCollapsedForMobileSearching;
  }
}
