<div class="my-system-transformer"
  [ngClass]="{'system-tab': roleId == 4 || roleId == 5 || roleId == 6 || type == 'plant'}">
  <div class="system-title" *ngIf="title">{{title}}</div>
  <div class="system-subtitle" *ngIf="subtitle">{{subtitle}}</div>
  <div class="system-form" [class.mt-4]="title || subtitle">
    <form [formGroup]="systemForm">
      <div class="row">
        <div class="form-group col-sm-6">
          <label for="exampleInputEmail1" class="label-form">{{typeNameForm}}</label>
          <input type="text" class="form-control" aria-describedby="name" placeholder="Nome impianto"
            formControlName="systemName">
        </div>
        <div class="form-group col-sm-6">
          <label for="exampleInputEmail1" class="label-form">Indirizzo</label>
          <input type="text" class="form-control" aria-describedby="name" placeholder="Indirizzo"
            formControlName="address">
        </div>
      </div>
    </form>

    <!-- SELECT -->
    <div class="row">
      <div class="form-group col-sm-6">
        <label for="exampleInputEmail1" class="label-form">Provincia</label>
        <nz-select class=" wide-select" nzPlaceHolder="Provincia" nzBorderless [(ngModel)]="provinceSelected"
          (ngModelChange)="getMunicipalityByProvince(provinceSelected.label)">
          <nz-option *ngFor="let item of municipality" [nzValue]="item" [nzLabel]="item.label"></nz-option>
        </nz-select>
      </div>
      <div class="form-group col-sm-6">
        <label for="exampleInputEmail1" class="label-form">Comune</label>
        <nz-select class=" wide-select" nzPlaceHolder="Comune" nzBorderless [(ngModel)]="municipalitySelected">
          <nz-option *ngFor="let prov of province" [nzValue]="prov" [nzLabel]="prov.label"></nz-option>
        </nz-select>
      </div>
    </div>
    <!-- SELECT -->

    <form [formGroup]="systemForm">
      <div class="row">
        <div class="form-group col-sm-6">
          <label for="exampleInputEmail1" class="label-form">CAP</label>
          <input type="text" class="form-control" aria-describedby="name" placeholder="CAP" formControlName="cap">
        </div>
        <div class="form-group col-sm-6">
          <label for="exampleInputEmail1" class="label-form">
            {{roleId != 2 ? 'Capienza magazzino' : 'Quantità coltivata'}}
          </label>
          <input type="text" class="form-control" aria-describedby="name" placeholder="Capienza magazzino"
            formControlName="warehouseCapacity">
        </div>
      </div>
    </form>

    <!-- FORM ESTESO PRODUTTORE -->
    <div *ngIf="roleId == 2">
      <form [formGroup]="systemForm">
        <div class="row">
          <div class="form-group col-sm-6">
            <label for="exampleInputEmail1" class="label-form">Ettari coltivati</label>
            <input type="text" class="form-control" aria-describedby="name" placeholder="Ettari coltivati"
              formControlName="cultivatedHectares">
          </div>
          <div class="form-group col-sm-6">
            <label for="exampleInputEmail1" class="label-form">Km coltivazione</label>
            <input type="text" class="form-control" aria-describedby="name" placeholder="Km coltivazione"
              formControlName="KmOfCrops">
          </div>

          <div class="form-group col-sm-6">
            <label for="exampleInputEmail1" class="label-form">Valore coltivato</label>
            <input type="text" class="form-control" aria-describedby="name" placeholder="Quantità coltivata"
              formControlName="cultivatedValue">
          </div>
          <div class="form-group col-sm-6">
            <label for="exampleInputEmail1" class="label-form">Tipo di suolo</label>
            <input type="text" class="form-control" aria-describedby="name" placeholder="Tipo di suolo"
              formControlName="soilType">
          </div>
        </div>
        <div class="row">
          <div *ngIf="roleId == 2" class="form-group col-sm-6">
            <label for="cultivation_type" class="label-form">Tipo di coltivazione</label>
            <nz-select class=" wide-select" nzPlaceHolder="coltivazione" nzBorderless
              formControlName="cultivation_type">
              <nz-option *ngFor="let prod of productType" [nzValue]="prod.label" [nzLabel]="prod.label"></nz-option>
            </nz-select>
          </div>
        </div>
      </form>

      <div class="default-button d-flex align-items-center">
        <nz-switch [(ngModel)]="switchDefault"></nz-switch>
        <div class="switch-text">
          {{switchDefault ? 'Rimuovi come piantagione di default' : 'Imposta come piantagione di default'}}
        </div>
      </div>
    </div>
    <div *ngIf="roleId != 2" class="buttons-container-form">
      <button nz-button nzType="default" class="back" (click)="resetForm()"
        (keydown)="resetForm($event)">Annulla</button>
      <button nz-button nzType="primary" class="modal-button save" (click)="saveSystem($event)"
        (keydown)="saveSystem($event)">Salva</button>
    </div>
    <div *ngIf="roleId == 2" class="buttons-container-form">
      <button nz-button nzType="default" class="back" (click)="resetForm($event)" (keydown)="resetForm($event)">
        {{plantId ? 'Elimina' : 'Annulla'}}
      </button>
      <button nz-button nzType="primary" class="modal-button save" (click)="saveSystem($event)"
        (keydown)="saveSystem($event)">Salva</button>
    </div>
  </div>
</div>



<!-- MODALE -->
<nz-modal [nzClosable]="false" [nzClassName]="'modal-delete-container'" [nzTitle]="'Elimina piantagione'"
  [nzOkText]="'Elimina'" [nzOkLoading]="isLoadingDeletePlant" [(nzVisible)]="isVisible" (nzOnCancel)="handleCancel()"
  (nzOnOk)="handleConfirmDelete()">
  <ng-container *nzModalContent>
    <p class="delete-modal-text">Sei sicuro di voler eliminare questa piantagione?</p>
  </ng-container>
</nz-modal>