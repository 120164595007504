<div class="water-chart water-container">
  <div class="d-flex justify-content-between align-items-center flex-wrap">
    <div>
      <h4 class="header-widget">{{ title }}</h4>
    </div>
    <div class="select-field">
      <nz-select [nzPlaceHolder]="placeholder" [(ngModel)]="selectedField" (ngModelChange)="getData()">
        <nz-option *ngFor="let field of fields" [nzLabel]="field.label" [nzValue]="field.id" class="color">
          {{field}}</nz-option>
      </nz-select>
    </div>
  </div>

  <div class="d-flex justify-content-start justify-content-lg-end">
    <div *ngFor="let item of data">
      <div class="label">{{ item.label }}</div>
      <div class="data" [ngClass]="{'no-data': item.value === 'Nessun dato'}">
        {{ item.value }}
      </div>
    </div>
  </div>
</div>
