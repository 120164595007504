import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { ComponentService } from '../component.service';
import Swal from 'sweetalert2';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit, OnDestroy {
  role_id: string | null = localStorage.getItem('role_id');
  form!: FormGroup;
  feedbackForm!: FormGroup;
  isVisible = false;
  operator: any;
  lotto: any;
  rateValue!: 0;
  operatorsToSelect: any;
  operatorsToSelectModal: any;
  isDisabled = true;
  showFeedbackList: boolean = false;
  count: any;
  mod: boolean = false;
  create: boolean = false;
  loadLottiSelect: boolean = true;
  lottiOnModal: any;
  dataFeedback = {
    is_receiver: true,
    lotto_id: null,
    operator_role_name: null,
    search: null,
    skip: 1,
    take: 5,
  };
  userLotti: any;
  filter: any;
  pageIndex: number = 1;
  pageSize: number = 2;
  total: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  inputValue?: string;
  options: string[] = [];
  overallRating: any;
  feedbackList: any;
  operators: any = [];
  isToggleCollapsedForMobileFiltering: boolean = true;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly _componentService: ComponentService,
    private readonly _commonService: CommonService
  ) {
    this.form = this.fb.group({
      type: new FormControl(true),
      lotto: new FormControl(null),
      actor: new FormControl(null),
      search: new FormControl(null),
    });

    this.feedbackForm = this.fb.group({
      id: null,
      operator: new FormControl(null, Validators.required),
      lotto: new FormControl(null, Validators.required),
      vote: new FormControl(null, Validators.required),
      title: new FormControl(null, Validators.required),
      description: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    this.getAllFeedback();
    this.getUserBatch();
    this.handleRoles();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getAllFeedback(): void {
    this._componentService.getAllFeedback(this.dataFeedback)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.feedbackList = r.data.feedbacks;
        this.count = r.total;

        if (this.feedbackList.length > 0) {
          this.overallRating = r.data.ratingAverage;
          this.operatorsToSelect = r.data.allRoles;
          this.operators = [];
          r.data.allRoles.forEach((element: any) => {
            switch (element) {
              case 'produttore':
                this.operators.push({
                  img: '../../../assets/icon/prod.svg',
                  name: 'Produttore',
                });
                break;

              case 'consumatore':
                this.operators.push({
                  img: '../../../assets/icon/consumatore.svg',
                  name: 'Consumatore',
                });
                break;

              case 'venditore':
                this.operators.push({
                  img: '../../../assets/icon/venditore.svg',
                  name: 'Venditore',
                });
                break;

              case 'selezione e stoccaggio':
                this.operators.push({
                  img: '../../../assets/icon/trasformatore.svg',
                  name: 'Selezione e stoccaggio',
                });
                break;

              case 'distributore':
                this.operators.push({
                  img: '../../../assets/icon/distributore.svg',
                  name: 'Distributore',
                });
                break;
              default:
                break;
            }
          });
          this.showFeedbackList = true;
        } else {
          this.showFeedbackList = false;
          this.feedbackList = 'Nessun dato';
        }
      });
  }

  getUserBatch(): void {
    this._componentService
      .getUserBatch({ is_receiver: this.dataFeedback.is_receiver })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.userLotti = r.data;
      });
  }

  handlePageEventNotification(page: number): void {
    this.dataFeedback.skip = page;

    this.pageIndex = page;
    this.getAllFeedback();
  }

  submitForm(): void {
    this.dataFeedback.is_receiver = this.form.value.type;
    this.dataFeedback.lotto_id = this.form.value.lotto;
    this.dataFeedback.operator_role_name = this.form.value.actor;
    this.dataFeedback.search = this.form.value.search;
    this.getAllFeedback();
    this.getUserBatch();
  }

  addFeedback(): void {
    this.mod = false;
    this.create = true;
    this.isDisabled = true;

    this.handleRoles();

    this.isVisible = true;
  }

  handleOk(): void {
    if (this.feedbackForm.valid) {
      let data = {
        id: this.feedbackForm.value.id,
        receiving_user_role_name: this.feedbackForm.value.operator,
        feedback: this.feedbackForm.value.description,
        title: this.feedbackForm.value.title,
        rating: this.feedbackForm.value.vote,
        batch_id: Number(this.feedbackForm.value.lotto),
      };

      if (this.mod) {
        this._componentService.updateFeedback(data)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((r) => {
            this.mod = false;
            this.getAllFeedback();
            Swal.fire({
              title: 'Feedback aggiornato',
              text: 'Feedback aggiornato con successo',
              html: '<img src="assets/icon/success-icon.svg" alt="Custom Icon">',
              showConfirmButton: false,
              timer: 2000,
            });
            this.getUserBatch();
          });
      } else if (this.create) {
        this._componentService.createNewFeedback(data)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((r) => {
            this.getAllFeedback();
            if (r.message == 'Feedback già presente') {
              const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.onmouseenter = Swal.stopTimer;
                  toast.onmouseleave = Swal.resumeTimer;
                },
              });

              Toast.fire({
                icon: 'error',
                title: 'Feedback già presente',
              });
            } else {
              Swal.fire({
                title: 'Feedback aggiunto',
                text: 'Feedback salvato con successo',
                html: '<img src="assets/icon/success-icon.svg" alt="Custom Icon">',
                showConfirmButton: false,
                timer: 2000,
              });
              this.create = false;
              this.getUserBatch();
            }
          });
      }

      this.isVisible = false;
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });

      Toast.fire({
        icon: 'error',
        title: 'Campi mancanti',
        text: 'Compila tutti i campi',
      });
    }
  }

  handleCancel(): void {
    this.mod = false;
    this.create = false;
    this.isVisible = false;
  }

  activeSelect(): void {
    this.loadLottiSelect = false;

    this._componentService
      .getBatchByRoleInTimeLine(this.feedbackForm.value.operator)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((r) => {
          this.loadLottiSelect = true;
          this.lottiOnModal = r.data;

          this.isDisabled = false;
        });
  }

  handleRoles(): void {
    this._componentService.handleRoles()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.operatorsToSelectModal = r.data;
      });
  }

  updateFeedback(feedback: any): void {
    this.mod = true;
    if (!this.form.value.type) {
      this.handleRoles();
      this.feedbackForm = this.fb.group({
        id: feedback.id,
        operator: new FormControl(feedback.role, Validators.required),
        lotto: new FormControl(
          feedback.batch.batch_unique_identifier,
          Validators.required
        ),
        vote: new FormControl(feedback.rating, Validators.required),
        title: new FormControl(feedback.title, Validators.required),
        description: new FormControl(feedback.feedback, Validators.required),
      });
      this.activeSelect();
      this.isVisible = true;
    }
  }

  toggleCollapsedForMobileFiltering(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.isToggleCollapsedForMobileFiltering = !this.isToggleCollapsedForMobileFiltering;
  }
}
