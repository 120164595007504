<div *ngIf="show; else noData" class="container">
  <div class="title">
    <h4 class="header-widget">Il più venduto</h4>
  </div>
  <div class="d-flex align-items-center pb-u">
    <div class="img">
      <img src="../../../../../../assets/img/best-sale.svg" alt="Medaglia vendite">
    </div>
    <div>
      <div class="d-flex flex-column">
        <span class="lotto">Lotto {{data.batch_unique_identifier}}</span>
        <div class="pb-z">
          <span class="data-mini-widget">{{sold}}</span> kg
        </div>
        <div class="feedback" (click)="goToFeedback()" onkeypress="">Lascia feedback</div>
      </div>
    </div>
  </div>
</div>

<ng-template #noData>
  <div class="container">
    <div class="title">
      <h4 class="header-widget">Nessun dato disponibile</h4>
    </div>
    <div class="d-flex align-items-center pb-u">
      <div class="img">
        <img src="../../../../../../assets/img/best-sale.svg" alt="Medaglia vendite">
      </div>
      <div class="d-flex flex-column">
        <span class="no-data-message">Non ci sono dati da visualizzare.</span>
      </div>
    </div>
  </div>
</ng-template>
