import { Component, OnInit } from '@angular/core';
import { ChartData, ChartOptions, Plugin } from 'chart.js';
import { ChartService } from '../../../../../components/charts/chart.service';
import { LoggingService } from 'src/app/components/Logging.service';
import { Router } from '@angular/router';
import { ComponentService } from 'src/app/components/component.service';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-widget-in-stock',
  templateUrl: './widget-in-stock.component.html',
  styleUrls: ['./widget-in-stock.component.scss']
})
export class WidgetInStockComponent implements OnInit {

  text: any = '100%';
  legendData: any;
  showWidget = false;
  noData!: string;
  selectedField!: string;
  prov: any;

  list: any;

  public doughnutChartData: ChartData<'doughnut'> = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: ['#EF7F00', '#FAC36A', '#E3E6E8'],
        hoverOffset: 4,
        // borderDash: [5],
        borderWidth: 1,
        // offset: 10
        // borderWidth: '20px',
        // circumference: 260
      }
    ]
  };
  public doughnutChartOptions: ChartOptions<'doughnut'> = {
    responsive: true,
    cutout: '60%',
    radius: '80%',

    maintainAspectRatio: false,
    aspectRatio: 1,
    plugins: {
      legend: {
        position: 'bottom',
        display: true,
        labels: {
          font: {
            size: 14,
            weight: '700'
          },
          boxWidth: 12,
          useBorderRadius: true,
          borderRadius: 6
        },
      }
    }
  };


  public doughnutChartPlugins: Plugin[] = [{
    id: 'customPlugin',
    beforeDraw: (chart) => {
      const width = chart.width,
        height = chart.height,
        ctx = chart.ctx;

      ctx.restore();
      const fontSize = Math.min(height, width) * 0.07;
      ctx.font = `600 ${fontSize}px Roboto`;
      ctx.textBaseline = 'middle';

      const text = this.text,
        textWidth = ctx.measureText(text).width,
        textX = (width - textWidth) / 2,
        textY = height / 2.3;

      ctx.fillText(text, textX, textY);

      ctx.save();
    }
  }];

  constructor(
    private _chartService: ChartService,
    private _loggingService: LoggingService,
    private _componentService: ComponentService,
    private router: Router,
    private _utils: Utils
  ) { }

  ngOnInit() {
    this.getTransformerinventoryStockData();
  }

  getData() {

  }


  getTransformerinventoryStockData() {
    this.showWidget = false;
    const currentYear = new Date().getFullYear().toString(); // Anno in formato 2024
    const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0'); // Mese in formato 09
    this._componentService.getInfoInStock(currentYear, currentMonth).subscribe(r => {
      // Assicurati che ci siano dati
      if (r.data.chart.labels.length > 0) {
        this.doughnutChartData.labels = r.data.chart.labels;
        this.doughnutChartData.datasets[0].data = r.data.chart.datasets[0].data;
        this.doughnutChartData.datasets[0].backgroundColor = r.data.chart.datasets[0].backgroundColor;

        let quantity = r.data.percentage[2].quantity;
        let numeroFormattato = (100 - parseFloat(quantity.toString())).toFixed(1);

        if (Number(numeroFormattato) > 100) {
          numeroFormattato = '100';
        }

        this.text = numeroFormattato + '/100%';

        // Adattamento della lista dai dati di "data"
        this.list = r.data.data.map((item: any) => ({
          lotto: item.batch || "Nessun dato",
          img: item.img || "Nessun dato",
          kg: item.quantity || 0,
          days: item.giacency || 0,
        }));

        this.showWidget = true;
      } else {
        this.resetWidgetData();
      }
    },
      (error: any) => {
        this.resetWidgetData();
      });
  }

  resetWidgetData() {
    this.doughnutChartData.datasets[0].data = [0];
    this.doughnutChartData.labels = ['Nessun dato'];
    this.text = 'Nessun dato';

    this.list = [
      {
        lotto: "Nessun dato",
        img: "Nessun dato",
        kg: 0,
        days: 0,
        cutout: '70%' // Se questo campo non esiste, puoi rimuoverlo o gestirlo diversamente
      },
    ];

    this.showWidget = true;
  }



  explodeWidget() {
    this._utils.handleWidgetClick('/app/warehouse/5', 38);

    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-widget-in-stock'
    }
    this._loggingService.logClick(data).subscribe(r => {
      console.log(r);
    })
    this.router.navigate(['/app/warehouse/5'])
  }

}
