import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { LoggingService } from 'src/app/components/Logging.service';
import { ChartService } from 'src/app/components/charts/chart.service';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-widget-products-coming-soon',
  templateUrl: './widget-products-coming-soon.component.html',
  styleUrls: ['./widget-products-coming-soon.component.scss'],
})
export class WidgetProductsComingSoonComponent implements OnInit {
  selectedField!: string;
  prov: any;
  showWidget!: boolean;
  noData!: string;
  label!: ['Lotto', 'Data', 'Produttore', 'Prodotto', 'Tipologia', 'Quantitá'];
  list: any;
  role = localStorage.getItem('role') ?? 5;

  constructor(
    private _chartService: ChartService,
    private _loggingService: LoggingService,
    private router: Router,
    private _utils: Utils
  ) {}

  ngOnInit() {
    this.getTransformerproductsComingSoonData();
  }

  getTransformerproductsComingSoonData() {
    this._chartService.getTransformerproductsComingSoonData().subscribe((r) => {
      if (r.data.length > 0) {
        this.list = r.data;
        this.showWidget = true;
      } else {
        this.list = [
          {
            lotto: 'Nessun dato',
            data: 'Nessun dato',
            productor: 'Nessun dato',
            img: 'Nessun dato',
            type: 'Nessun dato',
            quantity: 0,
          },
        ];
        this.showWidget = true;
      }
    });
  }

  explodeWidget() {
    this._utils.handleWidgetClick('app-widget-products-coming-soon', 12);
    this.router.navigate(['warehouse/' + this.role]);
    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-widget-products-coming-soon',
    };
    lastValueFrom(this._loggingService.logClick(data));
  }
}
