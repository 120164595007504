import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ComponentService } from 'src/app/components/component.service';

@Component({
  selector: 'app-bestSellingProduct',
  templateUrl: './bestSellingProduct.component.html',
  styleUrls: ['./bestSellingProduct.component.scss']
})
export class BestSellingProductComponent implements OnInit {

  private clickSubscription!: Subscription;

  data: any;
  show: boolean = false;
  sold: any;

  constructor(
    private _componentService: ComponentService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.clickSubscription = this._componentService.clickEvent$.subscribe(() => {
      this.getData();
    });
    this.getData();
  }

  getData() {
    this._componentService.getMostSold().subscribe(r => {
      console.log("ELEMENTO INTERESSANTE");
      console.log(r.data);
      this.data = r.data.batch;
      this.sold = r.data.sold
      this.show = true

      if (!this.data || !this.sold) {
        this.show = false
      }
    })
  }

  goToFeedback() {
    this.router.navigate(['/feedback']);
  }

}
