import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import { ChartService } from '../../../../../components/charts/chart.service';
import { LoggingService } from 'src/app/components/Logging.service';
import { Router } from '@angular/router';
import { Utils } from 'src/app/shared/helpers/utils';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/components/common.service';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit, OnDestroy {
  selectedYear!: string;
  showWidget = false;
  isEmpty!: boolean;
  years = ['2021', '2022', '2023'];

  public barChartData: ChartData<'bar'> = {
    labels: [],
    datasets: [
      {
        label: 'Arance',
        data: [],
        backgroundColor: 'rgba(236, 160, 37, 1)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
        borderRadius: 25,
      },
      {
        label: 'Limoni',
        data: [],
        backgroundColor: 'rgba(255, 216, 156, 0.8)',
        borderColor: 'rgba(255, 216, 156, 0.8)',
        borderWidth: 1,
        borderRadius: 25,
      },
    ],
  };
  public barChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  public barChartType: any = 'bar';
  public barChartLegend = true;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _chartService: ChartService,
    private readonly _loggingService: LoggingService,
    private readonly router: Router,
    private readonly _utils: Utils,
    private readonly _commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getData(): void {
    this.showWidget = false;
    let data;
    if (this.selectedYear) {
      data = this.selectedYear;
    } else {
      data = null;
    }
    this._chartService.getproductionData(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.isEmpty = r.data.isEmpty;
        if (!this.isEmpty) {
          this.barChartData.labels = r.data.widgetData.labels;
          this.barChartData.datasets[0].data = r.data.widgetData.datasets.data[0];
          this.barChartData.datasets[1].data = r.data.widgetData.datasets.data[1];
          this.showWidget = true;
        } else {
          this.barChartData.labels = ['Nessun dato'];
          this.barChartData.datasets[0].data = [0];
          this.barChartData.datasets[1].data = [0];
          this.showWidget = true;
        }
      });
  }

  explodeWidget(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    const params = {
      tab: 3,
      year: this.selectedYear,
    };
    this._utils.handleWidgetClick('app/statistics', 8, params);
    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-bar-chart',
    };
    lastValueFrom(this._loggingService.logClick(data))
      .then((response: any) => {
        this.router.navigate(['app/statistics'], {
          queryParams: params,
        });
      });
  }
}
