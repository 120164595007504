<div class="lot-history-timeline">
  <div class="row">
    <div class="col-12 header d-flex justify-content-between align-items-center">
      <div class="header__title">Storico lotti</div>

      <!-- begin: Filter by lot desktop -->
      <div class="header__box-filter">
        <ng-container *ngTemplateOutlet="filterByLot"></ng-container>
      </div>
      <!-- end: Filter by lot desktop -->
    </div>
    <div class="col-12 sub-header">
      <div class="sub-header__box-sub-header">
        <a href="javascript:void(0)" class="back-cta" (click)="goTo($event)" (keydown)="goTo($event)">
          <i class="icon-custom arrow-left-green"></i>
          <span>Tutti i lotti</span>
        </a>
      </div>

      <!-- begin: Filter by lot mobile/tablet -->
      <div class="sub-header__box-filter d-flex d-lg-none justify-content-between align-items-center">
        <ng-container *ngTemplateOutlet="filterByLot"></ng-container>
      </div>
      <!-- end: Filter by lot mobile/tablet -->

      <!-- begin: Roles tabset -->
      <div class="sub-header__roles-tab">
        <!-- 
        Viene utilizzato un tabset per avere una serie di pulsanti simili a delle "schede" ma senza alcun
        contenuto e funzionanti anche in mobile.
        -->
        <nz-tabset>
          <nz-tab [nzTitle]="role.role" (nzClick)="selectProductType(role.id)" *ngFor="let role of roles">
          </nz-tab>
        </nz-tabset>
      </div>
      <!-- end: Roles tabset -->
    </div>

    <div class="col-12 timeline-content">
      <ng-container *ngFor="let item of data">
        <div class="activities-container gap-4" *ngIf="item.activities">
          <ng-container *ngFor="let act of item.activities">
            <div class="d-flex align-items-center activities">
              <div class="circle" [ngStyle]="{'background-color': act.color}"></div>
              <div class="act">{{act.activity}}</div>
            </div>
          </ng-container>
        </div>

        <div class="lotto-container d-flex justify-content-between align-items-center">
          <div class="lotto-cont">
            Lotto <span class="lotto">{{item.batch}}</span>
          </div>
          <div class="d-flex align-items-center">
            <div class="lotto-type-icon">
              <img [src]="item.icon" alt="">
            </div>
            <div class="lotto">{{item.type}}</div>
          </div>
        </div>

        <div class="row mx-0">
          <div class="grid-container" *ngIf="item?.dateActions?.length; else noDataTemplate">
            <ng-container *ngFor="let day of item.dateActions; let i = index">
              <div class="row mx-0 px-0">
                <div class="date col-5 col-sm-3 col-md-2">
                  {{day.date}}
                </div>
              </div>
              <div class="row mx-0" *ngFor="let action of day.actions">
                <div class="row mx-0 px-0 flex-nowrap" *ngIf="i === 0 && item.delivered">
                  <div class="col-1"></div>
                  <div class="col-2 border-left d-flex flex-column align-items-center justify-content-center"></div>
                  <div class="delivered label col-9 col-lg-6 px-0">
                    Consegnato presso {{item.delivered}} {{ item?.quantity_received }} kg e scartati {{ item?.waste }}
                    kg
                  </div>
                </div>

                <div class="row mx-0 px-0 flex-nowrap">
                  <div class="col-1"></div>
                  <div class="col-2 border-left d-flex flex-column align-items-center justify-content-center">
                    <div class="line-circle-container d-flex align-items-center">
                      <div class="circle-line"></div>
                      <div class="line"></div>
                    </div>
                    <div class="line-circle-final d-flex align-items-center"
                      *ngIf="i === (item.dateActions.length - 1) && !item.shipped">
                      <div class="circle-line"></div>
                    </div>
                  </div>
                  <div class="col-9 col-lg-6 px-0 step-container"
                    [class.mt-0]="(i === 0 && item.delivered) && action.notes">
                    <div class="step-container__box">
                      <div class="plant-container margin-steps">
                        <div class="action-title" [ngStyle]="{'background-color': action.actionColor}">
                          {{action.action}}
                        </div>
                        <div class="label" *ngIf="action.establishment">Campo: {{action.establishment}}</div>
                      </div>

                      <div class="criterials-container" *ngIf="action.selectionCriteria">
                        <div class="selectionCriteria">Criteri di selezione</div>
                        <ul class="action-content margin-steps" *ngFor="let criterials of action.selectionCriteria">
                          <li>
                            <span class="label">{{criterials.label}}: </span>
                            <span class="value">{{criterials.value}}</span>
                          </li>
                        </ul>
                      </div>

                      <div class="action-content margin-steps"
                        *ngIf="action.price !== null && action.price !== undefined && action.price !== ''">
                        <span class="label">Costo: </span>
                        <span class="value">€ {{action.price}}</span>
                      </div>

                      <div class="action-content margin-steps" *ngFor="let step of action.steps">
                        <ng-container *ngIf="step.label !== 'user_id' ">
                          <span class="label">{{getTranslation(step.label)}}: </span>
                          <span class="value">{{step.value}}</span>
                        </ng-container>
                      </div>
                    </div>

                    <!-- begin: Box notes mobile/tablet -->
                    <div class="step-container__notes" *ngIf="action.notes">
                      <div class="d-flex flex-column gap-1">
                        <div class="d-flex align-items-center">
                          <img src="../../../assets/icon/note-icone.svg" alt="Note" />
                          <div class="note">Note</div>
                        </div>
                        <div class="note-text">{{action.notes}}</div>
                      </div>
                    </div>
                    <!-- end: Box notes mobile/tablet -->
                  </div>

                  <!-- begin: Box notes desktop -->
                  <div class="col-3 d-none d-lg-flex align-items-center" *ngIf="action.notes">
                    <div class="d-flex flex-column gap-2">
                      <div class="d-flex align-items-center">
                        <img src="../../../assets/icon/note-icone.svg" alt="Note" />
                        <div class="note">Note</div>
                      </div>
                      <div class="note-text">{{action.notes}}</div>
                    </div>
                  </div>
                  <!-- end: Box notes desktop -->
                </div>

                <div class="row mx-0 px-0 flex-nowrap" *ngIf="i === (item.dateActions.length - 1) && item.shipped">
                  <div class="col-1"></div>
                  <div class="col-2 border-left d-flex flex-column align-items-center justify-content-center"></div>
                  <div class="delivered label col-9 col-lg-6 px-0">
                    Spedito presso {{item?.shipped}} {{ item?.quantity ? item?.quantity : item?.quantity_sent }} kg
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <ng-template #noDataTemplate>
            <h4>Nessun dato presente</h4>
          </ng-template>
        </div>
      </ng-container>
    </div>

    <ng-template #filterByLot>
      <div class="select-container d-flex justify-content-between align-items-center">
        <span class="filters">Filtra per lotto:</span>
        <div class="d-flex justify-content-between align-items-center">
          <nz-form-item class="mb-0">
            <nz-select nzPlaceHolder="Lotto" nzBorderless class="wide-select" [(ngModel)]="batch"
              (ngModelChange)="getMyHistoryBatchTimeline(batch)">
              <nz-option *ngFor="let batch of batches" [nzValue]="batch.batch_id"
                [nzLabel]="batch.batch_id"></nz-option>
            </nz-select>
          </nz-form-item>
        </div>
      </div>
    </ng-template>
  </div>
</div>