import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChartService } from '../../../../../components/charts/chart.service';
import { LoggingService } from 'src/app/components/Logging.service';
import { Router } from '@angular/router';
import { lastValueFrom, Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/components/common.service';

@Component({
  selector: 'app-sun-chart',
  templateUrl: './sun-chart.component.html',
  styleUrls: ['./sun-chart.component.scss']
})
export class SunChartComponent implements OnInit, OnDestroy {
  selectedField!: string;
  fields: any;
  title: string = 'Suolo';
  data: any
  placeholder: string = 'Seleziona una provincia';

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _chart: ChartService,
    private readonly _loggingService: LoggingService, 
    private readonly router: Router,
    private readonly _commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getMunicipality();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getMunicipality(): void {
    this._chart.getMunicipality()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(r => {
        if (r.data.length > 0) {
          this.fields = r.data
          this.selectedField = this.fields[0].id
          this.getData()
        }else{
          this.placeholder = 'Nessun dato'
          this.data = [
            { label: 'Umidità', value: 'Nessun dato' },
            { label: 'Temperatura', value: 'Nessun dato' }
          ];
        }
      });
  }

  getData(): void {
    this._chart.getSunData(this.selectedField)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(r => {
        this.data = [
          { label: 'Umidità', value: r.data ? r.data.humidity_percentage + '%' : 'Nessun dato' },
          { label: 'Temperatura', value: r.data ? r.data.temperature + '°' : 'Nessun dato' }
        ];
      });
  }

  explodeWidget(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-sun-chart'
    }
    lastValueFrom(this._loggingService.logClick(data));
  }

}
