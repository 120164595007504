import { Component, OnDestroy, OnInit } from '@angular/core';
import { IActivityRow } from '../../venditore/interfaces/IActivityRow';
import { IPlanteOption } from '../../venditore/interfaces/IPlanteOption';
import { ComponentService } from 'src/app/components/component.service';
import Swal from 'sweetalert2';
import { CommonService } from 'src/app/components/common.service';
import { finalize, Subject, takeUntil } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { toast } from 'src/app/app.component';

@Component({
  selector: 'app-treatment-prices-handler',
  templateUrl: './treatment-prices-handler.component.html',
  styleUrls: ['./treatment-prices-handler.component.scss'],
})
export class TreatmentPriceHandlerComponent implements OnInit, OnDestroy {
  formActivity!: FormGroup;

  isModalVisible: boolean = false;
  isModalOkLoading: boolean = false;
  modalType: 'add' | 'edit' | null = null;
  selectedRow: IActivityRow | null = null;
  plantOptions: IPlanteOption[] = [];
  activityData: IActivityRow[] = [];
  pageIndex: number = 1;
  take: number = 10;
  totElementsNum: number = 0;
  role_id = localStorage.getItem('role_id');
  batchOptions: any;
  activityOptions: [] | string[] = [];
  selectedProductName: string | null = null;
  dropDownOptions: { label: string; value: number }[] = [];
  transportationOptions = [
    'Vagone refrigerato',
    'Furgone',
    'Moto ape',
    'Camion refrigerato',
  ];

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _componentService: ComponentService,
    private readonly _commonService: CommonService
  ) { }

  ngOnInit(): void {
    switch (this.role_id) {
      case '2': {
        this.getActivitiesForProducer();
        this.getMyPlantes();
        break;
      }
      case '6': {
        this.getActivitiesForDistributor();
        this.getAllBatchesByUser();
        break;
      }
      default: {
        this.getActivitiesForTransformer();
        this.getAllBatchesByUser();
        break;
      }
    }
    this.getActivitiesByUserRole();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openModal(type: 'add' | 'edit', row: IActivityRow | null = null, event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.modalType = type;
    this.selectedRow = row;

    // inizializzo il form della modale
    this.initFormActivity((type === 'edit') ? this.selectedRow : null);

    // apro la modale
    this.isModalVisible = true;
  }

  handleModalCancel(): void {
    this.isModalVisible = false;
    this.modalType = null;
    this.selectedRow = null;
    this.selectedProductName = null;
    this.formActivity.reset();
  }

  handleModalOk(): void {
    if (this.formActivity.invalid) {
      toast.fire({
        title: 'Campi mancanti',
        text: 'Compila i relativi campi obbligatori',
        icon: 'warning'
      });
      return;
    }
    const formData: any = this.formActivity.value;

    if (this.modalType === 'add') {
      this.addActivityPrice(formData);
    } else if (this.modalType === 'edit' && this.selectedRow?.id) {
      this.updateActivityPrice(formData, this.selectedRow.id);
    }

    this.handleModalCancel();
  }

  getMyPlantes(): void {
    this._componentService.getMyPlante()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        this.plantOptions = response.data;
      });
  }

  getActivitiesByUserRole(): void {
    switch (this.role_id) {
      case '2': {
        this._componentService
          .getActivitiesForProducer(this.pageIndex - 1, this.take)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((response) => {
            if (response.code === 200) {
              this.activityData = response.data;
              this.totElementsNum = response.total;
            }
          });
        break;
      }
      default: {
        this._componentService
          .getActivitiesForNonProducer(this.pageIndex - 1, this.take)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((response) => {
            if (response.code === 200) {
              this.activityData = response.data;
              this.totElementsNum = response.total;
            }
          });
        break;
      }
    }
  }

  onPageChange(newPageIndex: number): void {
    this.pageIndex = newPageIndex;
    this.getActivitiesByUserRole();
  }

  getAllBatchesByUser(): void {
    this._componentService.getAllBatchesByUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (response.code === 200) {
          this.batchOptions = response.data;
        }
      });
  }

  getActivitiesForProducer(): void {
    this._componentService.getActivitiesByProductId()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => {
          this.activityOptions = response.data;
        },
        error: (error) => {
          console.error('Error fetching activity options:', error);
        },
      });
  }

  getActivitiesForTransformer(): void {
    this._componentService.getAllActivitiesNonProducer()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => {
          this.activityOptions = response.data;
        },
        error: (error) => {
          console.error('Error fetching activity options:', error);
        },
      });
  }

  getActivitiesForDistributor(): void {
    this.activityOptions = this.transportationOptions;
  }

  getProductByPlanteId(plante_id: number): void {
    this._componentService.getProductByPlanteId(plante_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => this.handleProductResponse(response),
        error: (error) =>
          console.error('Error fetching product by plant ID:', error),
      });
  }

  getProductByBatchId(batch_id: number): void {
    this._componentService.getAllProductByBatchId(batch_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => this.handleProductResponse(response),
        error: (error) =>
          console.error('Error fetching product by batch ID:', error),
      });
  }

  private addActivityPrice(data: any): void {
    this.isModalOkLoading = true;
    this._componentService.postActivityPrice(data)
      .pipe(takeUntil(this.unsubscribe$), finalize(() => this.isModalOkLoading = false))
      .subscribe(() => {
        Swal.fire({
          title: 'Prezzo aggiunto',
          text: 'Operazione completata',
          icon: 'success',
          timer: 600,
          showConfirmButton: false,
        });
        this.getActivitiesByUserRole();
      });
  }

  private updateActivityPrice(data: any, activityId: number): void {
    this.isModalOkLoading = true;
    this._componentService
      .updateActivityPrice(data, activityId)
      .pipe(takeUntil(this.unsubscribe$), finalize(() => this.isModalOkLoading = false))
      .subscribe(() => {
        Swal.fire({
          title: 'Prezzo modificato',
          text: 'Operazione completata',
          icon: 'success',
          timer: 600,
          showConfirmButton: false,
        });
        this.getActivitiesByUserRole();
      });
  }

  private initFormActivity(selectedData?: any): void {
    // inizializzo il form
    this.formActivity = this._fb.group({
      plante_id: [selectedData?.plante_id ?? null],
      plante_label: [selectedData?.plante_label ?? null],
      batch_id: [selectedData?.batch_id ?? null],
      batch_label: [null],
      product_id: [selectedData?.product_id ?? null, Validators.required],
      product_label: [selectedData?.product_label ?? null],
      activity: [selectedData?.activity ?? null, Validators.required],
      price: [selectedData?.price ?? null, Validators.required],
      visibility: [selectedData?.visibility ?? null, Validators.required],
    });

    // imposto il nome del prodotto nella rispettiva proprietà agganciata all'input dedicato (se ho passato il "selectedData" altrimenti rimane null)
    this.selectedProductName = selectedData?.product_label ?? null;

    /*
    In base al ruolo, definisco alcune operazioni su alcuni campi del form
    */
    if (this.role_id === '2') {
      this.dropDownOptions = this.plantOptions.map((option) => ({
        label: option.name,
        value: option.id,
      }));
      this.formActivity.get('plante_id')?.setValidators([Validators.required]);
      this.formActivity.get('batch_id')?.clearValidators();
    } else {
      this.dropDownOptions = this.batchOptions?.map((option: any) => ({
        label: option.label,
        value: option.value,
      }));
      this.formActivity.get('batch_id')?.setValidators([Validators.required]);
      this.formActivity.get('plante_id')?.clearValidators();
    }
    this.formActivity.get('plante_id')?.updateValueAndValidity();
    this.formActivity.get('batch_id')?.updateValueAndValidity();

    /*
    Inizializzo degli eventi al changes in alcune select-option
    */
    this.formActivity.get('plante_id')
      ?.valueChanges
      ?.pipe(takeUntil(this.unsubscribe$))
      ?.subscribe((plante_id) => {
        if (plante_id) {
          this.getProductByPlanteId(plante_id);
        }
      });
    this.formActivity.get('batch_id')
      ?.valueChanges
      ?.pipe(takeUntil(this.unsubscribe$))
      ?.subscribe((batch_id) => {
      if (batch_id) {
        this.getProductByBatchId(batch_id);
      }
    });
  }

  private clearProductSelection(): void {
    this.formActivity.patchValue({
      product_id: null,
    });
    this.selectedProductName = null;
  }

  private handleProductResponse(response: any): void {
    if (response.code === 200) {
      const firstProduct = response.data;
      this.formActivity.patchValue({
        product_label: null,
        product_id: null,
      });

      this.formActivity.patchValue({
        product_label: firstProduct.label,
        product_id: firstProduct.id,
      });
      this.selectedProductName = firstProduct.name;
    } else {
      this.clearProductSelection();
    }
  }
}
