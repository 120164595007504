import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChartService } from '../charts/chart.service';
import { ChartData, ChartOptions } from 'chart.js';
import { ComponentService } from '../component.service';
import { Subject, takeUntil } from 'rxjs';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-production-rend',
  templateUrl: './production-rend.component.html',
  styleUrls: ['./production-rend.component.scss'],
})
export class ProductionRendComponent implements OnInit, OnDestroy {
  selectedField: any = null;
  selectedYear: number | null = null;
  showWidget: boolean = false;
  isEmpty: boolean = false;
  productionTrendCards: any;
  tableData: any;
  productsListForMobile: any;
  type: number = 0;
  lastThreeYears: number[] = [];
  selectedRowIndex: number | null = null;
  fields: any;
  isToggleCollapsedForMobileFiltering: boolean = true;

  productsType = [
    {
      id: 0,
      label: 'Tutti',
      selected: true,
    },
    {
      id: 1,
      path: '../../../assets/icon/modal-orange-icon.svg',
      label: 'Arance',
      selected: false,
    },
    {
      id: 2,
      path: '../../../assets/icon/modal-lemon-icon.svg',
      label: 'Limoni',
      selected: false,
    },
  ];
  cols = [
    'Prodotto',
    'Totale annuo',
    'Media mensile',
    'Numero lotti',
    'Picchi di produzione',
  ];

  public barChartData: ChartData<'bar'> = {
    labels: [],
    datasets: [
      {
        label: 'Arance',
        data: [],
        backgroundColor: 'rgba(236, 160, 37, 1)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
        borderRadius: 25,
      },
      {
        label: 'Limoni',
        data: [],
        backgroundColor: 'rgba(255, 216, 156, 0.8)',
        borderColor: 'rgba(255, 216, 156, 0.8)',
        borderWidth: 1,
        borderRadius: 25,
      },
    ],
  };
  public barChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: 'top',
        labels: {
          usePointStyle: true,
        },
      },
    },
  };
  public barChartType: any = 'bar';
  public barChartLegend = true;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _chartService: ChartService,
    private readonly _componentService: ComponentService,
    private readonly _commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.getYears();
    this.getMyPlante();
    this.trendProduction();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getMyPlante(): void {
    this._componentService.getMyPlante()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.fields = r.data;
        this.selectedField = this.fields[0];
        this.getProductionTrendData();
      });
  }

  getYears(): void {
    const currentYear = new Date().getFullYear();
    this.lastThreeYears = [currentYear, currentYear - 1, currentYear - 2];
    this.selectedYear = this.lastThreeYears[0];
  }

  filterForYear(): void {
    this.getProductionTrendData();
    this.trendProduction();
  }

  trendProduction(): void {
    this.showWidget = false;
    this._componentService
      .trendProduction(this.selectedYear, this.type, this.selectedField.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.tableData = r.data;
        this.showWidget = true;
      });
  }

  getProductionTrendData(): void {
    this.showWidget = false;

    this._chartService
      .getproductionDataStatistics(this.selectedYear, this.selectedField.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.isEmpty = r.data.isEmpty;
        if (!this.isEmpty) {
          this.barChartData.labels = r.data.widgetData.labels;
          this.barChartData.datasets[0].data =
            r.data.widgetData.datasets.data[0];
          this.barChartData.datasets[1].data =
            r.data.widgetData.datasets.data[1];
          this.productionTrendCards = r.data.cardData;
          this.showWidget = true;
        } else {
          this.barChartData.labels = ['Nessun dato'];
          this.barChartData.datasets[0].data = [0];
          this.barChartData.datasets[1].data = [0];
          this.productionTrendCards = r.data.cardData;
          this.showWidget = true;
        }
        this.trendProduction();
      });
  }

  selectProductType(id: number, event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.productsType.forEach((p) => (p.selected = false));
    const product = this.productsType.find((p) => p.id === id);
    if (product) {
      product.selected = true;
      this.type = product.id;
      this.trendProduction();
    }
  }

  toggleRow(index: number, event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.selectedRowIndex = this.selectedRowIndex === index ? null : index;
  }

  toggleCollapsedForMobileFiltering(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.isToggleCollapsedForMobileFiltering = !this.isToggleCollapsedForMobileFiltering;
  }
}
