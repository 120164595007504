<div class="prod-trend-container">
  <div class="field">
    <div class="box-header">
      <div class="box-header__title">
        Andamento Produzione
      </div>
      <div class="box-header__filter-cta">
        <a href="javascript:void(0)" (click)="toggleCollapsedForMobileFiltering($event)"
          (keydown)="toggleCollapsedForMobileFiltering($event)">
          Filtra
          <i class="icon-custom settings-orange mt-2"></i>
        </a>
      </div>
    </div>
    <div class="box-mobile-filter" [class.box-mobile-filter--open]="!isToggleCollapsedForMobileFiltering">
      <div class="filter-title">Filtra</div>
      <div class="box-filter-input">
        <nz-select class="w-100" nzPlaceHolder="Campo" [(ngModel)]="selectedField" nzBorderless
          (ngModelChange)="getProductionTrendData()">
          <nz-option [nzValue]="null" nzLabel="tutti"></nz-option>
          <nz-option *ngFor="let field of fields" [nzValue]="field" [nzLabel]="field.name"></nz-option>
        </nz-select>
        <nz-select class="w-100" nzPlaceHolder="Anno" [(ngModel)]="selectedYear" (ngModelChange)="filterForYear()">
          <nz-option *ngFor="let year of lastThreeYears" [nzLabel]="year" [nzValue]="year"></nz-option>
        </nz-select>
      </div>
    </div>
    <div class="content-tab">
      <div class="d-none d-lg-flex justify-content-between align-items-center mb-4">
        <div class="title-tab">Andamento Produzione</div>
        <div class="d-flex tab-select size-sel">
          <nz-select nzPlaceHolder="Campo" [(ngModel)]="selectedField" nzBorderless class="wide-select"
            (ngModelChange)="getProductionTrendData()">
            <nz-option [nzValue]="null" nzLabel="tutti"></nz-option>
            <nz-option *ngFor="let field of fields" [nzValue]="field" [nzLabel]="field.name"></nz-option>
          </nz-select>
          <nz-select nzPlaceHolder="Anno" [(ngModel)]="selectedYear" class="custom-select placeholder-color"
            (ngModelChange)="filterForYear()">
            <nz-option *ngFor="let year of lastThreeYears" [nzLabel]="year" [nzValue]="year" class="color"></nz-option>
          </nz-select>
        </div>
      </div>

      <div class="header-barr total d-flex justify-content-between justify-content-lg-start align-items-center"
        *ngIf="showWidget">
        <div class="box d-flex align-items-center" *ngFor="let type of productionTrendCards">
          <div class="box-img">
            <img [src]="type.image" alt="" />
          </div>
          <div class="box-data">
            <div class="box-label">Totale {{type?.type}}<span class="d-none d-lg-inline">&nbsp;prodotte</span></div>
            <div class="box-value">{{type?.total}} kg</div>
          </div>
        </div>
      </div>

      <div class="barr-chart-container" *ngIf="showWidget">
        <canvas id="barChart" baseChart height="400" [data]="barChartData" [options]="barChartOptions"
          [type]="barChartType" [legend]="barChartLegend">
        </canvas>
      </div>

      <div class="accordion-tab-container">
        <div class="selectType">
          <div class="filters d-flex align-items-center">
            <div class="d-flex flex-row align-items-center filter"
              [ngClass]="{'selected': item.selected, 'not-selected': !item.selected}"
              (click)="selectProductType(item.id, $event)" (keydown)="selectProductType(item.id, $event)"
              *ngFor="let item of productsType">
              <div *ngIf="item.path" class="img">
                <img [src]="item.path" alt="">
              </div>
              <div class="label">{{item.label}}</div>
            </div>
          </div>
        </div>

        <div class="table-cont" *ngIf="showWidget">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="font-title" *ngFor="let col of cols">{{col}}</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let item of tableData; let i = index">
                <tr>
                  <td class="text-style-big padd">
                    <div class="d-flex align-items-center">
                      <div class="icon-tab">
                        <img [src]="item.icon" alt="">
                      </div>
                      <div class="tab-item">{{item.type}}</div>
                    </div>
                  </td>
                  <td>
                    <div class="tab-item">{{ item?.yearTotal }} kg</div>
                  </td>
                  <td>
                    <div class="tab-item">{{ item?.monthlyAverage }} kg</div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <a href="javascript:void(0)" class="cta-number-tab-link"
                        [class.cta-number-tab-link--normal-text]="!item?.batchsNumber" (click)="toggleRow(i, $event)"
                        (keydown)="toggleRow(i, $event)">
                        {{item.batchsNumber}}
                        <div class="expand-tab" *ngIf="item?.batchsNumber">
                          <img src="../../../assets/icon/arrow-down-icon.svg" alt="" />
                        </div>
                      </a>
                    </div>

                  </td>
                  <td class="d-flex align-items-center">
                    <div *ngFor="let tred of item.productionPeaks" class="d-flex">
                      <div *ngIf="tred.trend === 'up'">
                        <img src="../../../assets/icon/trend-up.svg" alt="">
                      </div>
                      <div *ngIf="tred.trend === 'down'">
                        <img src="../../../assets/icon/trend-down.svg" alt="">
                      </div>
                      <div class="tab-item">{{ tred.month }}</div>
                    </div>
                  </td>

                </tr>
                <ng-container *ngIf="selectedRowIndex === i">
                  <tr *ngFor="let details of item.details; let i = index">
                    <td class="">
                      <div class="icon-tab detail-item">
                        {{details.batch}}
                      </div>
                    </td>
                    <td class="tab-item">{{details.totYear}}</td>
                    <td class="tab-item">{{details.totMonth}}</td>
                    <td class="tab-item"></td>
                    <td class="tab-item"></td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </div>

      </div>
    </div>

    <!-- begin: Mobile/Tablet prod list -->
    <div class="list-prod-mobile" *ngIf="tableData?.length">
      <div class="list-prod-mobile__box-select-type">
        <div class="d-flex flex-row align-items-center gap-2"
          [ngClass]="{'selected': item.selected, 'not-selected': !item.selected}"
          (click)="selectProductType(item.id, $event)" (keydown)="selectProductType(item.id, $event)"
          *ngFor="let item of productsType">
          <img [src]="item.path" alt="" *ngIf="item.path" />
          <div class="label">{{item.label}}</div>
        </div>
      </div>
      <div class="list-prod-mobile__item-list" *ngFor="let data of tableData; let i = index">
        <div class="header-item">
          <p class="description">Prodotto</p>
          <p class="title">{{data.type}}</p>
        </div>
        <div class="content-item">
          <div class="content-item__row">
            <div>
              <div class="row-title">Totale annuo</div>
              <div class="row-value">{{data.yearTotal}} kg</div>
            </div>
          </div>
          <div class="content-item__row">
            <div>
              <div class="row-title">Media mensile</div>
              <div class="row-value">{{data.monthlyAverage}} kg</div>
            </div>
          </div>
          <div class="content-item__row" *ngIf="data.productionPeaks?.length">
            <div>
              <div class="row-title">Picchi di produzione</div>
              <div class="row-value row-value--multi-value">
                <div class="d-flex flex-row" *ngFor="let tred of data.productionPeaks">
                  <div *ngIf="tred.trend === 'up' || tred.trend === 'down'">
                    <img src="../../../assets/icon/trend-up.svg" alt="" *ngIf="tred.trend === 'up'" />
                    <img src="../../../assets/icon/trend-down.svg" alt="" *ngIf="tred.trend === 'down'" />
                  </div>
                  <div class="text-capitalize">{{ tred.month }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-item__row">
            <div>
              <div class="row-title">Numero lotti</div>
              <div class="row-value">
                <a href="javascript:void(0)" class="cta-link" [class.cta-link--normal-text]="!data.batchsNumber"
                  (click)="toggleRow(i, $event)" (keydown)="toggleRow(i, $event)">
                  {{data.batchsNumber}}
                  <div class="expand-tab" *ngIf="data.batchsNumber">
                    <img src="../../../assets/icon/arrow-down-icon.svg" alt="" />
                  </div>
                </a>
              </div>
            </div>
            <div class="box-sub-list" *ngIf="selectedRowIndex === i && data.batchsNumber && data.details?.length">
              <div class="box-sub-list__item-sub-list" *ngFor="let details of data.details; let i = index">
                <div class="sub-item-row">
                  <div class="row-title-sub">
                    Lotto
                  </div>
                  <div class="row-value-sub">
                    {{details.batch}}
                  </div>
                </div>
                <div class="sub-item-row">
                  <div class="row-title-sub">
                    Totale annuo
                  </div>
                  <div class="row-value-sub">
                    {{details.totYear}} kg
                  </div>
                </div>
                <div class="sub-item-row">
                  <div class="row-title-sub">
                    Media mensile
                  </div>
                  <div class="row-value-sub">
                    {{details.totMonth}} kg
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end: Mobile/Tablet prod list -->
  </div>

</div>