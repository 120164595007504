<section class="most-used-activities">
  <div class="title-area">
    <p>Attività più usate</p>
  </div>

  <div class="container-cards">
    <ng-container *ngIf="!(isMobileWithTablet$ | async) else mobileLayout">
      <!-- Desktop layout -->
      <div class="card-activity" (click)="goTo(activity, $event)" (keydown)="goTo(activity, $event)" *ngFor="let activity of ((favoriteActivities!=null && local === true) ? favoriteActivities : favoriteActivitiesAlt)">
        <img class="icon-activity" [src]="activity.icon" [alt]="activity.name">
        <span>{{ activity.name }}</span>
      </div>
    </ng-container>
    <ng-template #mobileLayout>
      <!-- Mobile/Tablet layout -->
      <div class="card-activity-wrapper-mobile">
        <i class="icon-custom arrow-left-ios" (click)="carouselPre(carousel, $event)" (keydown)="carouselPre(carousel, $event)"></i>
        <nz-carousel #carousel class="card-activity-carousel" [nzDots]="false" [nzLoop]="true">
          <div class="card-activity-carousel__box-content" nz-carousel-content (click)="goTo(activity, $event)" (keydown)="goTo(activity, $event)" *ngFor="let activity of ((favoriteActivities!=null && local === true) ? favoriteActivities : favoriteActivitiesAlt)">
            <img class="icon-activity" [src]="activity.icon" [alt]="activity.name">
            <span>{{ activity.name }}</span>
          </div>
        </nz-carousel>
        <i class="icon-custom arrow-right-ios" (click)="carouselNext(carousel, $event)" (keydown)="carouselNext(carousel, $event)"></i>
      </div>
    </ng-template>
  </div>
</section>