<div class="row">
  <div class="col-9 d-flex flex-column gap-4">
    <div class="most-used-activities-container gap-4">
      <app-most-used-activities [favoriteActivities]="favoriteActivities">
      </app-most-used-activities>
    </div>
    <div class="d-flex gap-4">
      <div class="col-4 card bd-rad bg-color" *ngIf="widget.includes('app-SalesStatus'); else noData">
        <div class="bg-sale-status border-mini-widget">
          <div class="bg-img-sale-status widget-min-h">
            <app-SalesStatus></app-SalesStatus>
          </div>
        </div>
      </div>
      <div class="d-flex col-8 gap-4">
        <div class="col-5 card bd-rad bg-color" *ngIf="widget.includes('app-SalesStatus'); else noData">
          <div class="bg-discard-day border-mini-widget widget-min-h">
            <app-DiscardOfTheDay></app-DiscardOfTheDay>
          </div>
        </div>
        <div class="col-6 card bd-rad bg-color" *ngIf="widget.includes('app-SalesStatus'); else noData">
          <div class="bg-wite border-mini-widget widget-min-h">
            <app-bestSellingProduct></app-bestSellingProduct>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex gap-4">
      <div class="bg-color col-4" *ngIf="widget.includes('app-ProductsForSale'); else noData">
        <div class="bg-wite border-mini-widget big-widget-h">
          <div>
            <app-ProductsForSale></app-ProductsForSale>
          </div>
        </div>
      </div>
      <div style="max-width: 35.425rem;" class="col-8 card bd-rad bg-color gap-4"
        *ngIf="widget.includes('app-SalesTimes'); else noData">
        <div class="bg-wite border-mini-widget big-widget-h">
          <app-SalesTimes></app-SalesTimes>
        </div>
      </div>
    </div>
    <div>
      <div class="card bd-rad bg-color" *ngIf="widget.includes('app-sellerUpcomingProducts'); else noData">
        <div class="bg-wite border-mini-widget">
          <app-sellerUpcomingProducts></app-sellerUpcomingProducts>
        </div>
      </div>
    </div>
    <div>
      <div class="card bd-rad bg-color" *ngIf="widget.includes('app-latestShipments'); else noData">
        <app-latestShipments [actor]="'venditore_id'"></app-latestShipments>
      </div>
    </div>
  </div>

  <div class="col-3 d-flex flex-column gap-4 ">
    <div class="card bd-rad bg-color" *ngIf="widget.includes('app-SalesStatus'); else noData">
      <div class="bg-add-day-report border-mini-widget widget-min-h bg-img-add-day-report">
        <app-addReport></app-addReport>
      </div>
    </div>
    <div class="card bd-rad bg-color" *ngIf="widget.includes('app-SellerInStock'); else noData">
      <div class="bg-wite border-mini-widget big-widget-h">
        <app-SellerInStock></app-SellerInStock>
      </div>
    </div>
    <div class="card bd-rad bg-quantity">
      <div class="card-body pb-0">
        <app-batch-waste-used></app-batch-waste-used>
      </div>
    </div>
    <div class="card bd-rad bg-feedback" *ngIf="widget.includes('feedback'); else noData">
      <div class="card-body">
        <app-widget-feedback></app-widget-feedback>
      </div>
    </div>
  </div>

</div>

<ng-template #noData>
  <div class="card bd-rad bg-no-data h-100 mb-1">
    <div class="card-body">
      <app-no-data></app-no-data>
    </div>
  </div>
</ng-template>
