<div class="my-plantations">
  <div *ngIf="goBack && currentTemplate === 'plants'" class="go-back d-flex" (click)="goBackFn($event)"
    (keydown)="goBackFn($event)">
    <div class="arrow">
      <img src="../../../assets/icon/arrow-left-green.svg" alt="" />
    </div>
    <div class="back-text">Indietro</div>
  </div>
  <div *ngIf="currentTemplate === 'plants'" class="my-plants-tab">
    <div class="d-flex justify-content-between align-items-center">
      <div [hidden]="showFormPlants && (isMobileWithTablet$ | async)">
        <div class="plants-title">Le mie piantagioni</div>
        <div class="plants-subtitle" *ngIf="placeOfPlantStr">{{placeOfPlantStr}}</div>
      </div>
      <button type="button" class="add-plant" nz-button nzType="primary" (click)="goTOAddPlant($event)"
        (keydown)="goTOAddPlant($event)" *ngIf="!goBack">
        <span class="d-none d-lg-inline">Aggiungi piantagione</span>
        <span class="d-inline d-lg-none"><span class="pe-2">+</span>Aggiungi</span>
      </button>
    </div>
    <div class="plant-cards" [hidden]="showFormPlants && (isMobileWithTablet$ | async)">
      <div *ngFor="let plant of plants" (click)="showFormMyPlants(plant, $event)"
        (keydown)="showFormMyPlants(plant, $event)" [ngClass]="{'selected-card': plant.selected}">
        <div class="card-plant" [ngClass]="{'selected-card-width': plant.selected, 'card-width': !plant.selected}">
          <div class="default" *ngIf="plant.default">
            Default
          </div>
          <div class="plant-municipality" *ngIf="plant.municipality_name || plant.province_code">
            {{plant.municipality_name}}<span *ngIf="plant.province_code">, {{plant.province_code}}</span>
          </div>
          <div class="plant-name" *ngIf="plant.name">
            {{ plant.name }}
          </div>
        </div>
      </div>
    </div>
    <!-- MODIFICA PLANT -->
    <div id="editForm" *ngIf="showFormPlants">
      <app-mySystemTransformer [roleId]="2" [plantId]="plantId"
        [title]="(isMobileWithTablet$ | async) ? 'Il mio impianto' : ''"
        [subtitle]="(isMobileWithTablet$ | async) ? placeOfPlantStr : ''" [type]="'mod-plant'"
        (variabileOutput)="onOutputChild($event)"></app-mySystemTransformer>
    </div>
  </div>

  <!-- AGGIUNGI PLANT -->
  <div *ngIf="currentTemplate === 'add-plant-form'">
    <app-mySystemTransformer [roleId]="2" [type]="'plant'" [title]="'Aggiungi piantagione'"
      (variabileOutput)="onOutputChild($event)"></app-mySystemTransformer>
  </div>
</div>