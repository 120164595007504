<div class="feedback-consumer-tab">
  <div>
    <h6>Prodotti più venduti</h6>
  </div>
  <div class="product-list mb-5">
    <div *ngFor="let product of productsList" class="product-feedback-card" (click)="selectCard(product)"
      [ngClass]="{'selected': isSelected(product)}" onkeypress="">
      <img [src]="getIcon(product.transformation_name)" class="product-feedback-card-img"
        [alt]="getIcon(product.transformation_name)">
      <p class="product-feedback-card-title"> {{ product.product_name }} </p>
      <p class="product-feedback-card-subtitle"> {{ product.producer }} </p>
    </div>
  </div>

  <div *ngIf="selectedProduct">
    <div class="card-list-header">
      <div class="row align-items-center d-flex">
        <div class=" col-2 d-flex flex-column">
          <span class="card-list-header-name">{{ selectedProduct.product_name }}</span>
          <span class="card-list-header-producer">{{ selectedProduct.producer }}</span>
        </div>
        <div [formGroup]="feedbackForm" class=" col-10 d-flex align-items-center justify-content-end">
          <div class="select-gender">
            <nz-select class="select-gender" nzPlaceHolder="Gender" nzBorderless formControlName="gender"
              (ngModelChange)="getFeedbackOnProduct()">
              <nz-option nzValue="F" nzLabel="F"></nz-option>
              <nz-option nzValue="M" nzLabel="M"></nz-option>
              <nz-option nzValue="" nzLabel="Tutti"></nz-option>
            </nz-select>
          </div>
          <div class=" d-flex" style="gap: 1rem;">
            <nz-select nzAllowClear  class="age-interval" nzPlaceHolder="Da" formControlName="startAge"
              (ngModelChange)="updateAgeRange()">
              <nz-option *ngFor="let age of startAgeOptions" [nzValue]="age" [nzLabel]="age"></nz-option>
            </nz-select>
            <nz-select nzAllowClear  class="age-interval" nzPlaceHolder="A" formControlName="endAge"
              (ngModelChange)="updateAgeRange()">
              <nz-option *ngFor="let age of endAgeOptions" [nzValue]="age" [nzLabel]="age"></nz-option>
            </nz-select>
          </div>
        </div>
      </div>
    </div>

    <div *ngFor="let item of consumerFeedbackList">
      <div class="feedback-item">
        <div class="feedback-header d-flex p-2 justify-content-lg-start ">
          <img class="me-3" src="../../../../../assets/icon/consumer-user-feedback.svg" alt="consumatore-generico">
          <span class="feedback-age-gender">Età: {{ item?.age }} Gender: {{ item.gender }}</span>
        </div>
        <div style="padding-left: 1rem;">
          <div class="feedback-title">
            <nz-rate class="me-3" [ngModel]="item.rating" nzAllowHalf nzDisabled="true"></nz-rate>
            <span class="feedback-title">{{ item.title }}</span>
          </div>
          <div class="feedback-content">
            <p class="feedback-date">
              Feedback lasciato il {{ item.created_at | date:'yyyy MMMM d' }}
            </p>
            <p class="feedback-description">{{ item.feedback }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
