import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartData, ChartOptions } from 'chart.js';
import { lastValueFrom } from 'rxjs';
import { LoggingService } from 'src/app/components/Logging.service';
import { ComponentService } from 'src/app/components/component.service';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-SalesTimes',
  templateUrl: './SalesTimes.component.html',
  styleUrls: ['./SalesTimes.component.scss']
})
export class SalesTimesComponent implements OnInit {

  selectType!: any;
  average: any;
  showWidget: boolean = false;

  types = [
    {type: 'Arance', value: 1},
    {type: 'Limoni', value: 2}
  ];

  public barChartData: ChartData<'bar'> = {
    labels: [],
    datasets: [
      {
        label: '',
        data: [],
        backgroundColor: 'rgba(236, 160, 37, 1)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
        borderRadius: 25,
        barThickness: 20
      }
    ]
  };
  public barChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    // label: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          callback: function(value) {
            return value + ' g';
          }
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };

  public barChartType: any = 'bar';
  public barChartLegend = true;

  constructor(
    private router: Router,
    private _loggingService: LoggingService,
    private _componentService: ComponentService,
    private _utils: Utils
  ) { }

  ngOnInit() {
    this.selectType = this.types[0].value;
    this.getData();
  }

  getData() {
    this.showWidget = false;
    this._componentService.getVenditaTime(this.selectType).subscribe(r => {
      // console.log('tempi-di-vendita', r);
      this.barChartData.labels = r.data.widgetData.labels;
      this.barChartData.datasets[0].backgroundColor = r.data.widgetData.datasets.backgroundColor;
      this.barChartData.datasets[0].borderColor = r.data.widgetData.datasets.borderColor;
      this.barChartData.datasets[0].data = r.data.widgetData.datasets.data[0];
      this.average = r.data.average;
      this.showWidget = true;

    })

  }

  explodeWidget() {
    this._utils.handleWidgetClick(null, 8);

    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-bar-chart'
    }
    lastValueFrom(this._loggingService.logClick(data));
  }

}
