import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzButtonSize } from 'ng-zorro-antd/button';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { CommonService } from 'src/app/components/common.service';
import { ReportFMSService } from 'src/app/shared/services/report_fms.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-activity-type',
  templateUrl: './activity-type.component.html',
  styleUrls: ['./activity-type.component.scss']
})
export class ActivityTypeComponent implements OnDestroy, OnChanges {
  @Input() report?: any;

  @Output() reloadReports: EventEmitter<void> = new EventEmitter<void>();

  content: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  content2: any;
  size: NzButtonSize = 'small';
  formReport!: FormGroup;
  category = 1;
  data$!: Observable<any>;
  isVisibleActivityModal: boolean = false;

  citrus_type = [
    { icon: "/assets/icon/mini-orange.svg", category: 1 },
    { icon: "/assets/icon/mini-lemon.svg", category: 2 }
  ];
  activity_list = [
    { type: 'Lotto', data: '003456' },
    { type: 'Coltivazione', data: 'Arancia Tarocco' },
    { type: 'Causale', data: 'Malattia fungina' },
    { type: 'Prodotto', data: 'Fungicida ABC' },
    { type: 'Campo', data: 'Principale' },
    { type: 'Quantità', data: '10 litri' },
    { type: 'Superficie', data: '0.8' },
  ];

  private activityUnits: { [key: string]: string } = {
    'Trattamento fungicida': '(L)',
    'Irrigazione a goccia': '(L/h)',
    'Monitoraggio delle malattie delle piante': '(Piante)',
    'Preparazione del terreno': 'Ettari (ha)',
    'Trattamento insetticida': 'Litri (L)',
    'Concimazione del terreno': '(kg)',
  };
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly reportService: ReportFMSService,
    private readonly commonService: CommonService
  ) {
    this.formReport = this.fb.group({
      note: [null]
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['report']) {
      this.content.next(this.report);
    }
  }

  getIconPath(category: number): string {
    const citrus = this.citrus_type.find(type => type.category == category);
    return citrus ? citrus.icon : '';
  }

  openModal(data: any, event?: Event): void {
    if (!this.commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    this.isVisibleActivityModal = true;
    this.content2 = data;
    if (this.content2?.note) {
      this.formReport.patchValue({ note: this.content2.note });
    }
  }

  closeModal(): void {
    this.isVisibleActivityModal = false;
    this.content2 = undefined;
  }

  insertNote(id: any): void {
    const noteValue = this.formReport.value.note?.trim();
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });

    if (!noteValue) {
      Toast.fire({
        icon: "error",
        title: "Campo nota vuoto"
      });
      return;
    }

    this.reportService.updateReport(id, { note: noteValue })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        Toast.fire({
          icon: "success",
          title: "Nota inserita correttamente"
        });
        this.reloadReports.emit();
      });

    this.closeModal();
  }

  removeNote(id: any): void {
    this.reportService.removeNote(id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        });
        Toast.fire({
          icon: "success",
          title: "Nota rimossa"
        });
        this.reloadReports.emit();
      });
  }

  getUnitOfMeasure(activity: string): string {
    return this.activityUnits[activity] || 'Unità sconosciuta';
  }
}
