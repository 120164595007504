import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComponentService } from '../component.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-batch-waste-used',
  templateUrl: './batch-waste-used.component.html',
  styleUrls: ['./batch-waste-used.component.scss'],
})
export class BatchWasteUsedComponent implements OnInit {
  batchOptions: any;
  batchForm: FormGroup;
  item: any;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private _componentService: ComponentService,
    private _fb: FormBuilder
  ) {
    this.batchForm = this._fb.group({
      batch_id: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    console.log('here');

    this.getMyBatches();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getMyBatches() {
    this._componentService
      .getUserBatchesTimeline()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (response.data) {
          this.batchOptions = response.data;
          this.batchForm.patchValue({ batch_id: this.batchOptions[0] });
          this.getBatchesWeightOnTimeline();
        }
      });
  }

  getBatchesWeightOnTimeline() {
    this._componentService
      .getBatchesWeightOnTimeline(this.batchForm.value.batch_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (response.data) {
          this.item = response.data;
        }
      });
  }

  totalWeightManipulation(item: any) {
    return item?.total_quantity != null
      ? `${item?.total_quantity}kg`
      : 'Nessun prodotto';
  }

  wasteWeightManipulation(item: any) {
    return item?.waste != null ? `${item?.waste}kg` : 'Nessuno scarto';
  }

  isStaticValue(value: any): boolean {
    return value == null;
  }
}
