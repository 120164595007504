import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from 'src/app/components/Logging.service';
import { ChartService } from 'src/app/components/charts/chart.service';
import { Utils } from 'src/app/shared/helpers/utils';

@Component({
  selector: 'app-quantity-rejected',
  templateUrl: './quantity-rejected.component.html',
  styleUrls: ['./quantity-rejected.component.scss'],
})
export class QuantityRejectedComponent implements OnInit {
  selectedField!: string;
  prov: any;
  day: any;
  week: any;
  month: any;
  placeHolder: string = 'Seleziona una provincia';

  constructor(
    private _chartService: ChartService,
    private _loggingService: LoggingService,
    private router: Router,
    private _utils: Utils
  ) {}

  ngOnInit() {
    this.getTransformerMunicipality();
  }

  getData() {
    this.getTransformerQuantityData();
  }

  getTransformerMunicipality() {
    this._chartService.getTransformerMunicipality().subscribe((r) => {
      if (r.data.length > 0) {
        this.prov = r.data;
        this.selectedField = r.data[0]?.id;
        this.getTransformerQuantityData();
      } else {
        this.placeHolder = 'Nessun dato';
      }
    });
  }

  getTransformerQuantityData() {
    this._chartService
      .getTransformerQuantityData(this.selectedField)
      .subscribe((r) => {
        this.day = r.data.day;
        this.week = r.data.week;
        this.month = r.data.month;
      });
  }

  explodeWidget() {
    this._utils.handleWidgetClick('/app-quantity-rejected', 11);

    const currentUrl = this.router.url;
    const data = {
      url: currentUrl,
      clickOn: 'app-quantity-rejected',
    };
    this._loggingService.logClick(data).subscribe((r) => {
      console.log(r);
    });
  }
}
