<div class="latest-shipment-container" *ngIf="loaded">
  <div class="col-12 timeline">
    <div class="d-flex justify-content-between align-items-center header">
      <h4 class="header-widget">Ultima spedizione</h4>
      <div class="d-none d-lg-flex align-items-center">
        <span class="current-lotto-title">Lotto:</span>
        <span class="current-lotto">{{shipment?.lotto}}</span>
      </div>
      <div class="current-state-container d-none d-lg-flex align-items-center">
        <span class="current-lotto-title">Stato:</span>
        <span class="shipment-status">{{shipment?.status}}</span>
      </div>
      <span class="cursor-pointer" (click)="explodeWidget($event)" (keydown)="explodeWidget($event)">
        <img src="/assets/img/arrow.svg" alt="">
      </span>
    </div>

    <div class="row">
      <div class="col-12 d-flex align-items-center justify-content-between justify-content-lg-start gap-3 gap-lg-0">
        <ng-container *ngIf="(isMobileWithTablet$ | async) else desktopLayout">
          <!-- Mobile/Tablet layout -->
          <ng-container *ngIf="lastShipmentWithProgressOrFinished else noShipmentLayout">
            <div class="d-flex flex-row align-items-center justify-content-start gap-3">
              <div class="actor-img"
                [ngClass]="{'relative': lastShipmentWithProgressOrFinished.status != 'progress', 'border-finish-color': lastShipmentWithProgressOrFinished.status == 'progress', 'border-wait-color': lastShipmentWithProgressOrFinished.status != 'progress'}">
                <img [src]="lastShipmentWithProgressOrFinished.path" alt="">
                <div *ngIf="lastShipmentWithProgressOrFinished.status == 'finish'" class="overlay">
                  <i class="fas fa-check"></i>
                </div>
                <div *ngIf="lastShipmentWithProgressOrFinished.status == 'wait'" class="overlay-wait">
                </div>
              </div>
              <div class="d-flex flex-column gap-2">
                <div class="actor-name-no-wait pb-0">
                  {{lastShipmentWithProgressOrFinished.name}}
                </div>
                <div class="d-flex flex-column">
                  <div class="step-shipment-status">{{ lastShipmentWithProgressOrFinished.shipmentStatus }}</div>
                  <div class="step-shipment-date">
                    {{ lastShipmentWithProgressOrFinished.shipmentStatus == 'Spedito'
                    ? (lastShipmentWithProgressOrFinished.shippingDate | date:'dd MMMM yyyy')
                    : (lastShipmentWithProgressOrFinished.deliveryDate | date:'dd MMMM yyyy') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column align-items-center justify-content-end gap-0">
              <div class="current-lotto-title pe-0">Lotto:</div>
              <div class="current-lotto mb-1">{{shipment?.lotto}}</div>
              <div class="shipment-status">{{shipment?.status}}</div>
            </div>
          </ng-container>
          <ng-template #noShipmentLayout>
            <h5 class="h5"><i>Nessuna spedizione partita</i></h5>
          </ng-template>
        </ng-container>
        <ng-template #desktopLayout>
          <!-- Desktop layout -->
          <div class="steps" *ngFor="let item of shipment?.steps; let i = index; let last = last;">
            <div class="line-container d-flex flex-column">
              <div [ngClass]="{'actor-name-no-wait': item.status != 'wait', 'actor-name-wait': item.status == 'wait'}">
                {{item.name}}
              </div>
              <div class="actor d-flex align-items-center">
                <div class="actor-img"
                  [ngClass]="{'relative': item.status != 'progress', 'border-finish-color': item.status == 'progress', 'border-wait-color': item.status != 'progress'}">
                  <img [src]="item.path" alt="">
                  <div *ngIf="item.status == 'finish'" class="overlay">
                    <i class="fas fa-check"></i>
                  </div>
                  <div *ngIf="item.status == 'wait'" class="overlay-wait">
                  </div>
                </div>
                <div class="d-none d-lg-block" [class.transit]="item.transit">
                  <div [ngClass]="{
                    'progress-line': !last,
                    'progress-line-finish-color': item.status == 'finish',
                    'progress-line-wait-color': item.status != 'finish',
                    'progress-line-transporter': !last && actor == 'distributor_id',
                    'progress-line-venditore': !last && actor == 'venditore_id',
                    'progress-line-transformer': !last && actor == 'selection_user_id',
                    'progress-line-producer': !last && actor == 'producer_id'
                  }">
                  </div>
                </div>
              </div>

              <div class="info d-flex flex-column" [ngClass]="{'hidden': item.status == 'wait'}">
                <span class="step-shipment-status">{{ item.shipmentStatus }}</span>
                <span class="step-shipment-date">
                  {{ item.shipmentStatus == 'Spedito'
                  ? (item.shippingDate | date:'dd MMMM yyyy')
                  : (item.deliveryDate | date:'dd MMMM yyyy') }}
                </span>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>