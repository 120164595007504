import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ChartService } from '../charts/chart.service';
import { ComponentService } from '../component.service';
import Swal from 'sweetalert2';
import { Subject, takeUntil } from 'rxjs';
import { CommonService } from '../common.service';

type RoleId = 2 | 4 | 5 | 6;

@Component({
  selector: 'app-mySystemTransformer',
  templateUrl: './mySystemTransformer.component.html',
  styleUrls: ['./mySystemTransformer.component.scss'],
})
export class MySystemTransformerComponent implements OnInit, OnDestroy {
  @Input() roleId!: RoleId;
  @Input() type!: string;
  @Input() plantId!: number | null;
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Output() variabileOutput = new EventEmitter<string>();

  systemForm!: FormGroup;
  typeNameForm!: string;
  provinceSelected!: any;
  municipalitySelected!: any;
  productTypeSelected!: any;

  municipality: any[] = [];
  province: any[] = [];
  dataRole: any;
  dataRoleId: number | null = null;
  switchDefault: boolean = false;
  isVisible: boolean = false;
  showForm: boolean = false;
  isLoadingDeletePlant: boolean = false;
  productType = [
    { label: 'Arance', id: 1 },
    { label: 'Limoni', id: 2 },
  ];

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private readonly _componentService: ComponentService,
    private readonly _chartService: ChartService,
    private readonly _commonService: CommonService,
    private readonly fb: FormBuilder
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this._chartService.getMarketMunicipality()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.municipality = r.data;
        this.handleRoleSpecificFormSetup();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  saveSystem(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    if (
      !this.systemForm.valid ||
      !this.municipalitySelected ||
      !this.provinceSelected
    ) {
      this.error();
      return;
    }

    const roleSaveMethods: { [key in RoleId]: () => void } = {
      2: () => this.handleProducerRole(),
      4: () => this.handleVendorRole(),
      5: () => this.handleTransformerRole(),
      6: () => this.handleDistributorRole(),
    };

    roleSaveMethods[this.roleId]?.();
  }

  resetForm(event?: Event): void {
    if (!this._commonService.checkEventClickOnBtnOrCta(event)) {
      return;
    }
    if (this.roleId === 2 && this.plantId) {
      this.showModal();
    } else {
      this.systemForm.reset();
      this.variabileOutput.emit('reset');
    }
  }

  handleConfirmDelete(): void {
    /*
    Questo pezzo di codice va decommentato se si deve abilitare l'eliminazione effettiva della
    piantagione (da testare per bene prima di pushare su dev/prod)
    */
    /*this.isLoadingDeletePlant = true;
    this._componentService.deletePlant(this.plantId)
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() =>  this.isLoadingDeletePlant = false)
      )
      .subscribe((resp?: any) => {*/
    this.isVisible = false;
    this.variabileOutput.emit('ok');
    // });
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  getTransformerInfo(): void {
    this._componentService.getUserInfoTransformer()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.dataRole = r.data;
        this.dataRoleId = r.data.id;
        this.initForm();
        this.initFormNonProducer();
      });
  }

  getDistributorInfo(): void {
    this._componentService.getUserInfoDistributor()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.dataRole = r.data;
        this.dataRoleId = r.data.id;
        this.initForm();
        this.initFormNonProducer();
      });
  }

  getVendorInfo(): void {
    this._componentService.getUserInfoVendor()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.dataRole = r.data;
        this.dataRoleId = r.data.id;
        this.initForm();
        this.initFormNonProducer();
      });
  }

  getSinglePlantInfo(): void {
    this._componentService.getSinglePlantInfo(this.plantId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.dataRole = r.data;
        this.dataRoleId = r.data.id;
        this.initFormProducer();
      });
  }

  initFormProducer(): void {
    this._chartService.getMarketMunicipality()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((r) => {
        this.municipality = r.data;

        if (this.dataRole) {
          const selectedProvince = this.municipality?.find(
            (item: any) => item.label == this.dataRole.province
          );

          this.provinceSelected = selectedProvince;

          if (this.provinceSelected) {
            this.getMunicipalityByProvince(this.provinceSelected?.label);
          }
          if (this.dataRole.product_id == 1) {
            this.productTypeSelected = 1;
          } else {
            this.productTypeSelected = 2;
          }
          this.switchDefault = this.dataRole.default;
          this.systemForm.patchValue({
            systemName: this.dataRole.name,
            address: this.dataRole.address,
            cap: this.dataRole.cap,
            warehouseCapacity: this.dataRole.storage_capacity,
            cultivatedHectares: this.dataRole.cultivated_hectares,
            KmOfCrops: this.dataRole.km_cultivations,
            cultivatedValue: this.dataRole.cultivated_value,
            soilType: this.dataRole.soil_type,
            cultivation_type: this.dataRole.cultivation_type,
          });
        }
      });
  }

  initFormNonProducer(): void {
    this.systemForm.patchValue({
      systemName: this.dataRole.name,
      address: this.dataRole.address,
      cap: this.dataRole.cap,
      warehouseCapacity: this.dataRole.storage_capacity,
    });

    this.provinceSelected = this.municipality.find(
      (province) => province.label === this.dataRole.province
    );
    this.getMunicipalityByProvince(this.provinceSelected.label);
  }

  getMunicipalityByProvince(provinceId: string): void {
    this._chartService
      .getMunicipalityByProvince(provinceId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        this.province = response.data;
        if (this.province) {
          const selectedmunicipality = this.province.find(
            (item: any) => item.label === this.dataRole.municipality
          );
          this.municipalitySelected = selectedmunicipality;
        }
      });
  }

  private initForm(): void {
    this.systemForm = this.fb.group({
      systemName: new FormControl(null, Validators.required),
      address: new FormControl(null, Validators.required),
      cap: new FormControl(null, Validators.required),
      warehouseCapacity: new FormControl(null, Validators.required),
      cultivatedHectares: new FormControl(null),
      KmOfCrops: new FormControl(null),
      cultivatedValue: new FormControl(null),
      soilType: new FormControl(null),
      cultivation_type: new FormControl(null),
    });
  }

  private handleRoleSpecificFormSetup(): void {
    switch (this.roleId) {
      case 4:
        this.typeNameForm = 'Nome negozio';
        this.getVendorInfo();
        break;
      case 5:
        this.typeNameForm = 'Nome impianto';
        this.getTransformerInfo();
        break;
      case 6:
        this.typeNameForm = 'Nome sede';
        this.getDistributorInfo();
        break;
      case 2:
        this.typeNameForm = 'Nome sede';
        if (this.plantId) {
          this.getSinglePlantInfo();
        }
        this.addAdditionalFields();
        break;
      default:
        break;
    }
  }

  private addAdditionalFields(): void {
    this.systemForm
      .get('cultivatedHectares')
      ?.setValidators(Validators.required);
    this.systemForm.get('KmOfCrops')?.setValidators(Validators.required);
    this.systemForm.get('cultivatedValue')?.setValidators(Validators.required);
    this.systemForm.get('soilType')?.setValidators(Validators.required);
    this.systemForm.get('cultivation_type')?.setValidators(Validators.required);

    this.systemForm.get('cultivatedHectares')?.updateValueAndValidity();
    this.systemForm.get('KmOfCrops')?.updateValueAndValidity();
    this.systemForm.get('cultivatedValue')?.updateValueAndValidity();
    this.systemForm.get('soilType')?.updateValueAndValidity();
    this.systemForm.get('cultivation_type')?.updateValueAndValidity();
  }

  private handleProducerRole(): void {
    const producerData = this.getProducerData();
    const producerMethod = this.plantId
      ? this._componentService.updateProducerPlant(producerData, this.plantId)
      : this._componentService.postProducerPlant(producerData);

    producerMethod
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => this.success(),
        (error) => this.showErrorMessage(error)
      );
  }

  private handleVendorRole(): void {
    const vendorData = this.getCommonData();
    this.saveRoleData(vendorData, 'Vendor');
  }

  private handleTransformerRole(): void {
    const transformerData = this.getCommonData();
    this.saveRoleData(transformerData, 'Transformer');
  }

  private handleDistributorRole(): void {
    const distributorData = this.getCommonData();
    this.saveRoleData(distributorData, 'Distributor');
  }

  private getCommonData(): any {
    return {
      company_name: this.systemForm.value.systemName,
      address: this.systemForm.value.address,
      municipality_id: this.municipalitySelected.id,
      province_id: this.provinceSelected.id,
      cap: this.systemForm.value.cap,
      capacity: this.systemForm.value.warehouseCapacity,
    };
  }

  private getProducerData(): any {
    return {
      name: this.systemForm.value.systemName,
      address: this.systemForm.value.address,
      municipality_id: this.municipalitySelected.id,
      province_id: this.provinceSelected.id,
      cap: this.systemForm.value.cap,
      capacity: this.systemForm.value.warehouseCapacity,
      product_id: this.productType.find(
        (prod) => prod.label === this.systemForm.value.cultivation_type
      )?.id,
      cultivated_hectares: this.systemForm.value.cultivatedHectares,
      km_cultivations: this.systemForm.value.KmOfCrops,
      cultivated_value: this.systemForm.value.cultivatedValue,
      soil_type: this.systemForm.value.soilType,
      cultivation_type: this.systemForm.value.cultivation_type,
      default: this.switchDefault,
    };
  }

  private saveRoleData(
    data: any,
    roleType: 'Transformer' | 'Distributor' | 'Vendor'
  ): void {
    const serviceMethods = {
      Transformer: () =>
        this.dataRoleId
          ? this._componentService.updateUserInfoTransformer(
            data,
            this.dataRoleId
          )
          : this._componentService.postUserInfoTransformer(data),
      Distributor: () =>
        this.dataRoleId
          ? this._componentService.updateUserInfoDistributor(
            data,
            this.dataRoleId
          )
          : this._componentService.postUserInfoDistributor(data),
      Vendor: () =>
        this.dataRoleId
          ? this._componentService.updateUserInfoVendor(data, this.dataRoleId)
          : this._componentService.postUserInfoVendor(data),
    };

    serviceMethods[roleType]().subscribe({
      next: () => this.success(),
      error: (error) => this.showErrorMessage(error),
    });
  }

  private success(): void {
    Swal.fire({
      title: 'Profilo aggiornato',
      text: 'Aggiornamento effettuato con successo',
      icon: 'success',
      showConfirmButton: false,
      timer: 2000,
    });
  }

  private error(): void {
    Swal.fire({
      title: 'Attenzione',
      text: 'Completa tutti i campi',
      icon: 'warning',
      showConfirmButton: false,
      timer: 2000,
    });
  }

  private showErrorMessage(error: any): void {
    Swal.fire({
      title: 'Errore',
      text: error.message,
      icon: 'warning',
      showConfirmButton: false,
      timer: 2000,
    });
  }

  private showModal(): void {
    this.isVisible = true;
  }
}
