import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { CapitalizeFirstPipe } from 'src/app/components/historical-weather/capitalize-first.pipe';
import { DataTableComponent } from 'src/app/components/data-table/data-table.component';
import { TranslateModule } from '@ngx-translate/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgChartsModule } from 'ng2-charts';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { IconModule } from '@ant-design/icons-angular';
import { NgZorroAntdModule } from 'src/app/components/ng-zorro-antd/ng-zorro-antd.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MostUsedActivitiesComponent } from 'src/app/components/attività_più_usate/most-used-activities/most-used-activities.component';
import { BatchWasteUsedComponent } from 'src/app/components/batch-waste-used/batch-waste-used.component';

@NgModule({
  declarations: [
    CapitalizeFirstPipe,
    DataTableComponent,
    MostUsedActivitiesComponent,
    BatchWasteUsedComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SweetAlert2Module,
    NgChartsModule,
    NzButtonModule,
    NzSelectModule,
    NzToolTipModule,
    NzRateModule,
    NzCalendarModule,
    NzPaginationModule,
    NzIconModule,
    NzTabsModule,
    IconModule,
    NgZorroAntdModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    CurrencyMaskModule,
  ],
  exports: [
    CommonModule,
    CapitalizeFirstPipe,
    DataTableComponent,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SweetAlert2Module,
    NgChartsModule,
    NgZorroAntdModule,
    NzButtonModule,
    NzSelectModule,
    NzToolTipModule,
    NzRateModule,
    NzCalendarModule,
    NzPaginationModule,
    NzIconModule,
    NzTabsModule,
    IconModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    CurrencyMaskModule,
    MostUsedActivitiesComponent,
    BatchWasteUsedComponent
  ],
})
export class SharedModule {}
