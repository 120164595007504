<div class="widget-weather weather-container" *ngIf="showWidget">
  <div class="d-flex justify-content-between align-items-center">
    <div>
      <h4 class="header-widget">Meteo</h4>
    </div>
    <div class="select-field d-flex align-items-center gap-3">
      <nz-select [nzPlaceHolder]="placeholder" [(ngModel)]="selectedField" (ngModelChange)="getWeather($event)">
        <nz-option *ngFor="let field of fields" [nzLabel]="field.label" [nzValue]="field" class="color">
          {{ field.label }}
        </nz-option>
      </nz-select>
      <span class="cursor-pointer" (click)="explodeWidget($event)" (keydown)="explodeWidget($event)">
        <img src="/assets/img/arrow.svg" alt="freccia-puntatrice">
      </span>
    </div>
  </div>
  <div class="d-flex flex-column temp-info">
    <div class="temp-info__temp">
      <span class="temp-max">{{temp_max|async}}°</span>
      <span class="temp-min">/ {{temp_min|async}}°</span>
    </div>
    <div class="temp-info__other">
      <span class="other-info">{{ sky | async}}</span>
    </div>
  </div>
</div>
