import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { WeatherComponent } from './components/weather/weather.component';
import { ReportFmsComponent } from './pages/report-fms/report-fms.component';
import { PlantationsComponent } from './pages/plantations/plantations.component';
import { WidgetReportFmsComponent } from './components/widget/widget-report-fms/widget-report-fms.component';
import { WidgetWeatherComponent } from './components/widget/widget-weather/widget-weather.component';
import { DashboardComponent } from 'src/app/components/dashboard/dashboard.component';
import { HistoricalLotsComponent } from './pages/historical-lots/historical-lots.component';
import { TreatmentPriceHandlerComponent } from './treatment-prices-handler/treatment-prices-handler.component';

const routes: Routes = [
  { path: 'reports', title: 'Quaderno attività', component: ReportFmsComponent, canActivate: [AuthGuard] },
  { path: 'historical-lots', title: 'Storico lotti', component: HistoricalLotsComponent, canActivate: [AuthGuard] },
  { path: 'treatment-prices-handler', title: 'Gestione costi', component: TreatmentPriceHandlerComponent, canActivate: [AuthGuard] },
  { path: 'maps', title: 'Mappa', component: WeatherComponent, canActivate: [AuthGuard] },
  { path: 'stats', component: PlantationsComponent, canActivate: [AuthGuard] },
  { path: 'production-trend', component: PlantationsComponent, canActivate: [AuthGuard] },
  { path: 'widget-fms', component: WidgetReportFmsComponent, canActivate: [AuthGuard] },
  { path: 'widget-weather', component: WidgetWeatherComponent, canActivate: [AuthGuard] },
  { path: '', component: DashboardComponent, pathMatch: 'full' },
  { path: '*', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProducerRoutingModule { }
