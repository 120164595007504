import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor() { }

  /**
   * Metodo che controlla un determinato evento ($event) al click su di un pulsante/cta e ritorna un
   * false nel caso il click è avvenuto tramite un pulsante differente dal click, dal touch event o dalla
   * pressione dello spazio.
   * NB: Serve per poter gestire il click con il keydown per l'accessibilità ed inoltre, effettua
   * anche un "preventDefault"
   * 
   * @param event Oggetto con le info dell'evento
   * @param mustStopPropagation Booleano per indicare se serve fermare anche la propagazione degli eventi
   * in sequenza (solo in alcuni casi dove potrebbero essere presenti degli eventi annidati, di default non verrà
   * considerato)
   * @returns 
   */
  checkEventClickOnBtnOrCta(event?: Event, mustStopPropagation?: boolean): boolean {
    const isKeyboardEvent = event instanceof KeyboardEvent && (event?.key === ' ' || event?.key === 'Enter');
    const isMouseEvent = event instanceof MouseEvent && event?.button === 0;
    const isTouchEvent = event instanceof TouchEvent;
    if (!isKeyboardEvent && !isMouseEvent && !isTouchEvent) {
      return false;
    }
    event.preventDefault();
    if (mustStopPropagation) {
      event.stopPropagation();
    }
    return true;
  }
}

