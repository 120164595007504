<div *ngIf="dayWeather" class="weather-card" [class.selected]="isSelected">
  <div class="weather-card-header">
    <p class="weather-card-header__title">{{ dayWeather.created_at | date:'dd' }}</p>
    <img class="weather-card-header__icon" [src]="getIconPath(dayWeather.weather_main)"
      [alt]="dayWeather.weather_main" />
    <p class="weather-card-header__temp">
      {{ dayWeather.temp_main }} °C
    </p>
    <p class="weather-card-header__desc">{{ dayWeather.weather_description }}</p>
  </div>
</div>