<div class="quantity-rejected">
  <div class="row consumed-container">
    <div class="col-6 d-flex justify-content-between title-cont">
      <h4 class="header-widget">Quantitá scartata</h4>
    </div>
  </div>

  <div class="row">
    <div class="col-4 fw-500">
      <div class="text-mini-widget ">Oggi</div>
      <span class="fs">{{day ? day : 0}}</span>
      <span class="kg">kg</span>
    </div>
    <div class="col-4 data-cont px-0 fw-500">
      <div class="text-mini-widget">Settimana</div>
      <span class="fs">{{week ? week : 0}}</span>
      <span class="kg">kg</span>
    </div>
    <div class="col-4 data-cont fw-500">
      <div class="text-mini-widget">Mese</div>
      <span class="fs">{{month ? month : 0}}</span>
      <span class="kg">kg</span>
    </div>
  </div>
</div>
